import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

import Option from './ComboBoxWithCheckBox/Option';
import MultiValue from './ComboBoxWithCheckBox/MultiValue';
import ValueContainer from './ComboBoxWithCheckBox/ValueContainer';

function getMultiValue(base, state) {
  return state.data.isFixed ? { ...base, backgroundColor: 'grey' } : base;
}

function getMultiValueLabel(base, state) {
  return state.data.isFixed
    ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
    : base;
}

function getMultiValueRemove(base, state) {
  return state.data.isFixed ? { ...base, display: 'none' } : base;
}

function handleValues(action, options, selectedValue) {
  if (action.isFixed) {
    return;
  } else if (action.value === '*') {
    selectedValue = options.filter(option => option.isFixed === true);
  }
  return selectedValue;
}

function handleManualSelection(selected, options, allOption, action) {
  let result = [];
  if (selected.length === options.length) {
    if (selected.includes(allOption)) {
      result = selected.filter(option => option.value !== allOption.value);
    } else if (action === 'select-option') {
      result = [allOption, ...options];
    }
    return result;
  }
}

const ComboBoxWithCheckbox = props => {
  const { allowSelectAll, allOption, options, onChange, value } = props;
  const animatedComponents = makeAnimated();
  const IndicatorSeparator = () => null;
  const DropdownIndicator = () => null;

  const components = {
    Option,
    MultiValue,
    ValueContainer,
    animatedComponents,
    IndicatorSeparator,
    DropdownIndicator,
  };

  const styles = {
    multiValue: (base, state) => {
      return getMultiValue(base, state);
    },
    multiValueLabel: (base, state) => {
      return getMultiValueLabel(base, state);
    },
    multiValueRemove: (base, state) => {
      return getMultiValueRemove(base, state);
    },
  };

  const orderOptions = values => {
    return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
  };

  const handleOnChange = (selectedValue, actionMeta) => {
    let selected;
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        selectedValue = handleValues(
          actionMeta.removedValue,
          options,
          selectedValue
        );
        selected = selectedValue;
        break;
      case 'clear':
        selected = options.filter(v => v.isFixed);
        break;
      case 'deselect-option':
        selectedValue = handleValues(actionMeta.option, options, selectedValue);
        selected = selectedValue;
        break;
      default:
        selected = selectedValue;
    }

    selected = orderOptions(selected);

    let manualSelection;
    if (selected !== null && selected.length > 0) {
      // on select all options checkbox
      if (selected[selected.length - 1].value === allOption.value) {
        return onChange([allOption, ...options]);
      }
      // on manually selecting all the checkbox
      manualSelection = handleManualSelection(
        selected,
        options,
        allOption,
        actionMeta.action
      );
    }

    return onChange(manualSelection?.length ? manualSelection : selected);
  };

  const renderProps = {
    ...props,
    styles,
    options: allowSelectAll
      ? [allOption, ...orderOptions(options)]
      : orderOptions(options),
    components: components,
    value: value?.length === options.length ? [allOption, ...value] : value,
    onChange: allowSelectAll ? handleOnChange : null,
    isOptionDisabled: option => option.isFixed === true,
  };

  return <ReactSelect {...renderProps} />;
};

ComboBoxWithCheckbox.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  value: PropTypes.array,
};

ComboBoxWithCheckbox.defaultProps = {
  onChange: () => null,
  allowSelectAll: true,
  allOption: {
    label: 'Select all',
    value: '*',
  },
  value: [],
};

export { ComboBoxWithCheckbox };
