export const ERROR_MESSAGE = {
  400001: 'Please provide an email address.',
  400002: 'Please select the user role',
  400003:
    'Selected file type is not supported. Please select .xls or .xlsx file types to upload',
  400004: 'Uploaded file is empty. Please upload a valid file',
  400005: 'Uploaded file is empty. Please upload a valid file.',
  400006: 'Please provide the valid list name',
  400007: 'Please the list from My Lists',
  400008: 'Please select the case ID',
  400009: 'User does not exist',
  500001: 'User is not available.',
  500002: 'There was some error ocurred while updatig the user',
  500003: 'There was some error occrred while processing the uploaded file',
  500004: 'List not exist. Please select the valid list',
  500005: 'List not exist. Please select the valid list',
  500010: 'User with this email address already exists',
  500012: 'Download limit exceeded. You can only download upto 100 records.',
};

export const DEFAULT_ERROR = {
  general:
    'There was some error occured while processing your request. Please try again.',
  upload:
    'There was some error occured while uploading the file. Please try again.',
  search: 'There was some error occured while fetching the case records',
  download: 'Failed to download case details',
};
