import React, { useEffect } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { showGlobalLoader } from 'store/ui/selector';
import ProtectedPage from './containers/ProtectedPage';
import LoginError from './containers/LoginError';
import Loader from 'components/Loader';
import { PATHNAME } from './constants/router';
import { oktaAuth, restoreOriginalUri } from 'utils/okta';

import './App.scss';

function App() {
  const history = useHistory();
  const loaderIsActive = useSelector(showGlobalLoader);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_ADOBE_URL;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri(history)}
    >
      <Loader active={loaderIsActive} />
      <Switch>
        <Route
          exact
          path={PATHNAME.LOGIN_CALLBACK}
          render={() => (
            <LoginCallback
              loadingElement={<Loader active />}
              errorComponent={LoginError}
            />
          )}
        />
        <SecureRoute path={`${PATHNAME.LANDING}`} component={ProtectedPage} />
      </Switch>
      <ToastContainer
        containerId="alert-toast-container"
        draggable={false}
        theme="colored"
        hideProgressBar
        newestOnTop
      />
    </Security>
  );
}
export default withRouter(App);
