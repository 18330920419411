import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import edit from 'assets/images/edit.svg';
import keyResearch from 'assets/images/Key-research.svg';
import { Edit } from 'components/modal/Edit/Edit';
import EditKeyResearchBlock from 'components/modal/Edit/EditForms/EditKeyResearchBlock';
import ShowMoreContent from 'components/ShowMoreContent';
import { isAdminUser } from 'utils/user';
import './CaseInformation.scss';

function KeyResearchBlock({ informationSources }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const setOpenModal = state => setOpenEditModal(state);
  const handleOnClick = () => {
    setOpenEditModal(true);
  };
  return (
    <>
      {openEditModal && (
        <Edit
          Content={EditKeyResearchBlock}
          open={openEditModal}
          setOpenModal={setOpenModal}
        />
      )}
      <div className="case-information__section-container">
        <div className="case-information__section-header">
          <div className="case-information__section-header__project-icon-name">
            <Image src={keyResearch} className="margin-right-5" />
            <div className="case-information__section-header__project-icon-name__project-name">
              Key Research and Data Sources
            </div>
          </div>
          {isAdminUser() && (
            <div
              className="case-information__section-header__edit"
              onClick={handleOnClick}
            >
              <Image
                src={edit}
                className="case-information__section-header__edit__edit-icon"
              />
              <div className="case-information__section-header__edit__edit-text">
                Edit
              </div>
            </div>
          )}
        </div>
        <div className="case-information__project-name-container">
          <div className="case-information__project-name-container__block">
            <div className="case-information__project-name-container__lower-text">
              <ShowMoreContent
                anchorClass="case-information__project-name-container__lower-text__more-less"
                showMoreText="Read More"
                showLessText="Read Less"
                lines={2}
              >
                {informationSources}
              </ShowMoreContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

KeyResearchBlock.propTypes = {
  informationSources: PropTypes.string,
};

KeyResearchBlock.defaultProps = {
  informationSources: '',
};

export default KeyResearchBlock;
