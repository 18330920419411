import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Image } from 'semantic-ui-react';

import { DownloadButton } from 'components/Button';
import { PREV_TEXT, NEXT_TEXT } from 'constants/Common';
import { getPrevPageInfo, getSort } from 'store/data_table/selectors';
import { getDownloadAction, getAllCaseDetailsAction } from 'utils/case';
import { redirectToCaseDetails } from 'utils/DataTable';
import ui from 'store/ui';
import { toastError, toastSuccess } from 'utils/toast';
import { downloadExcelFile } from 'utils/browser';
import { extractErrorMessage } from 'utils/error';
import { DEFAULT_ERROR } from 'constants/errors';
import greaterthan from 'assets/images/greaterThan.svg';
import './CaseDetailsActionsHeader.scss';

function CaseDetailsActionsHeader() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [caseIds, setCaseIds] = useState([]);
  const prevPageInfo = useSelector(getPrevPageInfo);
  const sortData = useSelector(getSort);
  const { from, query, totalRows, currentIndex } = prevPageInfo;
  const { sortBy, sortDirection } = sortData;
  const [caseIndex, setCaseIndex] = useState(currentIndex);
  const [totalElements, setTotalElements] = useState(totalRows);

  const getAllCaseIds = useCallback(async () => {
    const searchQuery = {
      currentIndex,
      sortDirection,
      sortBy,
      query,
      from,
    };

    try {
      const result = await dispatch(getAllCaseDetailsAction(searchQuery));
      const caseDetails = unwrapResult(result);
      const recordsList = caseDetails?.data?.recordsList || [];
      setCaseIds(recordsList.map(caseRow => caseRow.id));
      setTotalElements(
        caseDetails?.data?.matchedRow || caseDetails?.data?.totalElements
      );
    } catch (error) {
      toastError(
        'There was some error occured while fetching the case records'
      );
    }
  }, [dispatch, from, query, sortBy, sortDirection, currentIndex]);

  useEffect(() => {
    getAllCaseIds();
  }, [getAllCaseIds]);

  useEffect(() => {
    if (!caseIndex) {
      setCaseIndex(caseIds.indexOf(params.caseId) + 1);
    }
  }, [params.caseId, caseIndex, caseIds]);

  const goBack = () => {
    history.goBack();
  };

  const onClickDownload = () => {
    const downloadAction = getDownloadAction({
      page: 1,
      limit: totalElements,
      sortDirection,
      sortBy,
      query,
      from,
    });

    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(downloadAction)
      .unwrap()
      .then(response => {
        downloadExcelFile(response, {
          contentTypeHeader: 'application/vnd.ms-excel',
          fileName: 'my_cases.xlsx',
        });
        toastSuccess('File downloaded successfully');
      })
      .catch(error => {
        toastError(extractErrorMessage(error, DEFAULT_ERROR.download));
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const handleNavClick = e => {
    const rowIndex = caseIndex + (e.target.value === PREV_TEXT ? -1 : 1);

    if (rowIndex < 1 || rowIndex > totalElements) return null;

    const caseId = caseIds[rowIndex - 1];
    setCaseIndex(rowIndex);
    redirectToCaseDetails(e, { caseId, history, replace: true });
  };

  return (
    <div className="case-details-actions-header margin-bottom-5">
      <div className="case-details-actions-header__main">
        <div className="case-details-actions-header__main__left">
          <span
            className="case-details-actions-header__main__left__results"
            onClick={goBack}
          >
            Results
          </span>
          <Image src={greaterthan} className="margin-left-5 margin-right-5" />
          <span className="case-details-actions-header__main__left__record-details">
            Record Details
          </span>
        </div>
        <div className="case-details-actions-header__main__right">
          <div className="case-details-actions-header__main__right__records">
            <p>
              Displaying {caseIndex} of {totalElements} Records
            </p>
          </div>
          <div className="case-details-actions-header__main__right__buttons">
            <Button
              color="black"
              value={PREV_TEXT}
              onClick={handleNavClick}
              disabled={caseIndex === 1}
            >
              &lt; {PREV_TEXT}
            </Button>
            <Button
              color="black"
              value={NEXT_TEXT}
              onClick={handleNavClick}
              disabled={caseIndex === totalElements}
            >
              {NEXT_TEXT} &gt;
            </Button>
          </div>
        </div>
      </div>
      <div className="case-details-actions-header__download">
        <DownloadButton content="Download Results" onClick={onClickDownload} />
      </div>
    </div>
  );
}

export default CaseDetailsActionsHeader;
