import React from 'react';
import PropTypes from 'prop-types';

function Remove({ fill, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5H14V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V5ZM3.5 6.5V14H12.5V6.5H3.5ZM5.75 8H7.25V12.5H5.75V8ZM8.75 8H10.25V12.5H8.75V8ZM4.25 2.75V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H11C11.1989 0.5 11.3897 0.579018 11.5303 0.71967C11.671 0.860322 11.75 1.05109 11.75 1.25V2.75H15.5V4.25H0.5V2.75H4.25ZM5.75 2V2.75H10.25V2H5.75Z"
        fill={fill}
      />
    </svg>
  );
}

Remove.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Remove.defaultProps = {
  fill: '#FFF',
  height: '16',
  width: '16',
  viewBox: '0 0 16 16',
};

export default Remove;
