import { createAsyncThunk } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import axiosInstance from 'utils/axios';

export const getStatistics = createAsyncThunk(
  `${SLICES.STATISTICS}/getStatistics`,
  async category => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: `/v1/statistics/${category}`,
    });
    return res.data;
  }
);

export const getDDStatistics = createAsyncThunk(
  `${SLICES.STATISTICS}/getDDStatistics`,
  async () => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: '/v1/dashBoard/DDdetails',
    });
    return res.data;
  }
);
