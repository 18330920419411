import { createAsyncThunk } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import axiosInstance from 'utils/axios';

export const uploadFile = createAsyncThunk(
  `${SLICES.UI}/upload`,
  async ({ formData, setUploadStatus }) => {
    setUploadStatus({
      uploading: true,
      percentCompleted: 0,
    });
    const res = await axiosInstance.request({
      method: 'POST',
      url: '/v1/excel/upload',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadStatus({
          uploading: true,
          percentCompleted,
        });
      },
    });
    return res.data;
  }
);
