export const OPERATOR_SUGGESTIONS = {
  AND: 'AND',
  OR: 'OR',
  PLAIN_TEXT: 'PLAIN TEXT',
};

export const SEARCH_PAYLOAD_TYPES = {
  TEXT: 'TOKEN',
  OPERATOR: 'OPERATOR',
};

export const OPERATOR_ACTIONS = {
  ADDED: 'ADDED',
  DELETED: 'DELETED',
  UNCHANGED: 'UNCHANGED',
};

export const DEFAULT_FILTER_OPTIONS = {
  selectedRadio: ['all'],
  industryPracticeArea: [],
  caseType: [],
  topic: [],
  region: [],
  selectedYear: [],
  clientFirm: [],
  mdp: [],
};

export const DEFAULT_SEARCH = [
  {
    type: SEARCH_PAYLOAD_TYPES.TEXT,
    value: '',
  },
];
