import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackTileClick } from 'analytics';

/**
 * @example
 *
 * const {  open, closeDropdown, openDropdown, calculateDropdownPosition } = useDropdownMenu();
 *
 * return (<>
 *   <Menu
 *     vertical
 *     className="sidebar-menu"
 *     style={{ height }}
 *     onScroll={calculateDropdownPosition}
 *   >
 *     <MenuItem imageSrc={home} title="HOME" to={PATHNAME.HOME} />
 *     <Ref innerRef={myListMenuItemRef}>
 *       <MenuItem
 *         imageSrc={loadbucket}
 *         title="MY LISTS"
 *         onClick={openDropdown('bucket')}
 *         className={isMyListsRoute ? 'active' : ''}
 *       />
 *     </Ref>
 *   </Menu>
 *   <BucketsDropdown
 *     ref={bucketsDropdownRef}
 *     open={open['bucket']}
 *     closeDropdown={closeDropdown('bucket')}
 *   />
 * </>);
 *
 *
 * @returns {object}   - object containing the dropdown status and helper methods
 *                       open, closeDropdown, openDropdown, calculateDropdownPosition
 */
const useDropdownMenu = ({ menuDropdowns, offset }) => {
  const [open, setOpen] = useState({ bucket: false, investible: false });

  const setDropdownPosition = type => {
    const { dropdownRef, menuItemRef } = menuDropdowns[type];

    if (!dropdownRef?.current || !menuItemRef?.current) return;

    const [menuItemRects] = menuItemRef.current.getClientRects();
    const meuItemTop = menuItemRects.top;
    const top = meuItemTop - offset;
    dropdownRef.current.style.top = top + 'px';
  };

  const calculateDropdownPosition = () => {
    for (const type of Object.keys(menuDropdowns)) {
      if (open[type]) setDropdownPosition(type);
    }
  };

  const closeDropdown = useCallback(
    type => () => {
      if (open[type]) setOpen({ ...open, [type]: false });
    },
    [open]
  );

  const hideDropDown = useCallback(
    e => {
      const { target } = e;
      for (const type of Object.keys(menuDropdowns)) {
        const { dropdownRef, menuItemRef } = menuDropdowns[type];

        if (
          dropdownRef?.current?.contains(target) ||
          menuItemRef?.current?.contains(target)
        ) {
          //inside click
          return;
        }

        // outside click
        closeDropdown(type)();
      }
    },
    [menuDropdowns, closeDropdown]
  );

  const openDropdown = type => () => {
    if (type === 'bucket') {
      trackTileClick('MY LISTS');
    } else if (type === 'investible') {
      trackTileClick('INVESTABLE THEMES & TEASERS');
    }
    setOpen({ ...open, [type]: !open[type] });
    setDropdownPosition(type);
  };

  useEffect(() => {
    document.addEventListener('mousedown', hideDropDown);

    return () => {
      document.removeEventListener('mousedown', hideDropDown);
    };
  }, [hideDropDown]);

  return {
    open,
    closeDropdown,
    openDropdown,
    calculateDropdownPosition,
  };
};

export default useDropdownMenu;

useDropdownMenu.propTypes = {
  menuDropdowns: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      menuItemRef: PropTypes.object,
      dropdownRef: PropTypes.object,
    }),
  }),
  offset: PropTypes.number,
};
