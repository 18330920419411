import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import edit from 'assets/images/edit.svg';
import { Edit } from 'components/modal/Edit/Edit';
import EditProjectBlock from 'components/modal/Edit/EditForms/EditProjectBlock';
import ShowMoreContent from 'components/ShowMoreContent';
import { isAdminUser } from 'utils/user';
import './CaseInformation.scss';

function ProjectBlock({
  projectName,
  description,
  caseType1,
  caseType2,
  firmName,
  firmType,
  hasMaterials,
}) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const setOpenModal = state => setOpenEditModal(state);

  return (
    <>
      <Edit
        Content={EditProjectBlock}
        open={openEditModal}
        setOpenModal={setOpenModal}
      />
      <div className="case-information__section-header">
        <div className="case-information__section-header__project-icon-name">
          <div className="case-information__section-header__project-icon-name__project-name">
            {projectName}
          </div>
        </div>
        {isAdminUser() && (
          <div
            className="case-information__section-header__edit"
            onClick={() => setOpenEditModal(true)}
          >
            <Image
              src={edit}
              className="case-information__section-header__edit__edit-icon"
            />
            <div className="case-information__section-header__edit__edit-text">
              Edit
            </div>
          </div>
        )}
      </div>
      <div className="case-information__project-description">
        <ShowMoreContent
          lines={2}
          anchorClass="case-information__project-description__more-less"
          showMoreText="Read More"
          showLessText="Read Less"
        >
          {description}
        </ShowMoreContent>
      </div>
      <div className="case-information__project-name-container">
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Case Type 1
          </div>
          <div className="case-information__project-name-container__lower-text">
            {caseType1}
          </div>
        </div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Case Type 2
          </div>
          <div className="case-information__project-name-container__lower-text">
            {caseType2}
          </div>
        </div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Client
          </div>
          <div className="case-information__project-name-container__lower-text">
            {firmName}
          </div>
        </div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Client Type
          </div>
          <div className="case-information__project-name-container__lower-text">
            {firmType}
          </div>
        </div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Has Material
          </div>
          <div className="case-information__project-name-container__lower-text">
            {hasMaterials || 'No'}
          </div>
        </div>
      </div>
    </>
  );
}

ProjectBlock.propTypes = {
  projectName: PropTypes.string,
  description: PropTypes.string,
  caseType1: PropTypes.string,
  caseType2: PropTypes.string,
  firmName: PropTypes.string,
  firmType: PropTypes.string,
  hasMaterials: PropTypes.oneOf(['Yes', 'No']),
};

ProjectBlock.defaultProps = {
  projectName: '',
  description: '',
  caseType1: '',
  caseType2: '',
  firmName: '',
  firmType: '',
  hasMaterials: 'No',
};

export default ProjectBlock;
