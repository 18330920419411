export const DESCENDING = {
  long: 'descending',
  short: 'desc',
  int: -1,
};

export const ASCENDING = {
  long: 'ascending',
  short: 'asc',
  int: 1,
};

export const DUPLICATE_ROW_HIGHLIGHTER = 'negative';

export const COLUMNS_CONF = [
  {
    headerName: 'Case ID',
    field: 'caseCode',
    sortable: true,
    style: {
      width: '80px',
    },
    wordHighlighter: true,
    isFixed: true,
  },
  {
    headerName: 'Case or Proposal',
    field: 'isCaseOrProposal',
    sortable: true,
    style: { width: '80px' },
    wordHighlighter: true,
    truncateText: false,
    hidden: true,
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    sortable: true,
    style: {
      width: '75px',
    },
    wordHighlighter: true,
    isFixed: true,
    type: 'date',
  },
  {
    headerName: 'Region',
    field: 'region',
    sortable: true,
    style: {
      width: '75px',
    },
    wordHighlighter: true,
    isFixed: true,
  },
  {
    headerName: 'Office',
    field: 'officeLocation',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
  },
  {
    headerName: 'Case Type 1',
    field: 'caseType1',
    sortable: true,
    style: { width: '90px' },
    wordHighlighter: true,
    isFixed: true,
  },
  {
    headerName: 'Case Type 2',
    field: 'caseType2',
    sortable: true,
    style: { width: '90px' },
    wordHighlighter: true,
    hidden: true,
  },
  {
    headerName: 'Topic Areas',
    field: 'pipeTopics',
    sortable: true,
    style: { width: '200px' },
    wordHighlighter: true,
    truncateText: true,
    hidden: true,
  },
  {
    headerName: 'Project Code Name',
    field: 'projectCodename',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
  },
  {
    headerName: 'Client',
    field: 'firmName',
    sortable: true,
    style: {
      width: '80px',
    },
    wordHighlighter: true,
    isFixed: true,
    truncateText: true,
  },
  {
    headerName: 'Client Type',
    field: 'firmType',
    sortable: true,
    style: {
      width: '100px',
    },
    wordHighlighter: true,
    isFixed: true,
    hidden: true,
  },
  {
    headerName: 'Major Industry',
    field: 'majorIndustries',
    sortable: true,
    style: {
      width: '120px',
    },
    wordHighlighter: true,
    isFixed: true,
    truncateText: true,
  },
  {
    headerName: 'Sub-Sectors',
    field: 'subSectors',
    sortable: true,
    style: {
      width: '100px',
    },
    wordHighlighter: true,
    isFixed: true,
    truncateText: true,
  },
  {
    headerName: 'Target Name',
    field: 'targetName',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
  },
  {
    headerName: 'Target Industry',
    field: 'targetIndustry',
    sortable: true,
    style: {
      width: '95px',
    },
    wordHighlighter: true,
    isFixed: true,
    truncateText: true,
  },
  {
    headerName: 'Relevant Players',
    field: 'relevantPlayers',
    sortable: true,
    style: { width: '250px' },
    wordHighlighter: true,
    truncateText: true,
    hidden: true,
  },
  {
    headerName: 'Case Description',
    field: 'caseDescription',
    sortable: true,
    style: { width: '300px' },
    wordHighlighter: true,
    truncateText: true,
    isFixed: true,
  },
  {
    headerName: 'Case MDP',
    field: 'caseMDPs',
    sortable: true,
    style: {
      width: '80px',
    },
    wordHighlighter: true,
    isFixed: true,
    truncateText: true,
  },
  {
    headerName: 'Case Team',
    field: 'caseTeam',
    sortable: true,
    style: { width: '150px' },
    wordHighlighter: true,
    truncateText: true,
    hidden: true,
  },
  {
    headerName: 'BCG Experts',
    field: 'bcgExperts',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
    truncateText: true,
  },
  {
    headerName: 'Digital Modules',
    field: 'digitalModulesUsed',
    sortable: true,
    style: { width: '200px' },
    wordHighlighter: true,
    hidden: true,
    truncateText: true,
  },
  {
    headerName: 'Digital Teams',
    field: 'digitalTeamsInvolved',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
    truncateText: true,
  },
  {
    headerName: 'Key Research and Data Sources',
    field: 'informationSources',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
    truncateText: true,
  },
  {
    headerName: 'Value Creation Levers',
    field: 'valueCreationLevels',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
    truncateText: true,
  },
  {
    headerName: 'KN URL',
    field: 'knURL',
    sortable: true,
    style: { width: '250px', wordBreak: 'break-all' },
    wordHighlighter: false,
    hidden: true,
  },
  {
    headerName: 'Keyword Tags',
    field: 'keyTerms',
    sortable: true,
    style: { width: '200px' },
    wordHighlighter: true,
    hidden: true,
    truncateText: true,
  },
  {
    headerName: 'Has Materials',
    field: 'hasMaterials',
    sortable: true,
    style: { width: '100px' },
    wordHighlighter: true,
    hidden: true,
  },
];
