import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import { CaseDetailsContext } from 'utils/context';
import { getKeyExperts } from 'store/user/asyncActions';
import FormAsyncSelect from 'components/FormAsyncSelect';

function EditKeyExpertsBlock({ setFormData }) {
  const { data: keyExpertBlockField } = useContext(CaseDetailsContext);
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState({
    bcgExperts: keyExpertBlockField?.bcgExperts,
  });
  let isFormUpdated = false;

  const handleChange = e => {
    formFields.bcgExperts = e.caseUpdateValue;
    setFormFields(formFields);

    if (formFields.bcgExperts !== keyExpertBlockField?.bcgExperts) {
      isFormUpdated = true;
    }

    const data = {
      formData: formFields,
      masterDataUpdateValue: e.masterDataUpdateValue,
      masterDataName: e.masterDataName,
    };
    setFormData(data, isFormUpdated);
  };

  const filter = inputValue => {
    return dispatch(getKeyExperts(inputValue))
      .unwrap()
      .then(response => {
        const options = response?.data?.searchFilter?.employees || [];
        return options.map(item => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }));
      });
  };

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FormAsyncSelect
            header="BCG Experts"
            placeholder="Input or select BCG expert name"
            options={[]}
            selectedValue={keyExpertBlockField?.bcgExperts}
            onChange={handleChange}
            name="bcgExperts"
            filter={filter}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
EditKeyExpertsBlock.propTypes = {
  setFormData: PropTypes.func,
};
EditKeyExpertsBlock.defaultProps = {
  setFormData: () => null,
};

export default EditKeyExpertsBlock;
