export const CASE_TYPES = {
  CASE: 'Case',
  PROPOSAL: 'Proposal',
};

export const MAJOR_INDUSTRY_MAPPING = {
  consumer: 'Consumer',
  financialInstitutions: 'Financial Institutions',
  healthCare: 'Health Care',
  principalInvestorsPrivateEquity: 'Principal Investors & Private Equity',
  businessServices: 'Business Services',
  energy: 'Energy',
  industrialGoods: 'Industrial Goods',
  publicSector: 'Public Sector',
  technologyMediaAndTelecom: 'Technology, Media and Telecom',
  insurance: 'Insurance',
  travelCitiesAndInfrastructure: 'Travel, Cities and Infrastructure',
  other: 'Other',
};

export const MAJOR_INDUSTRY_REVERSE_MAPPING = Object.entries(
  MAJOR_INDUSTRY_MAPPING
).reduce((prevValue, [key, value]) => {
  return { ...prevValue, [value]: key };
}, {});

export const MAJOR_INDUSTRY_SUBSECTORS_MAPPING = {
  businessServices: 'businessServicesSubSector',
  consumer: 'consumerSubSector',
  energy: 'energySubSector',
  financialInstitutions: 'financialInstitutionsSubSector',
  healthCare: 'healthCareSubSector',
  industrialGoods: 'industrialGoodsSubSector',
  technologyMediaAndTelecom: 'technologyMediaAndTelecomSubSector',
  other: 'ifOther',
  travelCitiesAndInfrastructure: 'travelCitiesAndInfrastructureSubSector',
};
