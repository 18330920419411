import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import edit from 'assets/images/edit.svg';
import keyExpertIcon from 'assets/images/Key-experts.svg';
import topicAreaIcon from 'assets/images/Topic-area.svg';
import ShowMoreContent from 'components/ShowMoreContent';
import { Edit } from 'components/modal/Edit/Edit';
import EditTopicBlock from 'components/modal/Edit/EditForms/EditTopicBlock';
import EditKeyExpertsBlock from 'components/modal/Edit/EditForms/EditKeyExpertsBlock';
import { isAdminUser } from 'utils/user';
import './CaseInformation.scss';

function KeyExpertAndTopicBlock({
  keyExperts,
  topicArea,
  valueCreationLevels,
}) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalValue, setEditModalValue] = useState();

  const setOpenModal = state => setOpenEditModal(state);

  // val = openKeyExpert for KeyExpert edit modal
  // val = openTopicArea for Topic Area edit modal
  const handleOnClick = val => () => {
    setEditModalValue(val);
    setOpenEditModal(true);
  };

  return (
    <>
      {openEditModal && (
        <Edit
          Content={
            editModalValue === 'openKeyExpert'
              ? EditKeyExpertsBlock
              : EditTopicBlock
          }
          open={openEditModal}
          setOpenModal={setOpenModal}
        />
      )}

      <div className="case-information__two-blocks-together">
        <div className="case-information__two-blocks-together__left-block">
          <div className="case-information__section-header">
            <div className="case-information__section-header__project-icon-name">
              <Image src={keyExpertIcon} className="margin-right-5" />
              <div className="case-information__section-header__project-icon-name__project-name">
                Key Experts
              </div>
            </div>
            {isAdminUser() && (
              <div
                className="case-information__section-header__edit"
                onClick={handleOnClick('openKeyExpert')}
              >
                <Image
                  src={edit}
                  className="case-information__section-header__edit__edit-icon"
                />
                <div className="case-information__section-header__edit__edit-text">
                  Edit
                </div>
              </div>
            )}
          </div>
          <div className="case-information__project-name-container">
            <div className="case-information__project-name-container__block">
              <div className="case-information__project-name-container__lower-text">
                <ShowMoreContent
                  anchorClass="case-information__project-name-container__lower-text__more-less"
                  showMoreText="Read More"
                  showLessText="Read Less"
                  lines={2}
                >
                  {keyExperts}
                </ShowMoreContent>
              </div>
            </div>
          </div>
        </div>
        <div className="case-information__two-blocks-together__right-block">
          <div className="case-information__section-header">
            <div className="case-information__section-header__project-icon-name">
              <Image src={topicAreaIcon} className="margin-right-5" />
              <div className="case-information__section-header__project-icon-name__project-name">
                Topic Area
              </div>
            </div>
            {isAdminUser() && (
              <div
                className="case-information__section-header__edit"
                onClick={handleOnClick('openTopicArea')}
              >
                <Image
                  src={edit}
                  className="case-information__section-header__edit__edit-icon"
                />
                <div className="case-information__section-header__edit__edit-text">
                  Edit
                </div>
              </div>
            )}
          </div>
          <div className="case-information__project-name-container">
            <div className="case-information__project-name-container__block">
              <div className="case-information__project-name-container__upper-text">
                Topic Area
              </div>
              <div className="case-information__project-name-container__lower-text">
                <ShowMoreContent
                  anchorClass="case-information__project-name-container__lower-text__more-less"
                  showMoreText="Read More"
                  showLessText="Read Less"
                  lines={2}
                >
                  {topicArea}
                </ShowMoreContent>
              </div>
            </div>
            <div className="case-information__project-name-container__vertical-line"></div>
            <div className="case-information__project-name-container__block">
              <div className="case-information__project-name-container__upper-text">
                Value Creation
              </div>
              <div className="case-information__project-name-container__lower-text">
                <ShowMoreContent
                  anchorClass="case-information__project-name-container__lower-text__more-less"
                  showMoreText="Read More"
                  showLessText="Read Less"
                  lines={2}
                >
                  {valueCreationLevels}
                </ShowMoreContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

KeyExpertAndTopicBlock.propTypes = {
  keyExperts: PropTypes.string,
  topicArea: PropTypes.string,
  valueCreationLevels: PropTypes.string,
};

KeyExpertAndTopicBlock.defaultProps = {
  keyExperts: '',
  topicArea: '',
  valueCreationLevels: '',
};

export default KeyExpertAndTopicBlock;
