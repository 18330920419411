import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { PATHNAME } from 'constants/router';
import { ISSUER, CLIENT_ID, SCOPES } from 'constants/okta';

// Okta auth object for authenticating the user in OKTA application
// using the okta issuer and client id of the PIPEpack okta app
export const oktaAuth = new OktaAuth({
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: window.location.origin + PATHNAME.LOGIN_CALLBACK,
  scopes: SCOPES,
});

/**
 *
 * @param {Object} history history object created from react-router-dom
 * @returns                the async callback function that can be used in pased to okta security componrnt
 */
export const restoreOriginalUri = history => async (_oktaAuth, originalUri) => {
  history.replace(
    toRelativeUrl(originalUri || PATHNAME.LANDING, window.location.origin)
  );
};
