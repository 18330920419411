import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'semantic-ui-react';

import cancel from 'assets/images/icons/cancel.svg';
import save from 'assets/images/icons/save.svg';
import alertIcon from 'assets/images/icons/alert.svg';
import Close from 'components/Icons/Close';

import './Alert.scss';

export function ConfirmAlert({
  title,
  open,
  onCancel,
  onConfirm,
  message,
  confirmText,
  confirmIcon,
  confirmButtonColor,
  cancelText,
}) {
  const [showAlert, setShowAlert] = useState(open);

  useEffect(() => {
    setShowAlert(open);
  }, [open]);

  const closeModal = () => {
    setShowAlert(false);
    onCancel();
  };

  const onSubmit = () => {
    setShowAlert(false);
    onConfirm();
  };

  return (
    <Modal
      className="modal-alert"
      open={showAlert}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header className="modal-alert__header">
        <span className="modal-alert__header__icon">
          <img src={alertIcon} alt="alert icon" />
        </span>
        <span className="modal-alert__header__text">{title}</span>
        <span className="modal-alert__header__close" onClick={closeModal}>
          <Close fill="#D7D7D7" height="13" width="13" />
        </span>
      </Modal.Header>
      <Modal.Content className="modal-alert__content">
        <p className="modal-alert__content__text">{message}</p>
      </Modal.Content>
      <Modal.Actions className="footer actions modal-alert__footer">
        <Button
          onClick={closeModal}
          icon={
            <Icon>
              <img src={cancel} alt="cancel" />
            </Icon>
          }
          className="modal-alert__footer__cancel"
          content={cancelText}
        />
        <Button
          onClick={onSubmit}
          icon={<Icon>{confirmIcon}</Icon>}
          className="modal-alert__footer__submit"
          content={confirmText}
          color={confirmButtonColor}
        />
      </Modal.Actions>
    </Modal>
  );
}

ConfirmAlert.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmIcon: PropTypes.node,
  confirmButtonColor: PropTypes.oneOf(['green', 'red', 'blue']),
};

const DefaultMessage = () => (
  <>
    You have unsaved changes. Click <b>Continue</b> to Make changes, or{' '}
    <b>Cancel</b> to lose the changes.
  </>
);

ConfirmAlert.defaultProps = {
  title: 'Alert!',
  cancelText: 'Cancel',
  confirmText: 'Continue',
  message: <DefaultMessage />,
  confirmIcon: <img src={save} alt="Save" />,
  confirmButtonColor: 'green',
};
