import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Container, Image } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import bcgLogoSrc from 'assets/images/bcg.svg';
import pipeLogoSrc from 'assets/images/pipe.svg';
import ArrowRight from 'components/Icons/ArrowRight';
import { getFirstName, getUserEmail } from 'store/okta_user/selectors';
import { updateLandingPageFlag } from 'store/user/asyncActions';
import { PATHNAME } from 'constants/router';
import { getContactEmail } from 'utils/user';
import './Landing.scss';

function Landing() {
  const history = useHistory();
  const username = useSelector(getFirstName);
  const userEmail = useSelector(getUserEmail);

  const dispatch = useDispatch();

  const [skipLandingPage, setskipLandingPage] = React.useState(false);

  const onClickGo = async () => {
    await dispatch(updateLandingPageFlag({ userEmail, skipLandingPage }));
    history.push(PATHNAME.HOME);
  };

  const ToggleLandingPageStatus = (_e, data) => {
    setskipLandingPage(data.checked);
  };

  return (
    <Container className="landing-container">
      <div className="landing-container__content">
        <header className="landing-container__content__header">
          <Image src={pipeLogoSrc} height="65px" width="auto" />
          <Image src={bcgLogoSrc} height="65px" width="auto" />
        </header>
        <section className="landing-container__content__body">
          <p className="landing-container__content__body__heading">
            Hi {username}, Welcome to PIPEpack!
          </p>
          <p className="landing-container__content__body__description">
            The PIPEpack is an effort to provide a complete view of PIPE
            transaction case experience. The tool offers an easy way to search,
            view and download descriptions of PIPE cases and proposals. Use the
            PIPEpack to strengthen your client pitches and inform your casework.
          </p>
          <p className="landing-container__content__body__description">
            The PIPEpack was developed by the PIPE KT to provide direct access
            to case details. We upload additional records each month to keep
            content complete and current. Please note that case materials are
            filed in a separate repository and are not available for download
            via the tool.
          </p>
          <p className="landing-container__content__body__description">
            If you have any questions or feedback, please contact the&nbsp;
            <span>
              <a
                style={{ color: 'white' }}
                href={`mailto:${getContactEmail()}`}
              >
                <u>PIPE KT</u>
              </a>
            </span>
            .
          </p>
        </section>
        <footer className="landing-container__content__footer">
          <Button
            className="landing-container__content__footer__button"
            color="green"
            onClick={onClickGo}
          >
            <span className="margin-right-10">Lets Get Started</span>
            <ArrowRight />
          </Button>
          <Checkbox
            className="landing-container__content__footer__checkbox"
            label="Do not show this page next time."
            onChange={ToggleLandingPageStatus}
            checked={skipLandingPage}
          />
        </footer>
      </div>
    </Container>
  );
}

export default Landing;
