import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { matchPath, Switch, Route, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import Home from 'containers/Home';
import SearchResult from 'containers/SearchResult';
import UnderProcess from 'containers/UnderProcess';
import AboutPipePack from 'containers/AboutPipePack';
import UserManagement from 'containers/UserManagement';
import MyLists from 'containers/MyLists';
import Modal from 'components/modal';
import Header from 'components/header/Header';
import SidebarMenu from 'components/SidebarMenu/SidebarMenu';
import { getFilter } from 'store/ui/selector';
import { PATHNAME, ROUTES_WITHOUT_SIDE_MENU } from 'constants/router';
import CaseDetails from 'containers/CaseDetails';
import { getUserEmail } from 'store/okta_user/selectors';
import { getBuckets } from 'store/bucket/asyncActions';
import { getRefreshBucketList } from 'store/bucket/selectors';
import bucket from 'store/bucket';

const LandingWithNav = ({ match }) => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const showFilter = useSelector(getFilter);
  const refreshBucketList = useSelector(getRefreshBucketList);
  const [showSideMenu, setShow] = useState(true);
  const [isHomeRoute, setHomeRoute] = useState(false);
  const filterOffset = showFilter ? 86 : 0;
  const pageOffset = (isHomeRoute ? 164 : 94) + filterOffset;

  const { url } = match;
  const getBucketList = useCallback(async () => {
    if (userEmail) {
      const result = await dispatch(getBuckets(userEmail));
      const responseData = unwrapResult(result);
      dispatch(bucket.actions.SET_BUCKET_LIST(responseData));
    }
  }, [dispatch, userEmail]);

  // Hide Side menu if the current route is on of the
  // route mentioned in ROUTES_WITHOUT_SIDE_MENU
  useEffect(() => {
    setShow(
      !ROUTES_WITHOUT_SIDE_MENU.find(route =>
        matchPath(url, {
          path: route,
          exact: true,
        })
      )
    );

    // Checking if the current route is /home,
    // to modify the height of the main container
    // as per the height of the header.
    setHomeRoute(
      matchPath(url, {
        path: PATHNAME.HOME,
        exact: false,
      })
    );
  }, [url]);

  useEffect(() => {
    getBucketList();
  }, [getBucketList, refreshBucketList]);

  return (
    <>
      <div>
        {/* For Upload Modal */}
        {<Modal />}
        <Header />
        <div
          className="main-container"
          style={{
            height: `calc(100vh - ${pageOffset}px)`,
          }}
        >
          {showSideMenu && (
            <div className="left-container">
              <SidebarMenu
                height={`calc(100vh - ${pageOffset}px)`}
                offset={pageOffset}
              />
            </div>
          )}
          <div
            className="right-container"
            style={{
              width: showSideMenu ? 'calc(100% - 115px)' : '100%',
            }}
          >
            <Switch>
              <Route path={PATHNAME.HOME} component={Home} />
              <Route exact path={PATHNAME.SEARCH} component={SearchResult} />
              <Route exact path={PATHNAME.MY_LISTS} component={MyLists} />
              <Route
                exact
                path="/case/:caseId/details"
                component={CaseDetails}
              />
              <Route
                exact
                path={PATHNAME.UNDER_PROCESS}
                component={UnderProcess}
              />
              <Route exact path={PATHNAME.ABOUT} component={AboutPipePack} />
              <Route exact path={PATHNAME.USERS} component={UserManagement} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

LandingWithNav.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(LandingWithNav);
