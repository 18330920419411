import React, { useEffect, useState, useRef } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';

import { removeDuplicates } from 'utils/common';

function getBGColorProperty(isFocused, isSelected, color) {
  if (isSelected) return color;
  if (isFocused) return 'rgba(91, 182, 131, 0.1)';
  return undefined;
}

function getColorProperty(isDisabled, isSelected) {
  if (isDisabled) return '#ccc';
  if (isSelected) return '#fff';
  return 'black';
}

function getActiveBGColorProperty(isDisabled, isSelected, color) {
  if (!isDisabled) {
    return isSelected ? color : 'rgba(91,182,131,0.3)';
  }
  return undefined;
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: '#fff' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = '#5BB683';
    const bgColor = getBGColorProperty(isFocused, isSelected, color);
    const ColorProperty = getColorProperty(isDisabled, isSelected);
    const activeBGColor = getActiveBGColorProperty(
      isDisabled,
      isSelected,
      color
    );
    return {
      ...styles,
      backgroundColor: bgColor,
      color: ColorProperty,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: activeBGColor,
      },
    };
  },
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'rgba(91,182,131,0.1)',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#5BB683',
    fontStyle: 'italic',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#5BB683',
    ':hover': {
      backgroundColor: '#5BB683',
      color: 'white',
    },
  }),
};
function FormAsyncSelect({
  header,
  options,
  selectedValue,
  name,
  onChange,
  placeholder,
  filter,
}) {
  const comboBoxRef = useRef();
  const menuRef = useRef();
  const [selected, setSelected] = useState([]);
  const [newAddedOptions, setNewAddedOptions] = useState([]);

  const promiseOptions = inputValue => {
    // Trigger async call only if 3 or more characters are entered
    if (inputValue?.length >= 3) {
      return filter(inputValue);
    }

    return [];
  };

  let result = '';
  const handleChange = selectedOptions => {
    let updatedNewOptions = [...newAddedOptions];
    setSelected(selectedOptions);
    selectedOptions.forEach(option => {
      if (option.__isNew__) {
        updatedNewOptions = removeDuplicates([
          ...newAddedOptions,
          option.value,
        ]);
        setNewAddedOptions(updatedNewOptions);
      }
    });
    selectedOptions.forEach(x => {
      result = result + ', ' + x.label;
    });
    result = result.substring(1);
    onChange({
      caseUpdateValue: result,
      masterDataUpdateValue: String(
        updatedNewOptions.filter(option =>
          Boolean(
            selectedOptions.find(selectedOption => {
              if (typeof selectedOption.value === 'string') {
                return selectedOption.value.trim() === option;
              } else {
                return selectedOption.value === option;
              }
            })
          )
        )
      ),
      masterDataName: name,
    });
  };

  const handleMenuOpen = () => {
    menuRef.current.style.left = comboBoxRef.current.inputRef.offsetLeft + 'px';
  };

  useEffect(() => {
    const selectedValueArray = selectedValue ? selectedValue.split(',') : [];
    const selectedValueList = selectedValueArray.map(item => ({
      value: item.trim(),
      label: item.trim(),
    }));
    setSelected(selectedValueList);
  }, [selectedValue]);

  useEffect(() => {
    handleMenuOpen();
  }, [selected]);

  return (
    <div className="combo-box-container">
      <div className="combo-box-container__title">{header}</div>
      <AsyncCreatableSelect
        cacheOptions
        isMulti
        placeholder={placeholder}
        menuPortalTarget={menuRef.current}
        onChange={handleChange}
        onMenuOpen={handleMenuOpen}
        defaultOptions={options}
        closeMenuOnSelect={false}
        loadOptions={promiseOptions}
        value={selected}
        styles={colourStyles}
        classNamePrefix="combo-box"
        className="combo-box-container__setwidth"
        ref={comboBoxRef}
        name={name}
      />
      <div ref={menuRef} className="combo-box-menu-container"></div>
    </div>
  );
}
FormAsyncSelect.propTypes = {
  header: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  filter: PropTypes.func.isRequired,
};
FormAsyncSelect.defaultProps = {
  selectedValue: '',
  onChange: () => null,
  placeholder: 'Please select an option from the menu',
};
export default FormAsyncSelect;
