import React from 'react';
import { Redirect } from 'react-router-dom';

import { isAdminUser } from './user';

/**
 * HOC to restrict non-admin user to access given component
 *
 * @param   {Function} Component
 * @returns {Function}
 */
export const checkAccess = Component => {
  function checkAccessComponent() {
    if (isAdminUser()) {
      return <Component />;
    }

    return <Redirect to="/home" />;
  }
  return checkAccessComponent;
};
