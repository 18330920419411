import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';

import { getUser, createUser } from 'store/user/asyncActions';
import ui from 'store/ui';
import { PATHNAME } from 'constants/router';
import okta_user from 'store/okta_user';
import {
  getUserEmail,
  getUserGroups,
  getFirstName,
  getLastName,
} from 'store/okta_user/selectors';
import { getMasterData } from 'store/master/asyncActions';
import { PIPEPACK_ADMIN_GROUP, USER_ROLES } from 'constants/user';
import Landing from 'containers/Landing';
import LoginError from 'containers/LoginError';
import LandingWithNav from './LandingWithNav';
import user from 'store/user';
import { setAnalyticsStaffEmailID } from 'analytics';

export default function ProtectedPage() {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const userGroups = useSelector(getUserGroups);
  const [userInPipePack, setUserInPipePack] = useState(false);
  const [showLandingPageStatus, setshowLandingPageStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);

  const isSuperAdmin = userGroups.some(
    group => group.toLowerCase() === PIPEPACK_ADMIN_GROUP
  );

  useEffect(() => {
    setAnalyticsStaffEmailID(userEmail);
  }, [userEmail]);

  const getUserData = React.useCallback(async () => {
    if (!userEmail) return null;

    setLoading(true);
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(getUser(userEmail))
      .unwrap()
      .then(response => {
        const userInfo = response[0];

        dispatch(user.actions.setUserData(userInfo));
        setshowLandingPageStatus(userInfo?.displayPage);
        setUserInPipePack(true);
      })
      .catch(() => {
        setshowLandingPageStatus(true);
        setUserInPipePack(false);
      })
      .finally(() => {
        setLoading(false);
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  }, [dispatch, userEmail]);

  const fetchMasterData = useCallback(async () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    await dispatch(getMasterData());
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
  }, [dispatch]);

  const createAdminUser = useCallback(async () => {
    const userInfo = {
      firstName,
      lastName,
      email: userEmail,
      role: USER_ROLES.ADMIN.NAME,
      createdBy: 'AUTOMATIC',
    };
    dispatch(createUser(userInfo));
    dispatch(user.actions.setUserData({ ...userInfo, displayPage: true }));
  }, [dispatch, firstName, lastName, userEmail]);

  const checkCreateAdminUserCondition = useCallback(
    () => !loading && !userInPipePack && userGroups.length && isSuperAdmin,
    [isSuperAdmin, loading, userGroups.length, userInPipePack]
  );

  React.useEffect(() => {
    let userInfo = null;
    if (authState?.isAuthenticated && authState?.idToken?.claims) {
      const {
        idToken,
        expiresAt,
        claims: { fullname, email, groups },
      } = authState.idToken;
      userInfo = {
        idToken,
        name: fullname,
        email,
        groups,
        expiresAt,
        accessToken: authState.accessToken.accessToken,
      };
    }
    dispatch(okta_user.actions.setUserInfo(userInfo));
  }, [dispatch, authState, oktaAuth]);

  useEffect(() => {
    fetchMasterData();
  }, [fetchMasterData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (checkCreateAdminUserCondition()) {
      createAdminUser();
    }
  }, [checkCreateAdminUserCondition, createAdminUser]);

  if (!loading && !userInPipePack && !isSuperAdmin) {
    return (
      <LoginError
        error={{
          message: 'You do not have permission to access this application',
        }}
        internalError={true}
      />
    );
  }

  return (
    <Switch>
      <Route path="/refresh" render={() => <></>} />
      {!loading && (
        <Route exact path={PATHNAME.LANDING}>
          {!showLandingPageStatus ? (
            <Redirect to={PATHNAME.HOME} />
          ) : (
            <Landing />
          )}
        </Route>
      )}
      <Route path={`${PATHNAME.LANDING}*`} component={LandingWithNav} />
    </Switch>
  );
}
