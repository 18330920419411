import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Image, Icon } from 'semantic-ui-react';

import editCaseIcon from 'assets/images/EditCase.svg';
import cancel from 'assets/images/icons/cancel.svg';
import save from 'assets/images/icons/save.svg';
import { ConfirmAlert } from 'components/modal/Alert/ConfirmAlert';
import { updateCaseDetails } from 'store/case/asyncActions';
import ui from 'store/ui';
import { prepareUpdateCaseDetailsPayload } from 'utils/case';
import { CaseDetailsContext } from 'utils/context';
import { extractErrorMessage } from 'utils/error';
import { toastError, toastSuccess } from 'utils/toast';
import './Edit.scss';
import { getMajorIndutries } from 'store/master/selectors';

export function Edit({ Content, open, setOpenModal }) {
  const dispatch = useDispatch();
  const { caseId, onUpdateSuccess } = useContext(CaseDetailsContext);

  const [formValues, setFormValues] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const majorIndustries = useSelector(getMajorIndutries);

  const closeModal = () => {
    if (isFormUpdated) {
      setOpenAlert(true);
    } else {
      setOpenModal(false);
      setOpenAlert(false);
      setIsFormUpdated(false);
      setFormValues();
    }
  };

  const setFormData = (formFieldObject, formUpdated) => {
    if (Object.hasOwn(formFieldObject, 'masterDataUpdateValue')) {
      setFormValues(formFieldObject.formData);
      setIsFormUpdated(formUpdated);
    } else {
      setFormValues(formFieldObject);
      setIsFormUpdated(formUpdated);
    }
  };

  const handleSubmit = async () => {
    if (formValues) {
      setLoading(true);
      const payload = prepareUpdateCaseDetailsPayload(
        formValues,
        majorIndustries
      );
      dispatch(
        updateCaseDetails({
          caseId,
          data: payload,
        })
      )
        .unwrap()
        .then(async () => {
          setOpenModal(false);
          setIsFormUpdated(false);
          setFormValues();
          onUpdateSuccess();
          toastSuccess('Case details updated successfully');
        })
        .catch(error => {
          toastError(
            extractErrorMessage(
              error,
              'Error occurred while updating the case details'
            )
          );
          dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOpenModal(false);
    }
  };

  const handleConfirmAlert = () => {
    setOpenAlert(false);
    setOpenModal(false);
    setIsFormUpdated(false);
    setFormValues();
  };

  const handleCancelAlert = () => {
    setOpenAlert(false);
  };

  return (
    <Modal className="edit-modal" onClose={closeModal} open={open}>
      <Modal.Header className="edit-modal__header-content">
        <div className="edit-modal__header-content__header-detail">
          <Image src={editCaseIcon} />
          <span className="edit-modal__header-content__header-detail__header-text">
            Edit Case Details
          </span>
        </div>
      </Modal.Header>

      <Modal.Content>
        <ConfirmAlert
          open={openAlert}
          onCancel={handleConfirmAlert}
          onConfirm={handleCancelAlert}
        />
        <Content setFormData={setFormData} />
      </Modal.Content>
      <Modal.Actions className="footer edit-modal__footer">
        <Button
          onClick={closeModal}
          className="edit-modal__footer__cancel-btn "
        >
          <Icon>
            <Image src={cancel} />
          </Icon>
          <span className="margin-left-15">Cancel</span>
        </Button>
        <Button
          className="green edit-modal__footer__submit"
          onClick={handleSubmit}
          loading={loading}
        >
          <Icon>
            <Image src={save} />
          </Icon>
          <span className="margin-left-15">Save</span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

Edit.propTypes = {
  Content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};
