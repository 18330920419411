import { createSelector } from '@reduxjs/toolkit';

const userState = state => state.user;

/**
 * Returns the role of the logged-in user
 */
export const getRole = createSelector(
  userState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state?.userInfo?.role
);
