import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, matchPath } from 'react-router-dom';
import { Menu, Ref } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import ui from 'store/ui';
import home from 'assets/images/home.svg';
import loadbucket from 'assets/images/load-bucket.svg';
import underProcess from 'assets/images/underProcess.svg';
import aboutPP from 'assets/images/aboutPP.svg';
import contriPP from 'assets/images/contriPP.svg';
import userManage from 'assets/images/userManage.svg';
import uploaddata from 'assets/images/uploaddata.svg';
import { PATHNAME } from 'constants/router';
import { getContactEmail, isAdminUser } from 'utils/user';
import BucketsDropdown from './BucketsDropdown';
import useDropdown from './useDropdownMenu';
import InvestibleThemesDropdown from './InvestibleThemesDropdown';
import MenuItem from './MenuItem';
import './SidebarMenu.scss';
import { trackTileClick } from 'analytics';

const SidebarMenu = ({ height, offset }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const bucketsDropdownRef = useRef();
  const investibleDropdownRef = useRef();
  const myListMenuItemRef = useRef();
  const investibleMenuItemRef = useRef();
  const isMyListsRoute = matchPath(pathname, {
    path: PATHNAME.MY_LISTS,
    exact: false,
  });
  const menuDropdowns = useMemo(
    () => ({
      bucket: {
        menuItemRef: myListMenuItemRef,
        dropdownRef: bucketsDropdownRef,
      },
      investible: {
        menuItemRef: investibleMenuItemRef,
        dropdownRef: investibleDropdownRef,
      },
    }),
    []
  );
  const { open, closeDropdown, openDropdown, calculateDropdownPosition } =
    useDropdown({
      menuDropdowns,
      offset,
    });

  const openUploadModal = () => {
    trackTileClick('Upload Data');
    dispatch(ui.actions.OPEN_MODAL('Upload_Excel'));
  };

  return (
    <div className="sidebar">
      <Menu
        vertical
        className="sidebar-menu"
        style={{ height }}
        onScroll={calculateDropdownPosition}
      >
        <MenuItem
          imageSrc={home}
          title="HOME"
          to={PATHNAME.HOME}
          trackAnalytics={() => {
            trackTileClick('Home');
          }}
        />
        <Ref innerRef={myListMenuItemRef}>
          <MenuItem
            imageSrc={loadbucket}
            title="MY LISTS"
            onClick={openDropdown('bucket')}
            className={isMyListsRoute ? 'active' : ''}
          />
        </Ref>
        {isAdminUser() && (
          <MenuItem
            imageSrc={uploaddata}
            onClick={openUploadModal}
            title="UPLOAD DATA"
          />
        )}
        {isAdminUser() && (
          <MenuItem
            imageSrc={underProcess}
            title="UNDER PROCESS"
            to={PATHNAME.UNDER_PROCESS}
            trackAnalytics={() => {
              trackTileClick('Under Process');
            }}
          />
        )}
        {isAdminUser() && (
          <MenuItem
            imageSrc={userManage}
            title="USER MANAGEMENT"
            to={PATHNAME.USERS}
            trackAnalytics={() => {
              trackTileClick('User Management');
            }}
          />
        )}
        <Ref innerRef={investibleMenuItemRef}>
          <MenuItem
            imageSrc={contriPP}
            title="INVESTABLE THEMES &amp; TEASERS"
            onClick={openDropdown('investible')}
          />
        </Ref>
        <MenuItem
          imageSrc={contriPP}
          title="CONTRIBUTE TO PIPEPACK"
          href={`mailto:${getContactEmail()}?subject=I want to contribute to PIPEpack&body=Share a few notes on how you would like to contribute and PIPE KT will be in touch to coordinate.`}
          trackAnalytics={() => {
            trackTileClick('CONTRIBUTE TO PIPEPACK');
          }}
        />
        <MenuItem
          imageSrc={aboutPP}
          title="ABOUT PIPEPACK"
          to={PATHNAME.ABOUT}
          trackAnalytics={() => {
            trackTileClick('ABOUT PIPEPACK');
          }}
        />
      </Menu>
      <BucketsDropdown
        ref={bucketsDropdownRef}
        open={open['bucket']}
        closeDropdown={closeDropdown('bucket')}
      />
      <InvestibleThemesDropdown
        ref={investibleDropdownRef}
        open={open['investible']}
        closeDropdown={closeDropdown('investible')}
      />
    </div>
  );
};

SidebarMenu.propTypes = {
  height: PropTypes.string,
  offset: PropTypes.number,
};

SidebarMenu.defaultProps = {
  height: '100vh',
  offset: 0,
};

export default SidebarMenu;
