import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Route } from 'react-router-dom';
import { Image, Button, Popup } from 'semantic-ui-react';

import './Header.scss';
import bcg from 'assets/images/bcg.svg';
import info from 'assets/images/info.svg';
import filter from 'assets/images/filter.png';
import white_filter from 'assets/images/white-filter.svg';
import searchIcon from 'assets/images/search.svg';
import pipe from 'assets/images/pipe.svg';
import Search from 'components/Search';
import { PATHNAME } from 'constants/router';
import { DEFAULT_FILTER_OPTIONS } from 'constants/search';
import { getDDStatistics } from 'store/statistics/asyncActions';
import ui from 'store/ui';
import { getFilter } from 'store/ui/selector';
import { isEmpty } from 'utils/common';
import { getSearchQueryParams } from 'utils/search';
import { isAdminUser } from 'utils/user';
import HeaderFilter from './HeaderFilter/HeaderFilter';
import HeaderDashboard from './HeaderDashboard';
import { trackGlobalSearchAndAdvanceFilter } from 'analytics';
import { getSearchAdvanceFilterString, getSearchString } from 'utils/transform';

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [advanceFilter, setAdvanceFilter] = useState(DEFAULT_FILTER_OPTIONS);

  // State Variables
  const [statistics, setStatistics] = useState({
    totalDDs: '-',
    totalDDsWithCaseMaterials: '-',
    totalDDsWithDetailedRecords: '-',
    totalProposals: '-',
  });
  const [searchQuery, setSearchQuery] = useState([]);

  // Selectors
  const showFilter = useSelector(getFilter);

  const onSearch = globalSearch => {
    // Search Query Param string that need to be append in the url
    if (!isSubmitDisabled()) {
      const searchQueryParams = getSearchQueryParams({
        advanceFilter,
        search,
        searchQuery,
      });
      trackGlobalSearchAndAdvanceFilter(
        globalSearch,
        getSearchString(searchQuery),
        getSearchAdvanceFilterString(advanceFilter)
      );
      history.push(`${PATHNAME.SEARCH}?${searchQueryParams}`);
    }
  };

  useEffect(() => {
    if (!showFilter) setAdvanceFilter(DEFAULT_FILTER_OPTIONS);
  }, [showFilter]);
  /**
   * Formats statistics data as per the HeaderDashboard component props
   *
   * @param   {Object} unformattedStatistics
   * @returns {Object} Formatted object for the statistics
   */
  const formatHeaderStatistics = unformattedStatistics => ({
    totalDDs: unformattedStatistics.totalDDs,
    totalDDsWithCaseMaterials: unformattedStatistics.totalDDwithCaseMaterials,
    totalDDsWithDetailedRecords:
      unformattedStatistics.totalDDwithDetailedRecords,
    totalProposals: unformattedStatistics.totalProposals,
  });

  /**
   * API call to get the header statistics
   */
  const getHeaderStatistics = useCallback(async () => {
    const response = await dispatch(getDDStatistics());
    const statisticsResponse = unwrapResult(response);

    setStatistics(formatHeaderStatistics(statisticsResponse));
  }, [dispatch, setStatistics]);

  /**
   * Check if the submit is disabled
   * Non-admin user should be restricted to search by empty search query.
   *
   * @returns {Boolean}
   */
  const isSubmitDisabled = () => !isAdminUser() && isEmpty(searchQuery);

  useEffect(() => {
    getHeaderStatistics();
  }, [getHeaderStatistics]);

  if (pathname === '/') {
    return null;
  }

  return (
    <div className="application-header">
      <div className="application-header__header-ui">
        <div
          className="application-header__header-ui__img"
          onClick={() => {
            history.push(PATHNAME.HOME);
          }}
        >
          <Image
            src={pipe}
            className="application-header__header-ui__img__app-logo"
          />
        </div>

        <div className="application-header__header-ui__main">
          <div style={{ marginRight: '5px' }}>
            <Popup
              trigger={
                <Image
                  src={info}
                  className="application-header__header-ui__main__info-img"
                />
              }
              position="bottom right"
              size="large"
              className="popup-style"
            >
              <Popup.Content>
                <>
                  The following operators can be used to enhance your search:
                  <ul>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>AND:&nbsp;</span>
                      Separate two keywords with AND to search for records that
                      include BOTH of the keywords, together or separate
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>OR:&nbsp;</span>
                      Separate two keywords with OR to search for records that
                      include EITHER keyword in the record
                    </li>
                  </ul>
                </>
              </Popup.Content>
            </Popup>
          </div>

          <div className="application-header__header-ui__main__text">
            <Image
              src={searchIcon}
              className="application-header__header-ui__main__text__search-img"
            />
            <Search
              onSearchChange={setSearchQuery}
              onSubmit={() => onSearch(true)}
            />
            <Button
              className="application-header__header-ui__main__text__go-btn"
              disabled={isSubmitDisabled()}
              onClick={() => onSearch(true)}
            >
              <span className="application-header__header-ui__main__text__go-text">
                GO
              </span>
            </Button>
          </div>

          <Button
            onClick={() => {
              dispatch(ui.actions.TOGGLE_FILTER());
            }}
            className={
              showFilter
                ? 'application-header__header-ui__main__filter-on'
                : 'application-header__header-ui__main__filter'
            }
          >
            <div className="application-header__header-ui__main__filter__filter-img">
              <span
                className={
                  showFilter
                    ? 'application-header__header-ui__main__filter__filter-text-on'
                    : 'application-header__header-ui__main__filter__filter-text'
                }
              >
                Filters
              </span>
              {showFilter ? (
                <Image src={white_filter} />
              ) : (
                <Image src={filter} />
              )}
            </div>
          </Button>
        </div>

        <div className="application-header__header-ui__img">
          <Image src={bcg} />
        </div>
      </div>
      {showFilter ? (
        <HeaderFilter
          isSubmitDisabled={isSubmitDisabled()}
          handleOnClick={onSearch}
          setAdvanceFilter={setAdvanceFilter}
          advanceFilter={advanceFilter}
        />
      ) : null}
      <Route
        path={PATHNAME.HOME}
        render={() => <HeaderDashboard statistics={statistics} />}
      />
    </div>
  );
}
