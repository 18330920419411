import React from 'react';
import PropTypes from 'prop-types';

function ArrowRight({ fill, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.172 7.00066L11.808 1.63666L13.222 0.222656L21 8.00066L13.222 15.7787L11.808 14.3647L17.172 9.00066H0V7.00066H17.172Z"
        fill={fill}
      />
    </svg>
  );
}

ArrowRight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ArrowRight.defaultProps = {
  fill: '#FEFCFA',
  height: '16',
  width: '21',
  viewBox: '0 0 21 16',
};

export default ArrowRight;
