import React from 'react';
import PropTypes from 'prop-types';

function FolderUpArrow({ fill, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34484 2.16667H16.4998C16.7209 2.16667 16.9328 2.25446 17.0891 2.41074C17.2454 2.56702 17.3332 2.77899 17.3332 3V14.6667C17.3332 14.8877 17.2454 15.0996 17.0891 15.2559C16.9328 15.4122 16.7209 15.5 16.4998 15.5H1.49984C1.27882 15.5 1.06686 15.4122 0.910582 15.2559C0.754301 15.0996 0.666504 14.8877 0.666504 14.6667V1.33333C0.666504 1.11232 0.754301 0.900358 0.910582 0.744078C1.06686 0.587797 1.27882 0.5 1.49984 0.5H7.67817L9.34484 2.16667ZM9.83317 8.83333H12.3332L8.99984 5.5L5.6665 8.83333H8.1665V12.1667H9.83317V8.83333Z"
        fill={fill}
      />
    </svg>
  );
}

FolderUpArrow.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FolderUpArrow.defaultProps = {
  fill: '#111',
  height: '15',
  width: '16',
  viewBox: '0 0 15 16',
};

export default FolderUpArrow;
