const reducers = {
  SET_PAGINATION: (state, { payload }) => {
    state.pagination = payload;
  },
  SET_SORT_DATA: (state, { payload }) => {
    state.sortData = payload;
  },
  SET_PREV_PAGE_INFO: (state, { payload }) => {
    state.prevPage = payload;
  },
  SET_SELECTED_COLUMNS: (state, { payload }) => {
    state.selectedColumns = payload;
  },
};

export default reducers;
