import React from 'react';
import { withRouter } from 'react-router-dom';

import CaseDetailsActionsHeader from './CaseDetailsActionsHeader';
import CaseDetailsContent from './CaseDetailsContent';
import './CaseDetails.scss';
import { useEffect } from 'react';
import { Page_Views, trackPageView } from 'analytics';

function CaseDetails() {
  useEffect(() => {
    trackPageView(Page_Views.CASE_DETAILS);
  }, []);

  return (
    <div className="case-details">
      <CaseDetailsActionsHeader />
      <CaseDetailsContent />
    </div>
  );
}

export default withRouter(CaseDetails);
