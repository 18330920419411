import React from 'react';
import PropTypes from 'prop-types';

import { capitalizeFirstLetter } from 'utils/common';
import {
  TYPE_ALERT,
  TYPE_SUCCESS,
  TYPE_INFO,
  TYPE_ERROR,
} from 'constants/toast';

function ToastMessage({ type, message }) {
  return (
    <span className={`toast-${type}`}>
      <b style={{ marginRight: '10px' }}>{capitalizeFirstLetter(type)}!</b>
      {message}
    </span>
  );
}

ToastMessage.propTypes = {
  type: PropTypes.oneOf([TYPE_ALERT, TYPE_SUCCESS, TYPE_INFO, TYPE_ERROR])
    .isRequired,
  message: PropTypes.string.isRequired,
};

export default ToastMessage;
