import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from 'semantic-ui-react';

import TableCell from './TableCell';

const TableRow = ({
  selectedRows,
  collapsing,
  selectRow,
  columns,
  showCheckbox,
  data,
  addSpacer,
  rowHighlighter,
  onRowClick,
}) => {
  const handleRowClick = e => {
    if (onRowClick && typeof onRowClick === 'function') {
      onRowClick(e, data);
    }
  };

  const handleCheckboxClick = e => {
    selectRow(e, { value: rowId, checked: !selectedRows[rowId] });
  };

  const rowId = data.id;

  if (!columns.length) return null;

  return (
    <Fragment>
      {addSpacer && rowHighlighter === null && (
        <Table.Row key={`${rowId}-spacer`} className="row-spacer">
          <Table.Cell colSpan={columns.length}></Table.Cell>
        </Table.Row>
      )}
      <Table.Row
        key={rowId}
        className={selectedRows[rowId] ? 'row-selected' : ''}
        {...rowHighlighter}
        onClick={handleRowClick}
        style={onRowClick ? { cursor: 'pointer' } : null}
      >
        {showCheckbox && (
          <Table.Cell collapsing={collapsing} onClick={handleCheckboxClick}>
            <Checkbox
              name="select-row"
              value={rowId}
              onChange={selectRow}
              checked={selectedRows[rowId] ? true : false}
            />
          </Table.Cell>
        )}
        {columns &&
          columns.map((col, index) => (
            <TableCell
              key={`${rowId}_${col.field}_${index}`}
              col={col}
              data={data}
            />
          ))}
      </Table.Row>
    </Fragment>
  );
};

TableRow.propTypes = {
  data: PropTypes.object,
  selectedRows: PropTypes.objectOf(PropTypes.bool),
  collapsing: PropTypes.bool,
  selectRow: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      style: PropTypes.object,
      hidden: PropTypes.bool,
      cellRender: PropTypes.func,
      field: PropTypes.string,
      type: PropTypes.oneOf(['date']),
      wordHighlighter: PropTypes.bool,
      truncateText: PropTypes.bool,
    })
  ),
  showCheckbox: PropTypes.bool,
  addSpacer: PropTypes.bool,
  rowHighlighter: PropTypes.object,
  onRowClick: PropTypes.func,
};

TableRow.defaultProps = {
  data: {},
  selectedRows: {},
  selectRow: () => null,
  columns: [],
  collapsing: false,
  showCheckbox: false,
  addSpacer: false,
  rowHighlighter: null,
  onRowClick: () => null,
};

export default React.memo(TableRow);
