import React, { useState } from 'react';
import { TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './FormTextArea.scss';
function FormTextArea({ header, value, onChange, name }) {
  const [input, setInput] = useState(value || '');

  const handleInput = e => {
    const updatedValue = e.target.value;
    setInput(updatedValue);
    onChange(e, updatedValue);
  };

  return (
    <div className="form-text-area">
      <div className="form-text-area__label">{header}</div>
      <TextArea
        value={input}
        className="form-text-area__items"
        onChange={handleInput}
        name={name}
      />
    </div>
  );
}
FormTextArea.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
};

FormTextArea.defaultProps = {
  header: '',
  value: '',
  onChange: () => null,
};

export default FormTextArea;
