import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

const Tooltip = ({ content, children }) => {
  return <Popup trigger={children} content={content} basic></Popup>;
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Tooltip;
