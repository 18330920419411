import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const ValueContainer = ({ children, ...props }) => {
  const {
    selectProps: {
      allOption: { value: allOptionValue },
    },
    getValue,
  } = props;
  const currentValues = getValue();

  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOptionValue)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

export default ValueContainer;

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    allOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

ValueContainer.defaultProps = {
  selectProps: {
    allOption: {
      label: 'Select all',
      value: '*',
    },
  },
};
