import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';

import shareIcon from 'assets/images/icons/share.svg';

const ShareButtonWithToolTip = ({
  content,
  label,
  className,
  disabled,
  onClick,
}) => {
  return (
    <Popup
      trigger={
        <Button
          className={`green button--download ${className}`}
          icon={
            <i className="icon">
              <img src={shareIcon} alt={label} />
            </i>
          }
          content={label}
          onClick={onClick}
          disabled={disabled}
        />
      }
      content={content}
      basic
    />
  );
};

ShareButtonWithToolTip.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ShareButtonWithToolTip.defaultProps = {
  className: '',
  content: '',
  disabled: false,
  onClick: () => null,
};

export { ShareButtonWithToolTip };
