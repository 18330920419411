import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import ComboBox from 'components/ComboBox/ComboBox';

import FormTextInput from 'components/FormTextInput';
import { getMajorIndutries, getRelevantPlayers } from 'store/master/selectors';
import { CaseDetailsContext } from 'utils/context';
import ComboBoxWithSelect from 'components/ComboBox/ComboBoxWithSelect';
import {
  getIndustriesPracticeAreaDropdownOptions,
  getSelectedIPAValueFromData,
  getSubSectorDropdownOptions,
  getSubSectorSelectedFormData,
} from 'utils/case';

function EditIndustryDetailBlock({ setFormData }) {
  const { data: industryDetailBlockField } = useContext(CaseDetailsContext);
  const [subSectorOptions, setSubSectorOptions] = useState([]);

  const majorIndustries = useSelector(getMajorIndutries);
  const relevantPlayersList = useSelector(getRelevantPlayers);

  const selectedIPAValue = getSelectedIPAValueFromData(
    industryDetailBlockField
  );

  const selectedSubSectos = getSubSectorSelectedFormData(
    majorIndustries,
    industryDetailBlockField
  );

  const [formFields, setFormFields] = useState({
    majorIndustries: selectedIPAValue,
    subSectors: selectedSubSectos,
    targetIndustry: industryDetailBlockField.targetIndustry,
    relevantPlayers: industryDetailBlockField.relevantPlayers,
    targetName: industryDetailBlockField.targetName,
  });

  const IPAOptions = getIndustriesPracticeAreaDropdownOptions();

  const relevantPlayersOptions = relevantPlayersList
    .filter(({ relevantPlayersName, id }) => relevantPlayersName && id)
    .map(({ relevantPlayersName, id }) => ({
      value: id,
      label: relevantPlayersName,
    }));

  useEffect(() => {
    const subSectors = getSubSectorDropdownOptions(
      majorIndustries,
      formFields.majorIndustries
    );
    setSubSectorOptions(subSectors);
    const availableSubsectors = subSectors
      .filter(subSector => formFields.subSectors.indexOf(subSector.value) > -1)
      .map(option => option.value);

    setFormFields({
      ...formFields,
      subSectors: availableSubsectors,
    });
  }, [formFields.majorIndustries]);

  const handleChange = name => (_e, value) => {
    let isFormUpdated = false;

    setFormFields({
      ...formFields,
      [name]: value,
    });

    if (
      formFields.relevantPlayers !== industryDetailBlockField.relevantPlayers ||
      formFields.targetIndustry !== industryDetailBlockField.targetIndustry ||
      formFields.targetName !== industryDetailBlockField.targetName ||
      formFields.majorIndustries !== selectedIPAValue ||
      formFields.subSectors !== selectedSubSectos
    ) {
      isFormUpdated = true;
    }
    if (_e.masterDataUpdateValue) {
      const data = {
        formData: {
          ...formFields,
          [name]: value,
        },
        masterDataUpdateValue: _e.masterDataUpdateValue,
        masterDataName: _e.masterDataName,
      };
      setFormData(data, isFormUpdated);
    } else {
      setFormData(
        {
          ...formFields,
          [name]: value,
        },
        isFormUpdated
      );
    }
  };

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FormTextInput
            header="Target Name"
            value={formFields.targetName}
            onChange={handleChange('targetName')}
            name="targetName"
          />
        </Grid.Column>
        <Grid.Column>
          <FormTextInput
            header="Target Industry"
            value={formFields.targetIndustry}
            onChange={handleChange('targetIndustry')}
            name="targetIndustry"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <ComboBoxWithSelect
            header="Industry Practice Area"
            options={IPAOptions}
            selectedValue={formFields.majorIndustries}
            onChange={handleChange('majorIndustries')}
            name="majorIndustries"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <ComboBoxWithSelect
            header="Sectors"
            options={subSectorOptions}
            selectedValue={formFields.subSectors}
            onChange={handleChange('subSectors')}
            name="subSectors"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <ComboBox
            header="Relevant Players"
            options={relevantPlayersOptions}
            selectedValue={industryDetailBlockField.relevantPlayers}
            onChange={handleChange('relevantPlayers')}
            name="relevantPlayers"
            type="relevantPlayers"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
EditIndustryDetailBlock.propTypes = {
  setFormData: PropTypes.func,
};
EditIndustryDetailBlock.defaultProps = {
  setFormData: () => null,
};
export default EditIndustryDetailBlock;
