import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox, Grid } from 'semantic-ui-react';

import FormTextInput from 'components/FormTextInput';
import FormTextArea from 'components/FormTextArea';
import DropDown from 'components/FormDropdown';
import { getCaseTypes, getFirmTypes } from 'store/master/selectors';
import { CaseDetailsContext } from 'utils/context';

function EditProjectBlock({ setFormData }) {
  const { data: projectBlockField } = useContext(CaseDetailsContext);

  const caseTypes = useSelector(getCaseTypes);
  const firmTypes = useSelector(getFirmTypes);
  const [caseTypeOptions, setCaseTypeOptions] = useState([]);
  const [firmTypeOptions, setFirmTypeOptions] = useState([]);
  const [checked, setChecked] = useState(
    projectBlockField.hasMaterials?.toUpperCase() === 'Yes'.toUpperCase()
  );

  const caseType1SelectedValue = caseTypes.find(
    ({ caseTypeName }) => caseTypeName === projectBlockField?.caseType1
  )?.caseTypeName;

  const caseType2SelectedValue = caseTypes.find(
    ({ caseTypeName }) => caseTypeName === projectBlockField?.caseType2
  )?.caseTypeName;

  let firmTypeSelectedValue = '';

  firmTypeSelectedValue = firmTypes.find(
    ({ firmTypeValue }) => firmTypeValue === projectBlockField?.firmType
  )?.firmTypeValue;

  const [formFields, setFormFields] = useState({
    projectCodename: projectBlockField.projectCodename,
    firmName: projectBlockField.firmName,
    caseType1: caseType1SelectedValue,
    caseType2: caseType2SelectedValue,
    firmType: firmTypeSelectedValue,
    caseDescription: projectBlockField.caseDescription,
    hasMaterials: projectBlockField.hasMaterials === 'Yes' ? 'Yes' : 'No',
  });

  let isFormUpdated = false;

  const handleChange = name => (_e, value) => {
    const formFieldObject = formFields;
    formFieldObject[name] = value;
    setFormFields(formFieldObject);

    if (
      formFields.projectCodename !== projectBlockField.projectName ||
      formFields.firmName !== projectBlockField.firmName ||
      formFields.caseType1 !== caseType1SelectedValue ||
      formFields.caseType2 !== caseType2SelectedValue ||
      formFields.firmType !== firmTypeSelectedValue ||
      formFields.caseDescription !== projectBlockField.description
    ) {
      isFormUpdated = true;
    }
    setFormData(formFieldObject, isFormUpdated);
  };

  const handleCheck = name => (_e, data) => {
    setChecked(data.checked);
    if (
      formFields.hasMaterials ? 'Yes' : 'No' !== projectBlockField.hasMaterials
    ) {
      isFormUpdated = true;
    }
    const formFieldObject = formFields;
    formFieldObject[name] = data.checked ? 'Yes' : 'No';
    setFormFields(formFieldObject);
    setFormData(formFieldObject, isFormUpdated);
  };

  useEffect(() => {
    const caseTypeList = caseTypes
      .filter(({ caseTypeName }) => caseTypeName)
      .map(({ caseTypeName }) => ({
        value: caseTypeName,
        text: caseTypeName,
      }));
    setCaseTypeOptions(caseTypeList);
  }, [caseTypes]);

  useEffect(() => {
    const firmTypeList = firmTypes
      .filter(({ firmTypeValue }) => firmTypeValue)
      .map(({ firmTypeValue }) => ({
        value: firmTypeValue,
        text: firmTypeValue,
      }));
    setFirmTypeOptions(firmTypeList);
  }, [firmTypes]);

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FormTextInput
            header="Project Codename"
            value={formFields.projectCodename}
            onChange={handleChange('projectCodename')}
            name="projectCodename"
          />
        </Grid.Column>
        <Grid.Column>
          <FormTextInput
            header="Client"
            value={formFields.firmName}
            onChange={handleChange('firmName')}
            name="firmName"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DropDown
            header="Case Type 1"
            options={caseTypeOptions}
            selectedOption={formFields.caseType1}
            onChange={handleChange('caseType1')}
            name="caseType1"
          />
        </Grid.Column>
        <Grid.Column>
          <DropDown
            header="Case Type 2"
            options={caseTypeOptions}
            selectedOption={formFields.caseType2}
            onChange={handleChange('caseType2')}
            name="caseType2"
          />
        </Grid.Column>
        <Grid.Column>
          <DropDown
            header="Client Type"
            options={firmTypeOptions}
            selectedOption={formFields.firmType}
            onChange={handleChange('firmType')}
            name="firmType"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FormTextArea
            header="Case Description"
            value={formFields.caseDescription}
            onChange={handleChange('caseDescription')}
            name="caseDescription"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Checkbox
            label="Has Material"
            onChange={handleCheck('hasMaterials')}
            checked={checked}
            name="hasMaterials"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
EditProjectBlock.propTypes = {
  setFormData: PropTypes.func,
};
EditProjectBlock.defaultProps = {
  setFormData: () => null,
};

export default EditProjectBlock;
