import queryString from 'utils/queryString';
import { PATHNAME } from 'constants/router';
import { DEFAULT_FILTER_OPTIONS, DEFAULT_SEARCH } from 'constants/search';
import { CATEGORY_TO_FILTER_MAP } from 'constants/statistics';
/**
 * Util to divide the columns in the multiple columns
 * as per the home page design
 *
 * @param   {Array}  cards    array of cards
 * @param   {Number} columns  no. of columns in which cards needs to be divided
 * @returns {Array}           Array of card data arrays
 */
export const divideInColumns = (cards, columns = 5) => {
  const columnsWithCards = [];

  // pushing empty array for each column
  for (let i = 1; i <= columns; i++) {
    columnsWithCards.push([]);
  }

  // pushing cards in each columns one by one
  for (let i = 1; i < cards.length + 1; i++) {
    const insertionIndex = i % columns === 0 ? columns - 1 : (i % columns) - 1;
    columnsWithCards[insertionIndex].push(cards[i - 1]);
  }

  return columnsWithCards;
};

/**
 * Util to format card data as per the HomeCardsLayout component props
 *
 * @param   {String} category statistic category
 * @param   {Array}  cards    Array of cards data
 * @returns {Array}           Formatted list of cards data
 */
export const formatStatisticsCardsData = (category, cards) => {
  const filter = CATEGORY_TO_FILTER_MAP[category];
  const getPathName = filterValue => {
    const query = {
      search: JSON.stringify(DEFAULT_SEARCH),
      advanceFilter: JSON.stringify({
        ...DEFAULT_FILTER_OPTIONS,
        [filter]: filterValue,
      }),
    };
    return `${PATHNAME.SEARCH}?${queryString.stringify(query)}`;
  };

  const formattedCards = cards
    // Remove those cards from list which does not have a title or has 0 cases in it.
    .filter(({ totalRecords, value }) => value && totalRecords)
    .map(({ lastMonthRecords, totalRecords, value, filterValue }) => ({
      addedInLastMonth: lastMonthRecords,
      title: value,
      total: totalRecords,
      viewRecordsPath: getPathName(filterValue || [value]),
    }));

  formattedCards.sort((a, b) => {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }

    return 0;
  });

  return formattedCards;
};
