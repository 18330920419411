import { createSelector } from '@reduxjs/toolkit';

const oktaUser = state => state.okta_user;

export const getUserName = createSelector(oktaUser, state => {
  return state?.userInfo?.name || '';
});

export const getFirstName = createSelector(oktaUser, state => {
  const name = state?.userInfo?.name || '';
  return name?.split(' ')[0];
});

export const getLastName = createSelector(oktaUser, state => {
  const name = state?.userInfo?.name || '';
  return name.split(' ').slice(1).join(' ');
});

export const getUserEmail = createSelector(oktaUser, state => {
  return state?.userInfo?.email || '';
});

export const getAccessToken = createSelector(oktaUser, state => {
  return state?.userInfo?.accessToken || '';
});

export const getUserGroups = createSelector(oktaUser, state => {
  return state?.userInfo?.groups || [];
});
