import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import { NON_ADMIN_RECORDS_LIMIT } from 'constants/user';
import axiosInstance from 'utils/axios';
import { isAdminUser } from 'utils/user';

export const searchCaseDetails = createAsyncThunk(
  `${SLICES.DATA_TABLE}/searchCaseDetails`,
  async ({
    pageNumber,
    limit,
    sortBy,
    sortDirection,
    search,
    advanceFilter,
  }) => {
    const res = await axiosInstance.request({
      method: 'POST',
      url: '/v1/search/query',
      data: { pageNumber, limit, sortBy, sortDirection, search, advanceFilter },
    });

    if (res?.data?.data?.matchedRow) {
      let matchedRows = res.data.data.matchedRow;
      if (!isAdminUser() && matchedRows > NON_ADMIN_RECORDS_LIMIT) {
        matchedRows = NON_ADMIN_RECORDS_LIMIT;
      }
      const totalPages = Math.ceil(matchedRows / limit);
      res.data.data.firstPage = pageNumber === 1;
      res.data.data.lastPage = pageNumber === totalPages;
    }

    return res.data;
  }
);

export const getAllCaseDetails = createAsyncThunk(
  `${SLICES.DATA_TABLE}/allCaseDetails`,
  async ({ pageNumber, limit, sortBy, sortDirection }) => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: '/v1/caseDetails/all',
      params: { pageNumber, limit, sortBy, sortDirection },
    });
    return res.data;
  }
);

export const loadCaseDetails = createAsyncThunk(
  `${SLICES.DATA_TABLE}/caseDetails`,
  async (formData, { dispatch }) => {
    return unwrapResult(
      await (formData.search
        ? dispatch(searchCaseDetails(formData))
        : dispatch(getAllCaseDetails(formData)))
    );
  }
);

export const getDuplicateCaseDetails = createAsyncThunk(
  `${SLICES.DATA_TABLE}/duplicate`,
  async ({ limit, pageNumber }) => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: '/v1/caseDetails/duplicate',
      params: { pageNumber, limit },
    });

    if (res?.data?.data?.totalElements) {
      const matchedRows = res.data.data.totalElements;
      const totalPages = Math.ceil(matchedRows / limit);
      res.data.data.firstPage = pageNumber === 1;
      res.data.data.lastPage = pageNumber === totalPages;
    }

    return res.data;
  }
);

export const removeDuplicate = createAsyncThunk(
  `${SLICES.DATA_TABLE}/removeDuplicate`,
  async uid => {
    const res = await axiosInstance.request({
      method: 'DELETE',
      url: `/v1/managementData/delete/${uid}`,
    });
    return res.data;
  }
);

export const mergeDuplicate = createAsyncThunk(
  `${SLICES.DATA_TABLE}/mergeDuplicate`,
  async ({ uid, caseCode }) => {
    const res = await axiosInstance.request({
      method: 'DELETE',
      url: `/v1/managementData/merge/resources/uuid/${uid}/caseCode/${caseCode}`,
    });
    return res.data;
  }
);

export const downloadSearchResult = createAsyncThunk(
  `${SLICES.DATA_TABLE}/downloadResults`,
  async ({
    pageNumber,
    limit,
    sortBy,
    sortDirection,
    search,
    advanceFilter,
    rowsToFetch,
  }) => {
    const res = await axiosInstance.request({
      method: 'POST',
      url: `/v1/search/download`,
      responseType: 'blob',
      params: { rowsToFetch },
      data: { pageNumber, limit, sortBy, sortDirection, search, advanceFilter },
    });
    return res.data;
  }
);
