import { createSlice } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';

import reducers from './reducers';

export default createSlice({
  name: SLICES.BUCKET,
  initialState: {
    bucketList: [],
    selectedBuckets: {},
    refreshBucketList: new Date().valueOf(),
    shareBucketModal: false,
    confirmDeleteModal: false,
  },
  reducers,
  extraReducers: {},
});
