import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMarker } from './useMarker';

import ShowMoreContent from 'components/ShowMoreContent';

const HighlightWord = ({
  content,
  searchWords,
  className,
  highlightClassName,
  truncateText,
}) => {
  const { markerRef, marker } = useMarker();
  //setting unique key to force re-render on content change
  const randomNumber = () => Math.floor(Math.random() * 1000 + 1);
  const [uniqKey] = useState(
    `highlight-${randomNumber()}-${new Date().valueOf()}`
  );

  const mark = React.useCallback(() => {
    if (marker && searchWords.length) {
      const searchRegExp = new RegExp(
        `\\b(${searchWords.join('|')})[^\\s]*(\\b|$)`,
        'i'
      );

      marker.unmark();
      marker.markRegExp(searchRegExp, highlightClassName);
    }
  }, [marker, searchWords, highlightClassName]);

  React.useEffect(() => {
    mark();
  }, [mark]);

  React.useEffect(() => {
    window.addEventListener('resize', mark);

    return () => window.removeEventListener('resize', mark);
  }, [mark]);

  return (
    <span ref={markerRef} className={className}>
      {truncateText ? (
        <ShowMoreContent
          anchorClass="text-more-less"
          onClick={mark}
          keyValue={uniqKey}
        >
          {content}
        </ShowMoreContent>
      ) : (
        content
      )}
    </span>
  );
};

HighlightWord.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  searchWords: PropTypes.array,
  className: PropTypes.string,
  highlightClassName: PropTypes.string,
  truncateText: PropTypes.bool,
};

HighlightWord.defaultProps = {
  searchWords: [],
  className: '',
  highlightClassName: '',
  truncateText: false,
};

export default React.memo(HighlightWord);
