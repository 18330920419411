import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import bucket from './';
import axiosInstance from 'utils/axios';
import { isEmpty } from 'utils/common';
import queryString from 'utils/queryString';

export const getBuckets = createAsyncThunk(
  `${SLICES.BUCKET}/list`,
  async userEmail => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: `/v1/user/buckets/${userEmail}`,
    });

    return res.data;
  }
);

export const saveToBucket = createAsyncThunk(
  `${SLICES.BUCKET}/save`,
  async ({ casesList, bucketId }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axiosInstance.request({
        method: 'POST',
        url: '/v1/bucket/addCase',
        data: {
          bucketId,
          casesList,
        },
      });

      dispatch(bucket.actions.SET_REFRESH_BUCKET_LIST());
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createBucket = createAsyncThunk(
  `${SLICES.BUCKET}/create`,
  async (
    { userEmail, bucketName, casesList },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axiosInstance.request({
        method: 'POST',
        url: '/v1/bucket/create',
        data: {
          bucketName,
          userEmail,
        },
      });

      const bucketId = res.data?.data?.bucketId;
      if (bucketId) {
        const response = await dispatch(saveToBucket({ bucketId, casesList }));
        return unwrapResult(response);
      }
    } catch (err) {
      const error = unwrapResult(err);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteBucket = createAsyncThunk(
  `${SLICES.BUCKET}/delete`,
  async (bucketId, { dispatch, rejectWithValue }) => {
    try {
      const res = await axiosInstance.request({
        method: 'DELETE',
        url: `/v1/bucket/delete/${bucketId}`,
      });

      dispatch(bucket.actions.SET_REFRESH_BUCKET_LIST());
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateBucket = createAsyncThunk(
  `${SLICES.BUCKET}/update`,
  async ({ bucketId, bucketName }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axiosInstance.request({
        method: 'PUT',
        url: `/v1/bucket/update/${bucketId}/${bucketName}`,
      });

      dispatch(bucket.actions.SET_REFRESH_BUCKET_LIST());
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getBucketRecords = createAsyncThunk(
  `${SLICES.BUCKET}/records`,
  async ({ pageNumber, limit, sortBy, sortDirection, bucketIds }) => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: '/v1/bucket/cases',
      params: { pageNumber, limit, sortBy, sortDirection, bucketIds },
    });
    return res.data;
  }
);

export const downloadBucketRecords = createAsyncThunk(
  `${SLICES.BUCKET}/downloadBucketRecords`,
  async ({ bucketIds, rowsToFetch }) => {
    let url = `/v1/bucket/download?${queryString.stringify({ bucketIds })}`;

    if (!isEmpty(rowsToFetch)) {
      url = `${url}&${queryString.stringify({ rowsToFetch })}`;
    }

    const res = await axiosInstance.request({
      method: 'GET',
      responseType: 'blob',
      url,
    });
    return res.data;
  }
);

export const deleteBucketRecords = createAsyncThunk(
  `${SLICES.BUCKET}/deleteCase`,
  async ({ bucketIds, caseIds }) => {
    let url = `/v1/bucket/deleteCase/${bucketIds}`;

    if (!isEmpty(caseIds)) {
      url = `${url}?${queryString.stringify({ caseIds })}`;
    }

    const res = await axiosInstance.request({
      method: 'DELETE',
      url,
    });
    return res.data;
  }
);

export const shareBucket = createAsyncThunk(
  `${SLICES.BUCKET}/shareList`,
  async data => {
    const response = await axiosInstance.request({
      method: 'POST',
      url: '/v1/bucket/shareList',
      data,
    });
    return response.data;
  }
);
