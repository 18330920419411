import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import ComboBox from 'components/ComboBox/ComboBox';
import { getInformationSources } from 'store/master/selectors';
import { CaseDetailsContext } from 'utils/context';

function EditKeyResearchBlock({ setFormData }) {
  const { data: keyResearchBlockField } = useContext(CaseDetailsContext);
  const keyResearch = useSelector(getInformationSources);
  const [keyResearchOptions, setKeyResearchOptions] = useState([]);

  let isFormUpdated = false;

  const handleChange = e => {
    formFields.informationSources = e.caseUpdateValue;
    setFormFields(formFields);
    if (
      formFields.informationSources !== keyResearchBlockField.informationSources
    ) {
      isFormUpdated = true;
    }
    const data = {
      formData: formFields,
      masterDataUpdateValue: e.masterDataUpdateValue,
      masterDataName: e.masterDataName,
    };
    setFormData(data, isFormUpdated);
  };

  useEffect(() => {
    const keyResearchList = keyResearch
      .filter(({ informationSourcesName, id }) => informationSourcesName && id)
      .map(({ informationSourcesName, id }) => ({
        value: id,
        label: informationSourcesName,
      }));
    setKeyResearchOptions(keyResearchList);
  }, [keyResearch]);
  const [formFields, setFormFields] = useState({
    informationSources: keyResearchBlockField.informationSources,
  });

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <ComboBox
            header="Key Research"
            options={keyResearchOptions}
            selectedValue={keyResearchBlockField.informationSources}
            onChange={handleChange}
            name="informationSources"
            placeholder="Input or select Key Research and Data Sources"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

EditKeyResearchBlock.propTypes = {
  setFormData: PropTypes.func,
};

EditKeyResearchBlock.defaultProps = {
  setFormData: () => null,
};

export default EditKeyResearchBlock;
