const reducers = {
  SET_BUCKET_LIST: (state, { payload }) => {
    state.bucketList = payload;
  },
  SET_SELECTED_BUCKET: (state, { payload }) => {
    state.selectedBuckets = payload;
  },
  SET_REFRESH_BUCKET_LIST: state => {
    state.refreshBucketList = new Date().valueOf();
  },
  TOGGLE_SHARE_MODAL: state => {
    state.shareBucketModal = !state.shareBucketModal;
  },
  TOGGLE_CONFIRM_MODAL: state => {
    state.confirmDeleteModal = !state.confirmDeleteModal;
  },
};

export default reducers;
