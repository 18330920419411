import React from 'react';
import PropTypes from 'prop-types';

function Save({ fill, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16V10H14V16H16V4.828L13.172 2H2V16H4ZM1 0H14L18 4V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM6 12V16H12V12H6Z"
        fill={fill}
      />
    </svg>
  );
}

Save.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Save.defaultProps = {
  fill: '#FFF',
  height: '18',
  width: '20',
  viewBox: '0 0 20 18',
};

export default Save;
