import PropTypes from 'prop-types';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { PATHNAME } from 'constants/router';
import CasesByPracticeArea from './CasesByPracticeArea';
import CasesByRegion from './CasesByRegion';
import CasesByTopicArea from './CasesByTopicArea';
import ProposalVsCases from './ProposalVsCases';
import './Home.scss';
import { Page_Views, trackPageView, trackTabClicks } from 'analytics';
import { useEffect } from 'react';

function Home({ history }) {
  const { pathname } = useLocation();
  const handleItemClick = (item, tabname) => () => {
    trackTabClicks(tabname);
    history.push(item);
  };

  useEffect(() => {
    // trackPageView(Page_Views.HOME);
    trackTabClicks('Regions');
  }, []);

  return (
    <div className="home">
      <Menu pointing secondary>
        <Menu.Item
          name="region"
          active={pathname === PATHNAME.HOME_REGION}
          onClick={handleItemClick(PATHNAME.HOME_REGION, 'Regions')}
        >
          Regions
        </Menu.Item>
        <Menu.Item
          name="practice area"
          active={pathname === PATHNAME.HOME_PRACTICE_AREA}
          onClick={handleItemClick(
            PATHNAME.HOME_PRACTICE_AREA,
            'Industry Practice Areas'
          )}
        >
          Industry Practice Areas
        </Menu.Item>
        <Menu.Item
          name="topic area"
          active={pathname === PATHNAME.HOME_TOPIC_AREA}
          onClick={handleItemClick(PATHNAME.HOME_TOPIC_AREA, 'Topic Areas')}
        >
          Topic Areas
        </Menu.Item>
        <Menu.Item
          name="proposal vs case"
          active={pathname === PATHNAME.HOME_PROPOSAL_VS_CASES}
          onClick={handleItemClick(
            PATHNAME.HOME_PROPOSAL_VS_CASES,
            'Cases vs Proposals'
          )}
        >
          Cases vs Proposals
        </Menu.Item>
      </Menu>
      <div>
        <Switch>
          <Redirect exact from={PATHNAME.HOME} to={PATHNAME.HOME_REGION} />
          <Route path={PATHNAME.HOME_REGION} component={CasesByRegion} />
          <Route
            path={PATHNAME.HOME_PRACTICE_AREA}
            component={CasesByPracticeArea}
          />
          <Route path={PATHNAME.HOME_TOPIC_AREA} component={CasesByTopicArea} />
          <Route
            path={PATHNAME.HOME_PROPOSAL_VS_CASES}
            component={ProposalVsCases}
          />
        </Switch>
      </div>
    </div>
  );
}

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Home);
