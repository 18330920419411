import { ERROR_MESSAGE } from 'constants/errors';

/**
 * Custom error class for the search query validation error
 */
export class SearchQueryValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SearchQueryValidationError';
  }
}

/**
 * Extracts error message for the API's response
 *
 * @param {Object}  errorObj
 * @param {String}  defaultMessage
 */
export const extractErrorMessage = (errorObj, defaultMessage = '') => {
  // If the errorCode returned from API has mapping in 'ERROR_MESSAGE' constant,
  // then return the mapped message
  if (errorObj.code && ERROR_MESSAGE[errorObj.code]) {
    return ERROR_MESSAGE[errorObj.code];
  }

  // If errorCode mapping is absent, then returnt he message returned by the API
  if (errorObj?.message && typeof errorObj?.message === 'string') {
    return errorObj.message;
  }

  // If both the conditions are not met, return the default message
  return defaultMessage;
};
