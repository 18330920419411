import { useState, useEffect, useRef } from 'react';
import { textHighlight } from 'utils/textHighlight';

/**
 * @example
 *
 * const { markerRef, marker } = useMarker();
 *
 * React.useEffect(() => {
 *   if ( marker ) {
 *     marker.unmark();
 *     marker.markRegExp(/some text/i, 'hightlight-class');
 *   }
 * }, [marker]);
 *
 * retun <span ref={markerRef}>some text to higlight</span>
 * @returns {object}   - object textHighliter method object with markregExp and unmark methods and
 *                       the marker ref which has to be set as ref for the text wrapping element.
 */
export const useMarker = () => {
  const markerRef = useRef();
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (markerRef.current) {
      const markerobj = textHighlight(markerRef.current);
      setMarker(markerobj);
    }
  }, []);

  return { marker, markerRef };
};
