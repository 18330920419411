import { configureStore } from '@reduxjs/toolkit';

import master from './master';
import ui from './ui';
import okta_user from './okta_user';
import data_table from './data_table';
import user from './user';
import bucket from './bucket';

export default configureStore({
  reducer: {
    master: master.reducer,
    ui: ui.reducer,
    okta_user: okta_user.reducer,
    data_table: data_table.reducer,
    user: user.reducer,
    bucket: bucket.reducer,
  },
});
