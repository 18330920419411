import React from 'react';
import { toast } from 'react-toastify';

import ToastMessage from 'components/ToastMessage';
import Close from 'components/Icons/Close';
import {
  TOAST_TYPE_COLOR,
  TOAST_TYPE,
  TYPE_SUCCESS,
  TYPE_ERROR,
  TYPE_INFO,
  TYPE_ALERT,
} from 'constants/toast';

/**
 * @function showToast
 *
 * @param   {string}   type    - toast type success, info, alert, error
 * @param   {string}   message - text message to display in toast
 * @param   {function} onClose - callnack method for close action
 * @returns {any}
 */
export const showToast = (type, message, onClose) => {
  const closeButton = ({ closeToast }) => (
    <i onClick={closeToast} className="Toastify__close-icon">
      <Close fill={TOAST_TYPE_COLOR[type]} />
    </i>
  );

  return toast.warn(<ToastMessage message={message} type={type} />, {
    type: TOAST_TYPE[type],
    icon: false,
    onClose: onClose,
    closeButton: closeButton(type),
  });
};

/**
 * @function toastSuccess
 *
 * @param   {string}   message - text message to display in toast
 * @param   {function} onClose - callnack method for close action
 * @returns {any}
 */
export const toastSuccess = (message, onClose) => {
  return showToast(TYPE_SUCCESS, message, onClose);
};

/**
 * @function toastError
 *
 * @param   {string}   message - text message to display in toast
 * @param   {function} onClose - callnack method for close action
 * @returns {any}
 */
export const toastError = (message, onClose) => {
  return showToast(TYPE_ERROR, message, onClose);
};

/**
 * @function toastInfo
 *
 * @param   {string}   message - text message to display in toast
 * @param   {function} onClose - callnack method for close action
 * @returns {any}
 */
export const toastInfo = (message, onClose) => {
  return showToast(TYPE_INFO, message, onClose);
};

/**
 * @function toastAlert
 *
 * @param   {string}   message - text message to display in toast
 * @param   {function} onClose - callnack method for close action
 * @returns {any}
 */
export const toastAlert = (message, onClose) => {
  return showToast(TYPE_ALERT, message, onClose);
};

/**
 * @function dismissToast
 *
 * @param   {string} toastId - toast id of the active toast message
 * @returns {any}
 */
export const dismissToast = toastId => {
  return toast.dismiss(toastId);
};
