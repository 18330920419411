import { SearchQueryValidationError } from 'utils/error';
import {
  DEFAULT_FILTER_OPTIONS,
  OPERATOR_ACTIONS,
  OPERATOR_SUGGESTIONS,
  SEARCH_PAYLOAD_TYPES,
} from 'constants/search';

import { addAfter } from './array';
import { isEqual } from './common';
import { toastError } from './toast';
import queryString from 'utils/queryString';

/**
 * Generates a unique search ID
 *
 * @returns {String}
 */
export const generateUniqueSearchId = () => new Date().valueOf();

/**
 * Returns the operator action based on count of
 * new and previuos operators count in the search query
 *
 * @param {Array} newOperators
 * @param {Array} prevOperators
 * @returns
 */
export const getOperatorAction = (newOperators, prevOperators) => {
  if (newOperators.length > prevOperators.length) {
    return OPERATOR_ACTIONS.ADDED;
  } else if (newOperators.length < prevOperators.length) {
    return OPERATOR_ACTIONS.DELETED;
  } else {
    return OPERATOR_ACTIONS.UNCHANGED;
  }
};

/**
 * Returns the operator's updated state when new operator
 * is added in the search query
 *
 * @param   {Object}  param
 * @returns {Object}
 */
export const getOperatorsUpdatedStateOnAdd = ({
  currentCursorPosition,
  operators,
  operatorsInValue,
  wordsFromValue,
}) => {
  let insertionIndex = 0;

  operators.forEach(({ endIndex }) => {
    if (currentCursorPosition > endIndex + 1) {
      insertionIndex++;
    }
  });

  const addedOperator = operatorsInValue[insertionIndex];

  const getStartIndex = () => {
    let operatorOffset = insertionIndex;
    let startIndexValue = 0;

    for (const word of wordsFromValue) {
      if (isOperator(word) && !operatorOffset) {
        break;
      } else if (isOperator(word) && operatorOffset) {
        operatorOffset--;
      }

      startIndexValue = startIndexValue + word.length + 1;
    }

    return startIndexValue;
  };

  const startIndex = getStartIndex();

  return addAfter(operators, insertionIndex, {
    value: addedOperator,
    startIndex,
    endIndex: startIndex + addedOperator.length - 1,
    isOperator: true,
  });
};

/**
 * Returns the operator's updated state when
 * operator is deleted in the search query
 *
 * @param   {Object}  param
 * @returns {Object}
 */
export const getOperatorsUpdatedStateOnDelete = ({
  operators,
  searchValue,
  value,
}) => {
  const searchQueryLengthChange = searchValue.length - value.length;

  let deleteStartIndex = 0;
  let deleteEndIndex = 0;

  for (let i = 0; i < searchValue.length; i++) {
    if (searchValue[i] !== value[i]) {
      deleteStartIndex = i;
      break;
    }
  }

  deleteEndIndex = deleteStartIndex + searchQueryLengthChange;

  return operators
    .filter(operator => {
      return (deleteStartIndex < operator.startIndex &&
        deleteEndIndex < operator.startIndex) ||
        (deleteStartIndex > operator.endIndex &&
          deleteEndIndex > operator.endIndex)
        ? true
        : false;
    })
    .map(operator => {
      if (deleteEndIndex < operator.startIndex) {
        return {
          ...operator,
          startIndex: operator.startIndex - searchQueryLengthChange,
          endIndex: operator.endIndex - searchQueryLengthChange,
        };
      }

      return operator;
    });
};

/**
 * Prepares the payload for search query
 *
 * @param   {Array}   searchQuery
 * @returns {Array}
 */
export const getSearchQueryPayload = searchQuery => {
  if (!Array.isArray(searchQuery)) {
    return [];
  }

  return searchQuery.map(({ type, value }, index) => {
    // Validating the search query
    if (index === 0 && type === SEARCH_PAYLOAD_TYPES.OPERATOR) {
      throw new SearchQueryValidationError(
        'Search query must not start with an operator'
      );
    }

    if (
      index === searchQuery.length - 1 &&
      type === SEARCH_PAYLOAD_TYPES.OPERATOR
    ) {
      throw new SearchQueryValidationError(
        'Search query must not end with an operator'
      );
    }

    if (
      index > 0 &&
      type === SEARCH_PAYLOAD_TYPES.OPERATOR &&
      searchQuery[index - 1].type === SEARCH_PAYLOAD_TYPES.OPERATOR
    ) {
      throw new SearchQueryValidationError(
        'Search query must not have adjacent operators'
      );
    }

    return {
      type,
      value: value.trim(),
    };
  });
};

/**
 * Returns the highlighted text based on the provided operators and text
 *
 * @param {Array}   updatedOperators
 * @param {String}  text
 * @returns
 */
export const getHighlightedText = (updatedOperators, text) => {
  let operatorIdentifierIndex = 0;
  let highlightText = '';

  text.split(' ').forEach((value, index) => {
    let transformedWord = value;

    if (isOperator(value) && updatedOperators[operatorIdentifierIndex]) {
      const operatorString = `operator operator-${value.toLowerCase()}`;
      transformedWord = `<mark class="${
        updatedOperators[operatorIdentifierIndex].isOperator
          ? operatorString
          : 'plain-text'
      }">${value}</mark>`;
      operatorIdentifierIndex++;
    }
    if (index === 0) {
      highlightText = transformedWord;
    } else {
      highlightText = `${highlightText} ${transformedWord}`;
    }
  });

  return highlightText;
};

/**
 * Returns formatted dropdown options for major industries in advance filters
 *
 * @param   {Array} majorIndustries
 * @returns {Array}
 */
export const getMajorIndustriesDropdownOptionsForAdvanceFilters =
  majorIndustries => {
    const groupedData = majorIndustries.reduce((list, eachIndustry) => {
      list[eachIndustry.industryPracticeArea] = [
        ...(list[eachIndustry.industryPracticeArea] || []),
        eachIndustry,
      ];
      return list;
    }, {});

    return Object.entries(groupedData).map(([key, value]) => {
      let otherOption = null;
      const options = [
        {
          label: 'All',
          value: `${key}__ALL`,
          majorIndustry: key,
        },
      ];

      value.forEach(eachSubSector => {
        if (eachSubSector?.subSector?.toLowerCase() === 'other') {
          otherOption = {
            label: eachSubSector.subSector || key,
            value: eachSubSector.id,
            majorIndustry: key,
          };
        } else {
          options.push({
            label: eachSubSector.subSector || key,
            value: eachSubSector.id,
            majorIndustry: key,
          });
        }
      });

      if (otherOption) {
        options.push(otherOption);
      }

      return {
        label: key,
        options,
      };
    });
  };

/**
 * Get search query string for applied search and advance filter
 *
 * @param   {Object} param
 * @returns {String}
 */
export const getSearchQueryParams = ({
  advanceFilter,
  search,
  searchQuery,
}) => {
  try {
    let query = queryString.parse(search);
    const searchQueryPayload = getSearchQueryPayload(searchQuery);
    const searchTerms = { searchId: generateUniqueSearchId() };
    if (searchQueryPayload.length) {
      searchTerms.search = JSON.stringify(searchQueryPayload);
    } else {
      delete query.search;
    }

    const applyFilter =
      advanceFilter && !isEqual(advanceFilter, DEFAULT_FILTER_OPTIONS);
    if (applyFilter) {
      searchTerms.advanceFilter = JSON.stringify(advanceFilter);
    } else {
      delete query.advanceFilter;
    }

    query = {
      ...(query ? query : null),
      ...searchTerms,
    };

    return queryString.stringify(query);
  } catch (error) {
    if (error instanceof SearchQueryValidationError) {
      toastError(error.message);
    }
  }
};

export const getTopicsListOptionsForAdvanceFilters = topics =>
  topics.map(eachTopics => ({
    value: eachTopics.id,
    label: eachTopics.topics,
  }));

/**
 * Checks whether the given word is operator or not
 *
 * @param   {String}  word
 * @returns {Boolean}
 */
export const isOperator = word => {
  return (
    word.toUpperCase() === OPERATOR_SUGGESTIONS.AND ||
    word.toUpperCase() === OPERATOR_SUGGESTIONS.OR
  );
};
