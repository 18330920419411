import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

function ValueContainer({ children, ...props }) {
  const values = children[0];
  const inputValue = props?.selectProps?.inputValue;
  const placeholder = props?.selectProps?.placeholder;

  const getValueContainerChild = () => {
    // Conditional return causes issue with dropdown close on click.
    // So, for the fix we need to iterate over same chilred array.
    // Refer - https://github.com/JedWatson/react-select/issues/4845#issuecomment-937977537
    const valueContainerChildren = children.map(child => {
      if (
        child &&
        (child.type === components.Placeholder ||
          child.type === components.Input)
      ) {
        return child;
      } else if (Array.isArray(child) && !inputValue) {
        return (
          <label key="value_container_label" style={{ position: 'absolute' }}>
            {placeholder}
          </label>
        );
      }
      return null;
    });

    if (values && values.length) {
      valueContainerChildren.push(
        <div
          key="value_container_count"
          className="header-filter__filter-dropdown__value-container__selected-value"
        >
          <label className="header-filter__filter-dropdown__value-container__selected-value__count">
            {values.length}
          </label>
        </div>
      );
    }

    return valueContainerChildren;
  };

  return (
    <components.ValueContainer {...props}>
      {getValueContainerChild()}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
};

ValueContainer.defaultProps = {
  children: '',
};

export default ValueContainer;
