import React from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import placeholderPhoto from 'assets/images/PlaceholderPhoto.svg';
import './CaseTeam.scss';

const TeamMember = ({ alumni, onClick, photo, name, designation }) => {
  return (
    <div className="person" onClick={onClick}>
      <Image src={photo} className="round-img" />
      <div className="case-team__second-block person-details">
        <div className="upper-text">{name}</div>
        <div className="lower-text">{designation}</div>
        {alumni === 'YES' && <div className="lower-text">(Alumni)</div>}
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  alumni: PropTypes.oneOf(['YES', 'NO']),
  onClick: PropTypes.func,
  photo: PropTypes.string,
  name: PropTypes.string,
  designation: PropTypes.string,
};

TeamMember.defaultProps = {
  alumni: 'NO',
  onClick: () => null,
  photo: placeholderPhoto,
  name: '',
  designation: null,
};

export default TeamMember;
