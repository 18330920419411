import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICES } from 'constants/store';
import axiosInstance from 'utils/axios';

export const createUser = createAsyncThunk(
  `${SLICES.USER}/createUser`,
  async data => {
    const response = await axiosInstance.request({
      method: 'POST',
      url: '/v1/users',
      data,
    });
    return response.data;
  }
);

export const getKeyExperts = createAsyncThunk(
  `${SLICES.USER}/getKeyExperts`,
  async (searchValue = '') => {
    const graphqlQuery = {
      query: `query {
        searchFilter( find:"${searchValue}" sortBy:"firstName" limit:10 ){
          employees{
            id
            firstName
            lastName
          }
        totalCount
        }
     }`,
      variables: {},
    };

    const response = await axiosInstance.request({
      isPeopleAPI: true,
      url: '/domain/v2/people',
      method: 'POST',
      data: graphqlQuery,
      headers: {
        psid: '',
      },
    });

    return response.data;
  }
);

export const downloadUsers = createAsyncThunk(
  `${SLICES.USER}/downloadUsers`,
  async () => {
    const response = await axiosInstance.request({
      method: 'GET',
      responseType: 'blob',
      url: '/v1/download',
    });
    return response.data;
  }
);

export const getUser = createAsyncThunk(
  `${SLICES.USER}/getUser`,
  async mail => {
    const res = await axiosInstance.request({
      method: 'GET',
      url: `/v1/users/${mail}`,
    });
    return res.data;
  }
);

export const getUsers = createAsyncThunk(
  `${SLICES.USER}/getUsers`,
  async ({ pageNumber, limit, sortBy, sortDirection, searchQuery }) => {
    const response = await axiosInstance.request({
      method: 'GET',
      params: { pageNumber, limit, sortBy, sortDirection, searchQuery },
      url: '/v1/users/search',
    });
    return response.data;
  }
);

export const getUserByEmailId = createAsyncThunk(
  `${SLICES.USER}/getUserByEmailId`,
  async email => {
    const graphqlQuery = {
      query: `query {
        searchFilter (aliasMatching:["workEmail"] 
        compositeFilter: {logicOperator: AND 
        subFilters: [
         { logicOperator: OR  subFilters: [   
         { valueFilter: { field: "workEmail=*${email}*" } }
         ]	}
         ]} 
         resultFilters:[ "jobPosition", "hostOfficeId", "hostOfficeRegion", "alumni"] dataSet:BCG_ALL limit: 50 offset:0){ 
         employees{ 
         id firstName lastName
         }
     }}`,
      variables: {},
    };

    const response = await axiosInstance.request({
      isPeopleAPI: true,
      url: '/domain/v2/people',
      method: 'POST',
      data: graphqlQuery,
      headers: {
        psid: '',
      },
    });

    return response.data;
  }
);

export const getUserByHRID = createAsyncThunk(
  `${SLICES.USER}/getUserByHRID`,
  async hrid => {
    const graphqlQuery = {
      query: `query {
        employees(ids: "${hrid}") {
          id
          firstName
          lastName
          title
          profilePicture
          alumni
        }
      }
      `,
      variables: {},
    };

    const response = await axiosInstance.request({
      isPeopleAPI: true,
      url: '/domain/v2/people',
      method: 'POST',
      data: graphqlQuery,
      headers: {
        psid: '',
      },
    });

    return response.data;
  }
);

export const getUserPicture = createAsyncThunk(
  `${SLICES.USER}/getUserPicture`,
  async pictureUrl => {
    const response = await axiosInstance.request({
      isPeoplePictureAPI: true,
      method: 'GET',
      responseType: 'blob',
      url: `/domain/v2/people${pictureUrl}`,
      headers: {
        psid: '',
      },
    });
    return response.data;
  }
);

export const updateLandingPageFlag = createAsyncThunk(
  `${SLICES.USER}/updateLandingPageFlag`,
  async ({ userEmail, skipLandingPage }) => {
    const res = await axiosInstance.request({
      method: 'PUT',
      url: `/v1/users/${userEmail}/displayPage/${!skipLandingPage}`,
    });
    return res.data;
  }
);

export const updateUserRole = createAsyncThunk(
  `${SLICES.USER}/updateUserRole`,
  async ({ email, role }) => {
    const res = await axiosInstance.request({
      method: 'PUT',
      url: `/v1/update/${email}/${role}`,
    });
    return res.data;
  }
);

export const removeUser = createAsyncThunk(
  `${SLICES.USER}/removeUser`,
  async ({ email }) => {
    const res = await axiosInstance.request({
      method: 'DELETE',
      url: `/v1/remove/${email}`,
    });
    return res.data;
  }
);
