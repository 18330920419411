import React, { useMemo, useState } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Image, Icon, Progress } from 'semantic-ui-react';

import cancel from 'assets/images/icons/cancel.svg';
import excel from 'assets/images/icons/excel.svg';
import upload from 'assets/images/icons/upload.svg';
import { Alert } from 'components/modal';
import ui from 'store/ui';
import { getOpen, getAlertModal } from 'store/ui/selector';
import { uploadFile } from 'store/ui/asyncActions';
import { PATHNAME } from 'constants/router';
import { toastError } from 'utils/toast';
import { DEFAULT_ERROR } from 'constants/errors';
import { extractErrorMessage } from 'utils/error';
import { getUploadSuccessMessage } from 'utils/case';
import './Upload.scss';

const focusedStyle = {
  borderColor: '#03522d',
};

function Upload() {
  const history = useHistory();
  const { location } = history;
  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState({
    uploading: false,
    percentCompleted: 0,
  });
  const { uploading, percentCompleted } = uploadStatus;
  const open = useSelector(getOpen);
  const alertModal = useSelector(getAlertModal);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
  } = useDropzone({
    maxFiles: 1,
    accept: '.xls,.xlsx',
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
    }),
    [isFocused]
  );

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul style={{ listStyle: 'none', padding: '10px' }}>
        {errors.map(e => (
          <li key={e.code}>
            {file.path} - {file.size} bytes -- {e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  const openModal = () => dispatch(ui.actions.OPEN_MODAL('Upload_Excel'));
  const closeModal = () => dispatch(ui.actions.CLOSE_MODAL('Upload_Excel'));
  const reloadPage = page => {
    history.replace('/refresh');
    history.replace(page);
  };

  const onDrop = () => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    setUploadStatus(true);
    dispatch(uploadFile({ formData, setUploadStatus }))
      .unwrap()
      .then(response => {
        const data = response.data;

        dispatch(ui.actions.SHOW_ALERT_MODAL({ open: true, data }));

        let { pathname } = location;
        const isUnderProcessScreen = matchPath(pathname, {
          path: PATHNAME.UNDER_PROCESS,
        });

        if (data.totalDuplicateCases > 0 && !isUnderProcessScreen) {
          pathname = PATHNAME.UNDER_PROCESS;
        }

        reloadPage(pathname);
        closeModal();
      })
      .catch(error => {
        toastError(extractErrorMessage(error, DEFAULT_ERROR.upload));
      })
      .finally(() => {
        setUploadStatus({
          uploading: false,
          percentCompleted: 0,
        });
      });
  };

  const closeAlert = () => {
    dispatch(ui.actions.SHOW_ALERT_MODAL({ open: true, data: {} }));
  };

  const AlertMessage = () => {
    return getUploadSuccessMessage(alertModal.data);
  };

  return (
    <>
      {open && (
        <Modal
          className="modal-new"
          onClose={closeModal}
          onOpen={openModal}
          open={open}
          closeOnDimmerClick={false}
        >
          <Modal.Header className="modal-new__header">
            <span className="model-new__header__upload-text">
              Upload File ...
            </span>
          </Modal.Header>
          {uploading && (
            <Progress
              percent={percentCompleted}
              color="green"
              size="tiny"
              attached="bottom"
              active
            />
          )}
          <Modal.Content>
            <div {...getRootProps({ style, className: 'modal-new__dropbox' })}>
              <input {...getInputProps()} />
              <div>
                <Image src={excel} />
              </div>
              {isDragActive ? (
                <div className="modal-new__dropbox__drop-text">
                  Drop the files here
                </div>
              ) : (
                <>
                  <div className="modal-new__dropbox__drop-text">
                    Drag & Drop file into the area to upload ...
                  </div>
                  <div className="modal-new__dropbox__or-text">OR</div>
                  <Button
                    className="green"
                    id="upload-btn"
                    icon={
                      <Icon>
                        <img src={upload} alt="Browse Files" />{' '}
                      </Icon>
                    }
                    content="Browse Files"
                  />
                </>
              )}
            </div>
            <aside>
              {fileRejectionItems.length === 1 ? (
                <ul className="modal-new__file-reject">{fileRejectionItems}</ul>
              ) : (
                files.length === 1 && (
                  <ul className="modal-new__file-accept">{files}</ul>
                )
              )}
            </aside>
          </Modal.Content>
          <Modal.Actions className="footer actions modal-new__footer">
            <Button
              onClick={closeModal}
              id="cancel-btn"
              icon={
                <Icon>
                  <img src={cancel} alt="cancel" />
                </Icon>
              }
              content="Cancel"
            />
            <Button
              onClick={onDrop}
              className="green modal-new__footer__submit"
              disabled={files.length <= 0 || uploading}
              content={uploading ? 'Uploading...' : 'Submit'}
            />
          </Modal.Actions>
        </Modal>
      )}
      {alertModal.open && (
        <Alert
          open={alertModal.open}
          message={<AlertMessage />}
          onClose={closeAlert}
        />
      )}
    </>
  );
}

export default Upload;
