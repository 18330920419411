import { createSelector } from '@reduxjs/toolkit';

const dataTable = state => state.data_table;

export const getPagination = createSelector(dataTable, state => {
  return state?.pagination;
});

export const getSort = createSelector(dataTable, state => {
  return state?.sortData;
});

export const getPrevPageInfo = createSelector(dataTable, state => {
  return state?.prevPage;
});

export const getSelectedColumns = createSelector(dataTable, state => {
  return state?.selectedColumns;
});
