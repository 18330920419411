import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Image, Button } from 'semantic-ui-react';

import download_arrow from 'assets/images/download-arrow.svg';
import stat1 from 'assets/images/stat1.svg';
import stat2 from 'assets/images/stat2.svg';
import stat3 from 'assets/images/stat3.svg';
import stat4 from 'assets/images/stat4.svg';
import { downloadCases } from 'store/case/asyncActions';
import { downloadExcelFile } from 'utils/browser';
import { toastError, toastSuccess } from 'utils/toast';
import { isAdminUser } from 'utils/user';
import 'App.scss';
import './HeaderDashboard.scss';

function HeaderDashboard({ statistics }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    totalDDs,
    totalDDsWithCaseMaterials,
    totalDDsWithDetailedRecords,
    totalProposals,
  } = statistics;

  if (pathname === '/') {
    return null;
  }

  const onClickDownloadResults = async () => {
    try {
      toastSuccess('The download of file is in progress');
      const response = await dispatch(downloadCases({ allResults: true }));
      const responseData = unwrapResult(response);
      downloadExcelFile(responseData, {
        contentTypeHeader: 'application/vnd.ms-excel',
        fileName: 'All_Cases.xlsx',
      });
      toastSuccess('Records downloaded successfully');
    } catch (e) {
      toastError('Failed to download the records');
    }
  };

  const onClickUIADashboard = () => {
    window.open(
      'https://tableauha.bcg.com/#/site/KnowledgeBI/views/PipePackAnalytics_16824191293350/HomePage',
      '_blank'
    );
  };

  return (
    <div className="header-dashboard">
      <div className="five-column">
        <div style={{ marginLeft: '25px' }} className="five-column-div ">
          <Image src={stat1} />
          <div className="stat">
            <span className="stat-num">{totalDDs}</span>
            <span className="stat-text">Total DDs (2017 onwards)</span>
          </div>
        </div>
        <div className="five-column-div space">
          <Image src={stat2} />
          <div className="stat">
            <span className="stat-num">{totalDDsWithDetailedRecords}</span>
            <span className="stat-text">Total DDs with detailed records </span>
          </div>
        </div>
        <div className="five-column-div ">
          <Image src={stat3} />
          <div className="stat">
            <span className="stat-num">{totalDDsWithCaseMaterials}</span>
            <span className="stat-text">Total DDs with case materials</span>
          </div>
        </div>
        <div className="five-column-div ">
          <Image src={stat4} />
          <div className="stat">
            <span className="stat-num">{totalProposals}</span>
            <span className="stat-text">Additional Proposals</span>
          </div>
        </div>
      </div>
      {isAdminUser() && (
        <div className="download-btn-wrap">
          <a onClick={onClickUIADashboard}>UIA Dashboard</a>
          <Button className="download" onClick={onClickDownloadResults}>
            <div className="download-img">
              <Image src={download_arrow} />
              <span className="download-text">Download all records</span>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}

HeaderDashboard.propTypes = {
  statistics: PropTypes.shape({
    totalDDs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalDDsWithCaseMaterials: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    totalDDsWithDetailedRecords: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    totalProposals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

HeaderDashboard.defaultProps = {
  statistics: {
    totalDDs: '-',
    totalDDsWithCaseMaterials: '-',
    totalDDsWithDetailedRecords: '-',
    totalProposals: '-',
  },
};

export default HeaderDashboard;
