import { createAsyncThunk } from '@reduxjs/toolkit';

import { MASTER_DATA_TYPE } from 'constants/master';
import { SLICES } from 'constants/store';
import axiosInstance from 'utils/axios';

/**
 * Returns axios instance call for the given type of master data
 *
 * @param   {String}  type
 * @returns {axiosInstance}
 */
const getMasterDataApiCall = type =>
  axiosInstance.request({
    method: 'GET',
    url: `/v1/managementData/${type}`,
  });

/**
 * Extract the actual master data array from the response object
 *
 * @param     {Object} response
 * @returns   {Array}
 */
const extractMasterDataFromResponse = response => response?.data?.data || [];

/**
 * API call to fetch all master data of the system
 */
export const getMasterData = createAsyncThunk(
  `${SLICES.MASTER}/getMasterData`,
  async () => {
    const masterDataToLoad = [
      MASTER_DATA_TYPE.CASE_TYPE,
      MASTER_DATA_TYPE.DIGITAL_MODULES_USED,
      MASTER_DATA_TYPE.DIGITAL_TEAMS_INVOLVED,
      MASTER_DATA_TYPE.FIRM_TYPE,
      MASTER_DATA_TYPE.INFORMATION_SOURCES,
      MASTER_DATA_TYPE.KEY_TERMS,
      MASTER_DATA_TYPE.MAJOR_INDUSTRY,
      MASTER_DATA_TYPE.PIPE_TOPICS,
      MASTER_DATA_TYPE.REGION,
      MASTER_DATA_TYPE.RELEVANT_PLAYERS,
    ];

    // Calling all APIs in parallel to fetch the master data
    const response = await Promise.all(
      masterDataToLoad.map(type => getMasterDataApiCall(type))
    );

    const payloadData = {};

    // Creating formatted data to store in the redux store
    // based on the APIs response
    masterDataToLoad.forEach((type, index) => {
      payloadData[type] = extractMasterDataFromResponse(response[index]);
    });

    return {
      data: payloadData,
    };
  }
);
