export const CONTACT_EMAIL = {
  EUROPE: 'EURPIPEKT@bcg.com',
  AMERICA: 'NAMRPIPEKT@bcg.com',
  ASIA: 'APPIPEKT@bcg.com',
  DEFAULT: 'NAMRPIPEKT@bcg.com',
};

export const PREV_TEXT = 'Prev';

export const NEXT_TEXT = 'Next';
