import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Popup,
} from 'semantic-ui-react';

import cancel from 'assets/images/icons/cancel.svg';
import save from 'assets/images/icons/save.svg';
import Remove from 'components/Icons/Remove';
import EditPencil from 'components/Icons/EditPencil';

function DropdownItem({
  bucketId,
  editItem,
  loading,
  name,
  newBucket,
  onBucketNameChange,
  onCancel,
  onKeyDown,
  onSave,
  selectBucket,
  selectedBuckets,
  showAlertModal,
  showEditor,
}) {
  const IsLoading = () => {
    if (loading) {
      return <Icon loading name="circle notch" />;
    }
    return <Remove fill="#03053D" height="14px" width="14px" />;
  };

  return (
    <Dropdown.Item active={selectedBuckets[bucketId] || false}>
      <div className="item-content">
        {showEditor[bucketId] ? (
          <Input
            name="new-bucket"
            placeholder="List Name"
            className="new-bucket"
            size="mini"
            action
            onKeyDown={onKeyDown}
            focus
          >
            <input
              maxLength="20"
              value={newBucket[bucketId]}
              onChange={onBucketNameChange(bucketId)}
              autoComplete="off"
            />
            <Popup
              content="Cancel"
              trigger={
                <Button
                  onClick={onCancel(bucketId)}
                  className="btn-cancel"
                  disabled={loading}
                >
                  <img src={cancel} alt="cancel" />
                </Button>
              }
            />
            <Popup
              content="Save"
              trigger={
                <Button
                  onClick={onSave(bucketId)}
                  className="btn-save"
                  loading={loading}
                >
                  {!loading && <img src={save} alt="Save" />}
                </Button>
              }
            />
          </Input>
        ) : (
          <>
            <Checkbox
              label={name}
              value={bucketId}
              onChange={selectBucket}
              checked={selectedBuckets[bucketId] || false}
            />
            <span className="item-action">
              <Popup
                content="Edit"
                trigger={
                  <span
                    className="item-action__edit"
                    onClick={editItem(bucketId, name)}
                  >
                    <EditPencil />
                  </span>
                }
              />
              <Popup
                content="Delete"
                trigger={
                  <span
                    className="item-action__delete"
                    onClick={showAlertModal(bucketId)}
                  >
                    <IsLoading />
                  </span>
                }
              />
            </span>
          </>
        )}
      </div>
    </Dropdown.Item>
  );
}

DropdownItem.propTypes = {
  bucketId: PropTypes.number.isRequired,
  editItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  newBucket: PropTypes.object,
  onBucketNameChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectBucket: PropTypes.func.isRequired,
  selectedBuckets: PropTypes.object,
  showAlertModal: PropTypes.func.isRequired,
  showEditor: PropTypes.object,
};

DropdownItem.defaultProps = {
  loading: false,
  newBucket: {},
  selectedBuckets: {},
  showEditor: {},
};

export default DropdownItem;
