import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { getSortData, getSortDirection } from 'utils/DataTable';

const TableHeader = ({
  columns,
  showCheckbox,
  onSortChanage,
  sortDirection,
  sortBy,
  collapsing,
  sortable,
}) => {
  const showSort = col =>
    sortable && sortBy && sortBy === col.field && col.sortable
      ? getSortData(sortDirection)
      : null;

  const sortTable = col => () => {
    sortable &&
      col.sortable &&
      typeof onSortChanage === 'function' &&
      onSortChanage(
        col.field,
        getSortDirection({ sortBy, sortDirection }, col.field)
      );
  };

  if (!columns.length) return null;

  return (
    <Table.Header>
      <Table.Row>
        {showCheckbox && (
          <Table.HeaderCell collapsing={collapsing} style={{ width: '20px' }} />
        )}
        {columns &&
          columns.map((col, index) => (
            <Table.HeaderCell
              key={`${col.field}-${index}`}
              style={{
                ...(col.style ? col.style : null),
                ...(col.hidden ? { display: 'none' } : null),
              }}
              sorted={showSort(col)}
              onClick={sortTable(col)}
              collapsing={collapsing}
            >
              {col.hederCellRenderer
                ? col.hederCellRenderer(col, columns)
                : col.headerName}
            </Table.HeaderCell>
          ))}
      </Table.Row>
    </Table.Header>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  showCheckbox: PropTypes.bool,
  onSortChanage: PropTypes.func,
  sortDirection: PropTypes.number,
  sortBy: PropTypes.string,
  collapsing: PropTypes.bool,
  sortable: PropTypes.bool,
};

TableHeader.defaultProps = {
  showCheckbox: false,
  collapsing: false,
  sortable: false,
  onSortChanage: () => null,
  sortDirection: null,
  sortBy: null,
};

export default TableHeader;
