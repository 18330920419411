import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ColumnToggleButton } from 'components/Button';
import './EditColumns.scss';

const EditColumns = ({ columns, handleColumnChange, disabled }) => {
  const columnsRef = useRef(columns);
  const columnOption = columnsRef.current.reduce(
    (acc, { headerName, field, hidden, isFixed }) => {
      return field && headerName
        ? [...acc, { label: headerName, value: field, hidden, isFixed }]
        : acc;
    },
    []
  );
  const selectedCols = columnOption.filter(col => !col.hidden || col.isFixed);

  return (
    <ColumnToggleButton
      columns={columnOption}
      onColumnsChange={handleColumnChange}
      selectedColumns={selectedCols}
      disabled={disabled}
    />
  );
};

EditColumns.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string,
      field: PropTypes.string,
      isFixed: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
  handleColumnChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EditColumns.defaultProps = {
  columns: [],
  disabled: false,
};

export default EditColumns;
