import axios, { AxiosError } from 'axios';

import { cloneDeep } from './common';
import { getAccessToken } from 'store/okta_user/selectors';
import { API_KEY } from 'constants/okta';

let store;

export const injectStore = _store => {
  store = cloneDeep(_store);
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  showLoading: true,
});

axiosInstance.interceptors.request.use(
  request => {
    const state = store.getState();
    const accessToken = getAccessToken(state);
    const updatedRequest = {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY,
      },
    };

    if (updatedRequest.isPeoplePictureAPI) {
      updatedRequest.baseURL = process.env.REACT_APP_PEOPLE_PICTURE_API_URL;
      updatedRequest.headers['x-api-key'] =
        process.env.REACT_APP_PEOPLE_PICTURE_API_KEY;
    }

    if (updatedRequest.isPeopleAPI) {
      updatedRequest.baseURL = process.env.REACT_APP_PEOPLE_API_URL;
      updatedRequest.headers['x-api-key'] =
        process.env.REACT_APP_PEOPLE_API_KEY;
    }

    return updatedRequest;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  res => res,
  err => {
    const errorCode = err?.response?.data?.errorCode;
    const message = err?.response?.data?.message;
    return Promise.reject(new AxiosError(message, String(errorCode)));
  }
);

export default axiosInstance;
