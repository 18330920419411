import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import ShowMoreContent from 'components/ShowMoreContent';
import edit from 'assets/images/edit.svg';
import industryDetails from 'assets/images/Industry-details.svg';
import EditIndustryDetailBlock from 'components/modal/Edit/EditForms/EditIndustryDetailBlock';

import { Edit } from 'components/modal/Edit/Edit';
import { getMajorIndustrySubSectors, getRelevantPlayers } from 'utils/case';
import { isAdminUser } from 'utils/user';
import './CaseInformation.scss';

function IndustryDetailBlock({ data }) {
  const [randomRelevantPlayers, setRandomRelevantPlayers] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const setOpenModal = state => setOpenEditModal(state);
  const [majorIndustries, setMajorIndustries] = useState('');
  const [subSectors, setSubSectors] = useState('');

  useEffect(() => {
    const { relevantPlayers, targetName } = data;
    setRandomRelevantPlayers(
      getRelevantPlayers({
        relevantPlayers,
        targetName,
        isAdminUser: isAdminUser(),
      })
    );
  }, [data]);

  useEffect(() => {
    const result = getMajorIndustrySubSectors(data);
    setMajorIndustries(result.majorIndustryvalue);
    setSubSectors(result.subSectorValue);
  }, [data]);

  return (
    <>
      <Edit
        Content={EditIndustryDetailBlock}
        open={openEditModal}
        setOpenModal={setOpenModal}
      />
      <div className="case-information__section-header">
        <div className="case-information__section-header__project-icon-name">
          <Image src={industryDetails} className="margin-right-5" />
          <div className="case-information__section-header__project-icon-name__project-name">
            Industry Details
          </div>
        </div>
        {isAdminUser() && (
          <div
            className="case-information__section-header__edit"
            onClick={() => setOpenEditModal(true)}
          >
            <Image
              src={edit}
              className="case-information__section-header__edit__edit-icon"
            />
            <div className="case-information__section-header__edit__edit-text">
              Edit
            </div>
          </div>
        )}
      </div>
      <div className="case-information__project-name-container">
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Industry Practice Area
          </div>
          <div className="case-information__project-name-container__lower-text">
            <ShowMoreContent
              anchorClass="case-information__project-name-container__lower-text__more-less"
              showMoreText="Read More"
              showLessText="Read Less"
              lines={2}
            >
              {majorIndustries}
            </ShowMoreContent>
          </div>
        </div>
        <div className="case-information__project-name-container__vertical-line"></div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Sectors
          </div>
          <div className="case-information__project-name-container__lower-text">
            <ShowMoreContent
              anchorClass="case-information__project-name-container__lower-text__more-less"
              showMoreText="Read More"
              showLessText="Read Less"
              lines={2}
            >
              {subSectors}
            </ShowMoreContent>
          </div>
        </div>
        <div className="case-information__project-name-container__vertical-line"></div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Target Industry
          </div>
          <div className="case-information__project-name-container__lower-text">
            <ShowMoreContent
              anchorClass="case-information__project-name-container__lower-text__more-less"
              showMoreText="Read More"
              showLessText="Read Less"
              lines={2}
            >
              {data.targetIndustry}
            </ShowMoreContent>
          </div>
        </div>
        {isAdminUser() && (
          <>
            <div className="case-information__project-name-container__vertical-line"></div>
            <div className="case-information__project-name-container__block">
              <div className="case-information__project-name-container__upper-text">
                Target Name
              </div>
              <div className="case-information__project-name-container__lower-text">
                {data.targetName}
              </div>
            </div>
          </>
        )}
        <div className="case-information__project-name-container__vertical-line"></div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Relevant Players
          </div>
          <div className="case-information__project-name-container__lower-text">
            <ShowMoreContent
              anchorClass="case-information__project-name-container__lower-text__more-less"
              showMoreText="Read More"
              showLessText="Read Less"
              lines={2}
            >
              {randomRelevantPlayers}
            </ShowMoreContent>
          </div>
        </div>
      </div>
    </>
  );
}

IndustryDetailBlock.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    caseCode: PropTypes.string,
    isCaseOrProposal: PropTypes.string,
    projectCodename: PropTypes.string,
    startDate: PropTypes.string,
    caseType1: PropTypes.string,
    caseType2: PropTypes.string,
    region: PropTypes.string,
    officeLocation: PropTypes.string,
    caseTeam: PropTypes.string,
    firmName: PropTypes.string,
    firmType: PropTypes.string,
    targetName: PropTypes.string,
    businessServices: PropTypes.string,
    businessServicesSubSector: PropTypes.string,
    consumer: PropTypes.string,
    consumerSubSector: PropTypes.string,
    energy: PropTypes.string,
    energySubSector: PropTypes.string,
    financialInstitutions: PropTypes.string,
    financialInstitutionsSubSector: PropTypes.string,
    healthCare: PropTypes.string,
    healthCareSubSector: PropTypes.string,
    industrialGoods: PropTypes.string,
    industrialGoodsSubSector: PropTypes.string,
    insurance: PropTypes.string,
    principalInvestorsPrivateEquity: PropTypes.string,
    publicSector: PropTypes.string,
    technologyMediaAndTelecom: PropTypes.string,
    technologyMediaAndTelecomSubSector: PropTypes.string,
    other: PropTypes.string,
    ifOther: PropTypes.string,
    targetIndustry: PropTypes.string,
    relevantPlayers: PropTypes.string,
    digitalTeamsInvolved: PropTypes.string,
    bcgExperts: PropTypes.string,
    informationSources: PropTypes.string,
    caseDescription: PropTypes.string,
    keyTerms: PropTypes.string,
    hasMaterials: PropTypes.string,
    detailedDescriptions: PropTypes.string,
    pipeTopics: PropTypes.string,
    digitalModulesUsed: PropTypes.string,
    valueCreationLevels: PropTypes.string,
    creationalTimestamp: PropTypes.string,
    majorIndustries: PropTypes.string,
    subSectors: PropTypes.string,
    duplicate: PropTypes.bool,
  }),
};

IndustryDetailBlock.defaultProps = {
  data: {
    id: '',
    caseCode: '',
    isCaseOrProposal: '',
    projectCodename: '',
    startDate: '',
    caseType1: '',
    caseType2: '',
    region: '',
    officeLocation: '',
    caseTeam: '',
    firmName: '',
    firmType: '',
    targetName: '',
    businessServices: '',
    businessServicesSubSector: '',
    consumer: '',
    consumerSubSector: '',
    energy: '',
    energySubSector: '',
    financialInstitutions: '',
    financialInstitutionsSubSector: '',
    healthCare: '',
    healthCareSubSector: '',
    industrialGoods: '',
    industrialGoodsSubSector: '',
    insurance: '',
    principalInvestorsPrivateEquity: '',
    publicSector: '',
    technologyMediaAndTelecom: '',
    technologyMediaAndTelecomSubSector: '',
    other: '',
    ifOther: '',
    targetIndustry: '',
    relevantPlayers: '',
    digitalTeamsInvolved: '',
    bcgExperts: '',
    informationSources: '',
    caseDescription: '',
    keyTerms: '',
    hasMaterials: '',
    detailedDescriptions: '',
    pipeTopics: '',
    digitalModulesUsed: '',
    valueCreationLevels: '',
    creationalTimestamp: '',
    majorIndustries: '',
    subSectors: '',
    duplicate: false,
  },
};

export default IndustryDetailBlock;
