import { React } from 'react';
import PropTypes from 'prop-types';
import { Container, Message } from 'semantic-ui-react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

const LoginError = ({ error, internalError }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();

  if (!internalError && authState && authState.isAuthenticated) {
    history.push('/');
    return null;
  }

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <Container>
      <Message negative>
        <Message.Header>Access Denied</Message.Header>
        <p>{error.message}. Please contact admin for access.</p>
        <button onClick={logout}>Logout</button>
      </Message>
    </Container>
  );
};

export default withOktaAuth(LoginError);

LoginError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  internalError: PropTypes.bool,
};

LoginError.defaultProps = {
  internalError: false,
};
