import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import popOut from 'assets/images/pop-out.svg';
import { CASE_TYPES } from 'constants/case';
import ProjectBlock from './ProjectBlock';
import IndustryDetailBlock from './IndustryDetailBlock';
import KeyExpertAndTopicBlock from './KeyExpertAndTopicBlock';
import DigitalContextBlock from './DigitalContextBlock';
import KeyResearchBlock from './KeyResearchBlock';
import KeyTermBlock from './KeyTermBlock';
import './CaseInformation.scss';

function CaseInformation({ data, knGUID }) {
  const getCaseTypeHeader = () => {
    return [data.caseType1, data.caseType2]
      .filter(caseType => caseType && caseType.toUpperCase() !== 'NA')
      .map((caseType, index) => (
        <div
          className="case-information__header__case-type"
          key={`${caseType}-${index}`}
        >
          {index > 0 ? '; ' : null}
          {caseType}
        </div>
      ));
  };

  const onClickCaseCode = () => {
    if (knGUID) {
      window.open(
        `${process.env.REACT_APP_KNOWLEDGE_NAVIGATOR_CASE_URL}/${knGUID}`
      );
    }
  };

  return (
    <div className="case-information">
      <div className="case-information__header">
        {data?.isCaseOrProposal === CASE_TYPES.CASE ? (
          <Image src={popOut} className="case-information__header__popout" />
        ) : null}
        <div
          className={
            data?.isCaseOrProposal === CASE_TYPES.CASE
              ? 'case-information__header__case-id case-information__header__underline'
              : 'case-information__header__case-id'
          }
          onClick={onClickCaseCode}
        >
          {data.caseCode}
        </div>
        {getCaseTypeHeader()}
      </div>
      <hr className="case-information__hr" />
      <div>
        <ProjectBlock
          projectName={data.projectCodename}
          description={data.caseDescription}
          caseType1={data.caseType1}
          caseType2={data.caseType2}
          firmName={data.firmName}
          firmType={data.firmType}
          hasMaterials={data.hasMaterials}
        />
      </div>
      <div>
        <IndustryDetailBlock data={data} />
      </div>
      <Grid className="case-information__grid">
        <Grid.Row className="case-information__grid__row">
          <Grid.Column width={10}>
            <KeyExpertAndTopicBlock
              keyExperts={data.bcgExperts}
              topicArea={data.pipeTopics}
              valueCreationLevels={data.valueCreationLevels}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <KeyResearchBlock informationSources={data.informationSources} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="case-information__grid__row">
          <Grid.Column width={10}>
            <DigitalContextBlock
              digitalTeamsInvolved={data.digitalTeamsInvolved}
              digitalModulesUsed={data.digitalModulesUsed}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <KeyTermBlock keyTerms={data.keyTerms} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

CaseInformation.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    caseCode: PropTypes.string,
    isCaseOrProposal: PropTypes.oneOf([CASE_TYPES.CASE, CASE_TYPES.PROPOSAL]),
    projectCodename: PropTypes.string,
    startDate: PropTypes.string,
    caseType1: PropTypes.string,
    caseType2: PropTypes.string,
    region: PropTypes.string,
    officeLocation: PropTypes.string,
    caseTeam: PropTypes.string,
    firmName: PropTypes.string,
    firmType: PropTypes.string,
    targetName: PropTypes.string,
    businessServices: PropTypes.string,
    businessServicesSubSector: PropTypes.string,
    consumer: PropTypes.string,
    consumerSubSector: PropTypes.string,
    energy: PropTypes.string,
    energySubSector: PropTypes.string,
    financialInstitutions: PropTypes.string,
    financialInstitutionsSubSector: PropTypes.string,
    healthCare: PropTypes.string,
    healthCareSubSector: PropTypes.string,
    industrialGoods: PropTypes.string,
    industrialGoodsSubSector: PropTypes.string,
    insurance: PropTypes.string,
    principalInvestorsPrivateEquity: PropTypes.string,
    publicSector: PropTypes.string,
    technologyMediaAndTelecom: PropTypes.string,
    technologyMediaAndTelecomSubSector: PropTypes.string,
    other: PropTypes.string,
    ifOther: PropTypes.string,
    targetIndustry: PropTypes.string,
    relevantPlayers: PropTypes.string,
    digitalTeamsInvolved: PropTypes.string,
    bcgExperts: PropTypes.string,
    informationSources: PropTypes.string,
    caseDescription: PropTypes.string,
    keyTerms: PropTypes.string,
    hasMaterials: PropTypes.oneOf(['Yes', 'No']),
    detailedDescriptions: PropTypes.string,
    pipeTopics: PropTypes.string,
    digitalModulesUsed: PropTypes.string,
    valueCreationLevels: PropTypes.string,
    creationalTimestamp: PropTypes.string,
    majorIndustries: PropTypes.string,
    subSectors: PropTypes.string,
    duplicate: PropTypes.bool,
  }),
  knGUID: PropTypes.string,
};

CaseInformation.defaultProps = {
  data: {
    id: '',
    caseCode: '',
    isCaseOrProposal: '',
    projectCodename: '',
    startDate: '',
    caseType1: '',
    caseType2: '',
    region: '',
    officeLocation: '',
    caseTeam: '',
    firmName: '',
    firmType: '',
    targetName: '',
    businessServices: '',
    businessServicesSubSector: '',
    consumer: '',
    consumerSubSector: '',
    energy: '',
    energySubSector: '',
    financialInstitutions: '',
    financialInstitutionsSubSector: '',
    healthCare: '',
    healthCareSubSector: '',
    industrialGoods: '',
    industrialGoodsSubSector: '',
    insurance: '',
    principalInvestorsPrivateEquity: '',
    publicSector: '',
    technologyMediaAndTelecom: '',
    technologyMediaAndTelecomSubSector: '',
    other: '',
    ifOther: '',
    targetIndustry: '',
    relevantPlayers: '',
    digitalTeamsInvolved: '',
    bcgExperts: '',
    informationSources: '',
    caseDescription: '',
    keyTerms: '',
    hasMaterials: 'No',
    detailedDescriptions: '',
    pipeTopics: '',
    digitalModulesUsed: '',
    valueCreationLevels: '',
    creationalTimestamp: '',
    majorIndustries: '',
    subSectors: '',
    duplicate: false,
  },
  knGUID: null,
};

export default CaseInformation;
