import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { formatDate } from 'utils/common';
import { getTableCellContent } from 'utils/DataTable';

const TableCell = ({ col, data, collapsing }) => {
  const content =
    col.type === 'date' ? formatDate(data[col.field]) : data[col.field];

  return (
    <Table.Cell
      className={col.className ? col.className : ''}
      style={{
        ...(col.style ? col.style : null),
        ...(col.hidden ? { display: 'none' } : null),
      }}
      collapsing={collapsing}
    >
      {getTableCellContent({ data, col, content })}
    </Table.Cell>
  );
};

TableCell.propTypes = {
  data: PropTypes.object.isRequired,
  col: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
    hidden: PropTypes.bool,
    cellRender: PropTypes.func,
    field: PropTypes.string,
    type: PropTypes.oneOf(['date']),
    wordHighlighter: PropTypes.bool,
    truncateText: PropTypes.bool,
  }).isRequired,
  collapsing: PropTypes.bool,
};

TableCell.defaultProps = {
  collapsing: false,
};

export default React.memo(TableCell);
