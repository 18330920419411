import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { OPERATOR_ACTIONS, SEARCH_PAYLOAD_TYPES } from 'constants/search';
import {
  getHighlightedText,
  getOperatorAction,
  getOperatorsUpdatedStateOnAdd,
  getOperatorsUpdatedStateOnDelete,
  isOperator,
} from 'utils/search';

import cancel from 'assets/images/icons/cancel.svg';

import OperatorSuggestions from './OperatorSuggestions';
import './Search.scss';
import { Icon, Image } from 'semantic-ui-react';

function Search({ onSearchChange, onSubmit }) {
  const backdropRef = React.createRef(null);
  const highlightRef = React.createRef(null);
  const searchInputRef = React.createRef(null);

  // state variable to displaying of the Operator Suggestion box
  const [showOperatorSuggestions, setShowOperatorSuggestions] =
    React.useState(false);

  const [operators, setOperators] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedOperator, setSelectedOperator] = useState('');

  const checkLastEnteredWord = () => {
    // Current value in the input
    const value = searchInputRef.current.value;
    // Current position of the cursor
    const currentCursorPosition = getCursorPosition();
    // Array of words in the input
    const wordsFromValue = value.split(' ');

    // Toggle the operators suggestions dropdown based on the last entered value
    toggleOperatorSuggestions({
      currentCursorPosition,
      value,
      wordsFromValue,
    });

    // Array of operators in the input
    const operatorsInValue = wordsFromValue.filter(word => isOperator(word));
    // Identify whether the operator added, deleted or is unchanged after last change
    const operatorAction = getOperatorAction(operatorsInValue, operators);

    // Update 'operators' state based on the operator action value
    if (operatorAction === OPERATOR_ACTIONS.ADDED) {
      const operatorsUpdatedState = getOperatorsUpdatedStateOnAdd({
        currentCursorPosition,
        operators,
        operatorsInValue,
        wordsFromValue,
      });
      setOperators(operatorsUpdatedState);
    } else if (operatorAction === OPERATOR_ACTIONS.DELETED) {
      const operatorsUpdatedState = getOperatorsUpdatedStateOnDelete({
        operators,
        searchValue,
        value,
      });
      setOperators(operatorsUpdatedState);
    } else {
      // Update operator's startIndex and endIndex if text is added
      // between the existing search text
      if (currentCursorPosition < searchValue.length) {
        const valueLengthDifference = value.length - searchValue.length;
        const updatedOperators = operators.map(operator => {
          if (
            currentCursorPosition <
            operator.startIndex + valueLengthDifference
          ) {
            return {
              ...operator,
              startIndex: operator.startIndex + valueLengthDifference,
              endIndex: operator.endIndex + valueLengthDifference,
            };
          } else {
            return operator;
          }
        });

        setOperators(updatedOperators);
      }
    }

    setSearchValue(value);
  };

  /**
   * Close the operator suggestions
   */
  const closeOperatorSuggestions = () => {
    setShowOperatorSuggestions(false);
  };

  /**
   * Returns the current position of the cursor
   *
   * @returns {Number}
   */
  const getCursorPosition = () => {
    const el = searchInputRef.current;
    let end = 0;

    if (
      typeof el.selectionStart == 'number' &&
      typeof el.selectionEnd == 'number'
    ) {
      end = el.selectionEnd;
    }

    return end;
  };

  /**
   * Returns the word at current cursor position
   *
   * @returns {String}
   */
  const getWordAtCursorPosition = () => {
    const cursorPos = getCursorPosition();
    const value = searchInputRef.current.value;
    const words = value.split(' ');

    let wordAtPosition = '';
    let positionOffset = 0;

    for (const word of words) {
      if (cursorPos <= positionOffset + word.length) {
        wordAtPosition = word;
        break;
      }
      positionOffset = positionOffset + word.length + 1;
    }

    return wordAtPosition;
  };

  const handleEnterKeyPress = e => {
    // On click 'Enter', do not go to the next line. Submit the query instead.
    if (e.keyCode === 13) {
      e.preventDefault();
      closeOperatorSuggestions();
      onSubmit();
    }
  };

  const handleInput = () => {
    checkLastEnteredWord();
    setSearchValue(searchInputRef.current.value);
    setCursorPosition(getCursorPosition());
  };

  /**
   * Handler when a click happens in the input
   */
  const handleOnClick = () => {
    // Identifying the word at clicked position
    const wordAtPosition = getWordAtCursorPosition();

    // If word is an operator, then open the operator suggestions dropdown,
    // else close dropdown if open
    if (isOperator(wordAtPosition)) {
      setShowOperatorSuggestions(true);
      setSelectedOperator(wordAtPosition.toUpperCase());
    } else {
      setShowOperatorSuggestions(false);
      setSelectedOperator('');
    }

    // updating the cursor position
    setCursorPosition(getCursorPosition());
  };

  const handleScroll = e => {
    const scrollTop = e.target.scrollTop;
    backdropRef.current.scrollTo({
      top: scrollTop,
    });
  };

  /**
   * Callback function for the OperatorSuggestions component
   * to handle the selected option
   *
   * @param {String} selectedOption
   */
  const onSelectSuggestion = selectedOption => {
    // update the operator's 'isOperator' value
    // in the 'operators' state
    const updatedOperators = operators.map(operator => {
      if (
        cursorPosition >= operator.startIndex &&
        cursorPosition <= operator.endIndex + 1
      ) {
        return {
          ...operator,
          isOperator: isOperator(selectedOption),
        };
      }
      return operator;
    });

    closeOperatorSuggestions();
    setOperators(updatedOperators);
  };

  const prepareSearchQuery = useCallback((value, updateOperators) => {
    if (!value.trim()) {
      return [];
    }

    const words = value.split(' ');
    let operatorIdentifierIndex = 0;

    return (
      words
        .map(word => {
          if (updateOperators[operatorIdentifierIndex] && isOperator(word)) {
            const isWordOperator =
              updateOperators[operatorIdentifierIndex].isOperator;

            operatorIdentifierIndex++;

            // marking operators and normal text separately in a array
            if (isWordOperator) {
              return {
                isOperator: true,
                type: SEARCH_PAYLOAD_TYPES.OPERATOR,
                value: word,
              };
            } else {
              return {
                type: SEARCH_PAYLOAD_TYPES.TEXT,
                value: word,
              };
            }
          } else {
            return {
              type: SEARCH_PAYLOAD_TYPES.TEXT,
              value: word,
            };
          }
        })
        // if, operator is considered as plain text then merge it with the last word
        .reduce((prevValue, currentValue, index) => {
          // if, index is not 0
          // and, word at last index is not of type OPERATOR
          // merge the word with word at last index
          if (
            !currentValue.isOperator &&
            index > 0 &&
            prevValue[prevValue.length - 1].type === SEARCH_PAYLOAD_TYPES.TEXT
          ) {
            const updatedPrevValue = [...prevValue];
            const lastValue = updatedPrevValue[prevValue.length - 1];
            updatedPrevValue[prevValue.length - 1] = {
              ...lastValue,
              value: `${lastValue.value} ${currentValue.value}`,
            };
            return updatedPrevValue;
          }

          return [...prevValue, currentValue];
        }, [])
    );
  }, []);

  /**
   * Toggles the operator suggestion dropdown on and off
   * based on the last entered word in the search input
   *
   * @param {Object}
   */
  const toggleOperatorSuggestions = ({
    currentCursorPosition,
    value,
    wordsFromValue,
  }) => {
    // substring starting from 0 to the current cursor position
    const substring = value.substring(0, currentCursorPosition);
    // array of all words in the substring
    const wordsFromSubstring = substring.split(' ');
    // the last updated word in the input
    const lastWordFromSubstring =
      wordsFromSubstring[wordsFromSubstring.length - 1];

    // If last entered word is an operator,
    // then open the operator suggestion dropdown, else
    // close the dropdown
    if (
      isOperator(lastWordFromSubstring) &&
      wordsFromValue[wordsFromSubstring.length - 1] === lastWordFromSubstring
    ) {
      setShowOperatorSuggestions(true);
      setSelectedOperator(lastWordFromSubstring.toUpperCase());
    } else {
      setShowOperatorSuggestions(false);
      setSelectedOperator('');
    }
  };

  /**
   * Update the highlight in backdrop when 'operators' are changed
   */
  useEffect(() => {
    const text = searchInputRef.current.value;
    const highlightText = getHighlightedText(operators, text);
    highlightRef.current.innerHTML = highlightText;
  }, [highlightRef, operators, searchInputRef]);

  /**
   * Effect to run whenever value changes in the search input
   */
  useEffect(() => {
    const searchQuery = prepareSearchQuery(searchValue, operators);
    onSearchChange(searchQuery);
  }, [onSearchChange, operators, prepareSearchQuery, searchValue]);

  return (
    <div className="search-container">
      {/* The backdrop component which sits in background to highlight the operators */}
      <div ref={backdropRef} className="backdrop">
        <div ref={highlightRef} className="highlights"></div>
      </div>
      {/* The main text area component which is used to take the inputs */}
      <textarea
        ref={searchInputRef}
        rows={1}
        placeholder="Search for cases and proposals using keywords. Use operators - AND/OR for better results."
        onClick={handleOnClick}
        onInput={handleInput}
        onKeyDown={handleEnterKeyPress}
        onScroll={handleScroll}
        onBlur={closeOperatorSuggestions}
      />
      <div className="clear-button">
        <Icon
          onClick={() => {
            searchInputRef.current.value = '';
          }}
        >
          <Image src={cancel} />
        </Icon>
      </div>
      {/* The operator suggestions dropdown, which opens when an operator is entered or clicked */}
      {showOperatorSuggestions && (
        <OperatorSuggestions
          // Positioning suggestion dropdown to keep it close to the caret position
          leftOffset={20 + cursorPosition * 7}
          onSelectSuggestion={onSelectSuggestion}
          selectedOperator={selectedOperator}
        />
      )}
    </div>
  );
}

Search.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Search;
