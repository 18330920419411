import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ui from 'store/ui';
import HomeCardsLayout from 'components/HomeCardsLayout';
import { STATISTICS_CATEGORY } from 'constants/statistics';
import { getStatistics } from 'store/statistics/asyncActions';
import { formatStatisticsCardsData } from 'utils/cards';

function ProposalVsCases() {
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);

  const getCards = React.useCallback(async () => {
    try {
      dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));

      // Fetching statistics by PROPOSAL VS CASE to show the details in cards
      const response = await dispatch(
        getStatistics(STATISTICS_CATEGORY.PROPOSAL_VS_CASE)
      );
      const { listOfGetStatisticsValues } = unwrapResult(response);
      setCards(
        formatStatisticsCardsData(
          STATISTICS_CATEGORY.PROPOSAL_VS_CASE,
          listOfGetStatisticsValues
        )
      );
    } finally {
      dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  return <HomeCardsLayout columns={4} cards={cards} pluralizeTitle={true} />;
}

export default ProposalVsCases;
