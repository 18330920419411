import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';

const ShowMoreContent = ({
  lines,
  showMoreText,
  showLessText,
  className,
  anchorClass,
  onClick,
  showExpanded,
  width,
  endingText,
  children,
  keyValue,
}) => {
  //setting unique key to force re-render on content change
  const randomNumber = () => Math.floor(Math.random() * 1000 + 1);
  const [uniqKey, setUniqkey] = useState(
    keyValue || `${randomNumber()}-${new Date().valueOf()}`
  );

  useEffect(() => {
    if (!keyValue) setUniqkey(`${randomNumber()}-${new Date().valueOf()}`);
  }, [children, keyValue]);

  if (
    !children ||
    (typeof children === 'string' && children.trim().length <= 0)
  )
    return null;
  return (
    <ShowMoreText
      key={`showmoretext-${uniqKey}`}
      lines={lines}
      more={showMoreText}
      less={showLessText}
      className={className}
      anchorClass={anchorClass}
      onClick={onClick}
      expanded={showExpanded}
      width={width}
      truncatedEndingComponent={endingText}
    >
      {children}
    </ShowMoreText>
  );
};

ShowMoreContent.propTypes = {
  lines: PropTypes.number,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
  className: PropTypes.string,
  anchorClass: PropTypes.string,
  onClick: PropTypes.func,
  showExpanded: PropTypes.bool,
  width: PropTypes.number,
  endingText: PropTypes.string,
  children: PropTypes.node,
  keyValue: PropTypes.string,
};

ShowMoreContent.defaultProps = {
  lines: 3,
  showMoreText: 'More',
  showLessText: 'Less',
  className: '',
  anchorClass: '',
  onClick: () => null,
  showExpanded: false,
  width: 0,
  endingText: '... ',
  children: null,
  keyValue: null,
};

export default React.memo(ShowMoreContent);
