import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICES } from 'constants/store';
import axiosInstance from 'utils/axios';

export const downloadCases = createAsyncThunk(
  `${SLICES.CASE}/downloadCases`,
  async ({ allResults }) => {
    const res = await axiosInstance.request({
      method: 'GET',
      params: {
        allResults,
      },
      responseType: 'blob',
      url: '/v1/excel/download',
    });
    return res.data;
  }
);

export const getCaseDetails = createAsyncThunk(
  `${SLICES.CASE}/getCaseDetails`,
  async caseId => {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/v1/caseDetails/${caseId}`,
    });
    return response.data;
  }
);

export const getCaseTeamHRIDs = createAsyncThunk(
  `${SLICES.CASE}/getCaseTeamHRIDs`,
  async caseCode => {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/v1/snowflake/hrid/${caseCode}`,
    });
    return response.data;
  }
);

export const getKnowledgeNavigatorCaseGUID = createAsyncThunk(
  `${SLICES.CASE}/getKnowledgeNavigatorCaseGUID`,
  async caseCode => {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/v1/snowflake/knGuid/${caseCode}`,
    });

    return response.data;
  }
);

export const updateCaseDetails = createAsyncThunk(
  `${SLICES.CASE}/updateCaseDetails`,
  async ({ caseId, data }) => {
    const response = await axiosInstance.request({
      data,
      method: 'PUT',
      url: `/v1/caseDetails/update/case/${caseId}`,
    });

    return response.data;
  }
);
