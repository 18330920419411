/**
 *
 * @param   {string} query  - query string from the url
 * @returns {object}        - object containing all the key value pairs from query string
 */
const parse = query => {
  const urlParams = new URLSearchParams(query);
  let queryObject = {};
  for (const [key, value] of urlParams.entries()) {
    queryObject = {
      ...queryObject,
      [key]: value,
    };
  }

  return queryObject;
};

/**
 *
 * @param   {string} queryParams  - object containing all the key value pairs
 * @returns {object}              - string that can be appended to url as query string
 */
const stringify = queryParams => {
  const urlParams = new URLSearchParams();
  for (const [key, value] of Object.entries(queryParams)) {
    urlParams.append(key, value);
  }
  return urlParams.toString();
};

/**
 * @example
 * import queryString from 'utils/queryString';
 * const searchParams  = queryString.parse(history.location.search);
 *
 * const searchString = queryString.stringify(searchParams);
 */
const queryString = { parse, stringify };

export default queryString;
