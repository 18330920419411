import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image, Button, Icon } from 'semantic-ui-react';

import { AddNewUser, DownloadButton } from 'components/Button';
import DataTable from 'components/DataTable';
import FormDropdown from 'components/FormDropdown';
import Save from 'components/Icons/Save';
import { USER_ROLES } from 'constants/user';
import ui from 'store/ui';
import {
  downloadUsers,
  getUsers,
  removeUser,
  updateUserRole,
} from 'store/user/asyncActions';
import { downloadExcelFile } from 'utils/browser';
import { isEmpty } from 'utils/common';
import { checkAccess } from 'utils/hoc';
import { getSortData } from 'utils/DataTable';
import { toastError, toastSuccess } from 'utils/toast';
import { getUserRoleOptions } from 'utils/user';
import AddNewUserModal from './AddNewUserModal';
import './UserManagement.scss';
import { Page_Views, trackPageView } from 'analytics';
import searchIcon from 'assets/images/search.svg';

const columns = [
  {
    headerName: 'Username',
    field: 'userName',
    sortable: true,
    wordHighlighter: true,
  },
  {
    headerName: 'Email Id',
    field: 'emailId',
    sortable: true,
    wordHighlighter: true,
  },
  {
    headerName: 'Added On',
    field: 'createdAt',
    sortable: true,
    wordHighlighter: true,
  },
  {
    headerName: 'Added By',
    field: 'createdBy',
    sortable: true,
    wordHighlighter: true,
  },
  {
    headerName: 'Current User Role',
    field: 'roleType',
    sortable: true,
    wordHighlighter: true,
    className: 'current-user-role',
  },
];

function UserManagement() {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [changedUserRoles, setChangedUserRoles] = useState({});
  const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [sortBy, setSortBy] = useState('userName');
  const [sortDirection, setSortDirection] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    trackPageView(Page_Views.USER_MANAGEMENT);
  }, []);

  const tableColumns = columns.map(column => {
    if (column.field === 'roleType') {
      return {
        ...column,
        cellRender: data => {
          const selectedOption =
            changedUserRoles[data.emailId] || data.roleType;

          return (
            <FormDropdown
              name="userRole"
              options={getUserRoleOptions()}
              onChange={onChangeUserRole(data.emailId)}
              selectedOption={selectedOption}
            />
          );
        },
      };
    }

    return column;
  });

  const getUsersData = useCallback(async () => {
    try {
      dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
      const response = await dispatch(
        getUsers({
          pageNumber: currentPage,
          limit: pageLimit,
          sortBy: sortBy,
          sortDirection: getSortData(sortDirection, true),
          searchQuery: searchQuery,
        })
      );

      if (unwrapResult(response)?.data?.recordsList) {
        const responseData = unwrapResult(response).data;
        const fetchedUsers = responseData.recordsList;
        const totalElements = responseData.totalElements;
        setUsers(fetchedUsers);
        setTotalRecords(totalElements);
      }
    } catch (e) {
      toastError('Failed to fetch list of users');
      setUsers([]);
      setTotalRecords(0);
    } finally {
      dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
    }
  }, [currentPage, dispatch, pageLimit, sortBy, sortDirection, searchQuery]);

  const handlePageChange = ({ page, limit }) => {
    setCurrentPage(page);
    setPageLimit(limit);
  };

  const handleSortChange = sortData => {
    setSortBy(sortData.sortBy);
    setSortDirection(sortData.sortDirection);
  };

  const onChangeUserRole = username => (_e, updatedRole) => {
    setChangedUserRoles({
      ...changedUserRoles,
      [username]: updatedRole,
    });
  };

  const onCloseAddNewUser = () => {
    setOpenAddNewUserModal(false);
  };

  const onClickAddUser = () => {
    setOpenAddNewUserModal(true);
  };

  const onClickDownloadUsers = () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(downloadUsers())
      .unwrap()
      .then(response => {
        downloadExcelFile(response, {
          contentTypeHeader: 'application/vnd.ms-excel',
          fileName: 'PIPEpack_users.xlsx',
        });
        toastSuccess('File downloaded successfully');
      })
      .catch(() => {
        toastError('Failed to download');
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const onClickSave = () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    Promise.all([
      ...Object.entries(changedUserRoles).map(([email, role]) =>
        updateUserRoleOnSave(email, role)
      ),
    ])
      .then(response => {
        // Unwrapping each reponse to check for error
        response.forEach(responseNode => unwrapResult(responseNode));
        toastSuccess('Role updated successfully');
        setChangedUserRoles({});
        getUsersData();
      })
      .catch(() => {
        toastError('Error occurred in updating the user role');
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const onSuccessfullyAddNewUser = () => {
    setOpenAddNewUserModal(false);
    getUsersData();
  };

  const updateUserRoleOnSave = (email, role) => {
    if (role === USER_ROLES.REMOVE_ACCESS.NAME) {
      return dispatch(removeUser({ email: encodeURIComponent(email) }));
    }
    return dispatch(updateUserRole({ email, role }));
  };

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  const handleEnterKeyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setSearchQuery(e.target.value);
    }
  };

  return (
    <div className="user-management">
      {openAddNewUserModal && (
        <AddNewUserModal
          open={openAddNewUserModal}
          onAddNewUser={onSuccessfullyAddNewUser}
          onClose={onCloseAddNewUser}
        />
      )}
      <div className="user-management__header">
        <div className="user-management__header__title">User Management</div>
        <div
          className="application-header__header-ui__main__text"
          style={{
            width: '40%',
            height: 'inherit',
            border: '2px solid #d7d7d7',
          }}
        >
          <Image
            src={searchIcon}
            className="application-header__header-ui__main__text__search-img"
          />
          <div className="search-container">
            <textarea
              rows={1}
              placeholder="Search for Users"
              onKeyDown={handleEnterKeyPress}
            />
          </div>
        </div>
        <div className="user-management__header__actions">
          <Button
            className="user-management__header__actions__action button save"
            disabled={isEmpty(changedUserRoles)}
            onClick={onClickSave}
          >
            <Icon>
              <Save fill="#062A11" />
            </Icon>{' '}
            Save
          </Button>
          <AddNewUser
            className="user-management__header__actions__action"
            content="Add New User"
            onClick={onClickAddUser}
          />
          <DownloadButton
            className="user-management__header__actions__action download-result"
            content="Download Users"
            onClick={onClickDownloadUsers}
          />
        </div>
      </div>
      <div className="user-management__main">
        <DataTable
          paginated
          sortable
          fixed
          id="users-table"
          columns={tableColumns}
          dataSource={users}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          paginationData={{
            firstPage: currentPage === 1,
            lastPage: currentPage === Math.ceil(totalRecords / pageLimit),
            page: currentPage,
            limit: pageLimit,
          }}
          sortData={{
            sortBy,
            sortDirection,
          }}
          totalRows={totalRecords}
        />
      </div>
    </div>
  );
}

export default checkAccess(UserManagement);
