export const PATHNAME = {
  ABOUT: '/about',
  CASE_DETAILS: '/case/:caseId/details',
  LANDING: '/',
  HOME: '/home',
  HOME_REGION: '/home/region',
  HOME_PRACTICE_AREA: '/home/practice-area',
  HOME_TOPIC_AREA: '/home/topic-area',
  HOME_PROPOSAL_VS_CASES: '/home/proposal-vs-cases',
  MY_LISTS: '/my-lists',
  SEARCH: '/search',
  UNDER_PROCESS: '/underprocess',
  USERS: '/users',
  LOGIN_CALLBACK: '/login/callback',
};

export const ROUTES_WITHOUT_SIDE_MENU = [
  PATHNAME.CASE_DETAILS,
  PATHNAME.LANDING,
];

export const INVESTIBLE_THEMES = [
  {
    link: 'https://bcgcloud.sharepoint.com/sites/PAPIandPE/PIPE%20Investable%20Themes%20and%20Primers_sanitized%20and%20s/Forms/AllItems.aspx?e=2%3A9QPTky&at=9',
    text: 'Investable Themes',
    isComingSoon: false,
  },
  {
    link: '',
    text: 'Sector Overviews',
    isComingSoon: true,
  },
];
