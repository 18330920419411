import React from 'react';
import { Image } from 'semantic-ui-react';

import AboutPipePackBackground from 'assets/images/pipe-pack-hero.png';
import { getContactEmail } from 'utils/user';
import './AboutPipePack.scss';
import { Page_Views, trackPageView } from 'analytics';
import { useEffect } from 'react';

const AboutPipePack = () => {
  useEffect(() => {
    trackPageView(Page_Views.ABOUT);
  }, []);
  return (
    <div className="about-page">
      <div className="about-page__hero-container">
        <h4 className="about-page__hero-container__header">About PIPEpack</h4>
        <Image
          className="about-page__hero-container__img"
          src={AboutPipePackBackground}
          fluid
        />
      </div>
      <div className="about-page__content">
        <p className="about-page__content__description">
          The PIPEpack is meant to be a complete view of PIPE transaction case
          experience. The tool offers an easy way to search, view and download
          our best lists and descriptions of relevant PIPE DDs and proposals.
          Use the PIPEpack to strengthen your client pitches and inform your
          casework.
        </p>
        <p className="about-page__content__description">
          The PIPEpack was developed by the PIPE KT to bring you the direct
          access to case details. We upload additional records and each month to
          keep content complete and current. Please note that the case materials
          are filed in a separate repository, and not included directly in the
          tool.
        </p>
        <p className="about-page__content__description">
          If you have any questions or feedback, please contact&nbsp;
          <span>
            <a
              href={`mailto:${getContactEmail()}?subject=Question about PIPEpack`}
            >
              <u>PIPE KT</u>
            </a>
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default AboutPipePack;
