import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DataTable from 'components/DataTable';
import { Alert } from 'components/modal';
import { PATHNAME } from 'constants/router';
import { COLUMNS_CONF } from 'constants/DataTable';
import data_table from 'store/data_table';
import {
  getSort,
  getPagination,
  getSelectedColumns,
} from 'store/data_table/selectors';
import {
  loadCaseDetails,
  downloadSearchResult,
} from 'store/data_table/asyncActions';
import ui from 'store/ui';
import { getFilter, showGlobalLoader } from 'store/ui/selector';
import { DownloadButton, SaveToBucketButton } from 'components/Button';
import EditColumns from 'components/EditColumns';
import { toastSuccess, toastError } from 'utils/toast';
import { downloadExcelFile } from 'utils/browser';
import {
  prepareKeywords,
  redirectToCaseDetails,
  getCurrentItemIndex,
  prepareColumnsForSearchResult,
  prepareSearchQuery,
  getNoRecordsClassName,
  getTotalRowsInSearchResult,
  parseSearchQueryString,
  handleSearchChange,
  getTotalRows,
} from 'utils/DataTable';
import { extractErrorMessage } from 'utils/error';
import { DEFAULT_ERROR } from 'constants/errors';
import { NON_ADMIN_RECORDS_LIMIT } from 'constants/user';
import { isAdminUser } from 'utils/user';
import HeaderRecords from './HeaderRecords';
import './SearchResult.scss';
import {
  Page_Views,
  trackDownloadResults,
  trackPageView,
  trackPagination,
} from 'analytics';

export default function SearchResult() {
  const dispatch = useDispatch();
  const globalLoaderIsActive = useSelector(showGlobalLoader);
  const showFilter = useSelector(getFilter);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloadAlert, setDownloadAlert] = useState({
    open: false,
    message: '',
  });
  const searchRef = useRef();
  const history = useHistory();
  const sortData = useSelector(getSort);
  const paginationData = useSelector(getPagination);
  const selectedColumns = useSelector(getSelectedColumns);
  const [columns, setColumns] = useState(
    prepareColumnsForSearchResult({
      columnConf: COLUMNS_CONF,
      selectedColumns,
    })
  );
  const [searchResults, setSearchResults] = useState({
    totalRows: 0,
    dataSource: [],
    firstPage: true,
    lastPage: true,
    matchedRows: 0,
  });

  const { location } = history;

  const { searchQuery, advanceFilter, searchId } = useMemo(() => {
    return parseSearchQueryString(location.search);
  }, [location.search]);

  const { dataSource, totalRows, firstPage, lastPage, matchedRows } =
    searchResults;
  const { page, limit } = paginationData;
  const { sortDirection, sortBy } = sortData;
  const isAdmin = isAdminUser();

  const disableSaveToBucket = !selectedRows.length || !totalRows;

  const noRecordsClassName = getNoRecordsClassName({
    totalRows,
    loading,
    page: 'search-page',
  });

  const totalRowsInSearch = getTotalRowsInSearchResult({
    searchQuery,
    advanceFilter,
    matchedRows,
    totalRows,
  });

  const resetGridStatus = useCallback(() => {
    dispatch(data_table.actions.SET_PAGINATION({ page: 1, limit }));
    setSelectedRows([]);
  }, [dispatch, limit]);

  useEffect(() => {
    trackPageView(Page_Views.SEARCH);
  }, []);

  useEffect(() => {
    setColumns(
      prepareColumnsForSearchResult({
        columnConf: COLUMNS_CONF,
        selectedColumns,
      })
    );
  }, [isAdmin, selectedColumns]);

  useEffect(() => {
    handleSearchChange({
      searchId,
      searchRef,
      onSearchChange: resetGridStatus,
    });
  }, [dispatch, searchId, resetGridStatus]);

  const getCaseDetails = useCallback(async () => {
    setLoading(true);
    const query = prepareSearchQuery({
      page,
      limit,
      sortDirection,
      sortBy,
      searchQuery,
      advanceFilter,
    });
    await new Promise(resolve =>
      dispatch(loadCaseDetails(query))
        .unwrap()
        .then(caseDetails => {
          if (query.search && query.search.length && !showFilter) {
            dispatch(ui.actions.TOGGLE_FILTER());
          }
          setSearchResults({
            dataSource: prepareKeywords(caseDetails?.data, searchQuery),
            totalRows: getTotalRows({ caseDetails, searchQuery }),
            firstPage: caseDetails?.data?.firstPage,
            lastPage: caseDetails?.data?.lastPage,
            matchedRows: caseDetails?.data?.matchedRow || 0,
          });
        })
        .catch(error => {
          toastError(extractErrorMessage(error, DEFAULT_ERROR.search));
        })
        .finally(() => {
          setLoading(false);
          resolve();
        })
    );
  }, [
    dispatch,
    searchQuery,
    sortBy,
    sortDirection,
    limit,
    page,
    advanceFilter,
  ]);

  const handlePageChange = pagination => {
    trackPagination(pagination.page);
    dispatch(data_table.actions.SET_PAGINATION(pagination));
  };

  const handleSortChange = sortInfo =>
    dispatch(data_table.actions.SET_SORT_DATA(sortInfo));

  const handleColumnChange = selected => {
    const selectedCols = selected.reduce(
      (acc, { value }) => ({ ...acc, [value]: true }),
      {}
    );
    dispatch(data_table.actions.SET_SELECTED_COLUMNS(selectedCols));
  };

  const onClickDownload = () => {
    const query = prepareSearchQuery({
      page: 1,
      matchedRows,
      totalRows,
      sortDirection,
      sortBy,
      searchQuery,
      advanceFilter: advanceFilter,
      rowsToFetch: selectedRows,
    });
    trackDownloadResults(selectedRows);
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(downloadSearchResult(query))
      .unwrap()
      .then(response => {
        downloadExcelFile(response, {
          contentTypeHeader: 'application/vnd.ms-excel',
          fileName: 'my_cases.xlsx',
        });
        toastSuccess('File downloaded successfully');
        unSelectRows();
      })
      .catch(error => {
        toastError(extractErrorMessage(error, DEFAULT_ERROR.download));
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const onRowSelectionChange = selectedRecords => {
    setSelectedRows(
      Object.entries(selectedRecords)
        .filter(([_key, value]) => value)
        .map(([key]) => key)
    );
  };

  const unSelectRows = () => setSelectedRows([]);

  const onRowClick = useCallback(
    (e, rowData) => {
      const currentIndex = getCurrentItemIndex({
        dataSource,
        field: 'id',
        value: rowData.id,
        page,
        limit,
      });
      const query = prepareSearchQuery({
        searchQuery,
        advanceFilter,
      });
      dispatch(
        data_table.actions.SET_PREV_PAGE_INFO({
          from: PATHNAME.search,
          query,
          totalRows: matchedRows || totalRows,
          currentIndex,
        })
      );
      redirectToCaseDetails(e, { caseId: rowData.id, history });
    },
    [
      dispatch,
      history,
      searchQuery,
      dataSource,
      page,
      limit,
      matchedRows,
      totalRows,
      advanceFilter,
    ]
  );

  const noRowSelected = () => {
    if (!selectedRows.length) {
      setDownloadAlert({
        open: true,
        message: 'Please select records from the list',
      });
    } else if (selectedRows.length > 100) {
      setDownloadAlert({
        open: true,
        message: (
          <p>
            You have selected {selectedRows.length} records, To download more
            than 100 records please contact
            <a href="mailto:webmaster@example.com">NAMR PIPE KT</a> team
          </p>
        ),
      });
    } else {
      onClickDownload();
    }
  };

  const handleNonAdminDownload = () => {
    if (!isAdmin) {
      noRowSelected();
    } else {
      onClickDownload();
    }
  };

  const checkDownloadValidation = () => {
    handleNonAdminDownload();
  };

  const onCloseDownloadAlert = isCloseButton => {
    !isCloseButton && selectedRows.length && onClickDownload();
    setDownloadAlert({
      open: false,
      message: '',
    });
  };

  if (globalLoaderIsActive) {
    return null;
  }

  return (
    <div className="search-page">
      <Alert
        open={downloadAlert.open}
        onClose={onCloseDownloadAlert}
        message={downloadAlert.message}
      />
      <div className="search-page__header">
        <HeaderRecords
          advanceFilter={advanceFilter}
          isAdmin={isAdmin}
          loading={loading}
          matchedRows={matchedRows}
          searchQuery={searchQuery}
          totalRows={totalRows}
        />
        <div className="search-page__header__actions">
          <SaveToBucketButton
            className="search-page__header__actions__action save-my-list"
            content="Save to My List"
            disabled={disableSaveToBucket}
            selectedRows={selectedRows}
            onSaveToBucket={unSelectRows}
          />
          <DownloadButton
            className="search-page__header__actions__action download-result"
            content="Download Results"
            disabled={!totalRows}
            onClick={checkDownloadValidation}
          />
          <EditColumns
            className="search-page__header__actions__action"
            columns={columns}
            handleColumnChange={handleColumnChange}
            disabled={!totalRows}
          />
        </div>
      </div>
      <div className={`search-page__main ${noRecordsClassName}`}>
        <DataTable
          key={searchId}
          onRowSelectionChange={onRowSelectionChange}
          onQueryChange={getCaseDetails}
          id="ppc-data-table"
          columns={columns}
          dataSource={dataSource}
          totalRows={
            !isAdmin && totalRowsInSearch > NON_ADMIN_RECORDS_LIMIT
              ? NON_ADMIN_RECORDS_LIMIT
              : totalRowsInSearch
          }
          paginated
          sortable
          showCheckbox
          fixed
          sortData={sortData}
          paginationData={{ ...paginationData, firstPage, lastPage }}
          onRowClick={onRowClick}
          selectedRows={selectedRows.reduce(
            (acc, record) => ({ ...acc, [record]: true }),
            {}
          )}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
        />
      </div>
    </div>
  );
}
