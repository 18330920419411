import { createSelector } from '@reduxjs/toolkit';

const bucket = state => state.bucket;

export const getBucketList = createSelector(bucket, state => {
  return state?.bucketList;
});

export const getBucketRecords = createSelector(bucket, state => {
  return state?.bucketRecords || [];
});

export const getSelectedBuckets = createSelector(bucket, state => {
  return state?.selectedBuckets || {};
});

export const getRefreshBucketList = createSelector(bucket, state => {
  return state.refreshBucketList;
});

export const getShareModal = createSelector(bucket, state => {
  return state.shareBucketModal;
});

export const getCaseDeleteConfirmModal = createSelector(bucket, state => {
  return state?.confirmDeleteModal;
});
