import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';

import edit from 'assets/images/edit.svg';
import digitalContext from 'assets/images/Digital-context.svg';
import { Edit } from 'components/modal';
import EditDigitalContextBlock from 'components/modal/Edit/EditForms/EditDigitalContextBlock';
import ShowMoreContent from 'components/ShowMoreContent';
import { isAdminUser } from 'utils/user';
import './CaseInformation.scss';

function DigitalContextBlock({ digitalTeamsInvolved, digitalModulesUsed }) {
  const [openEditModal, setOpenEditModal] = useState(false);

  const onClickEdit = () => setOpenEditModal(true);

  const setOpenModal = open => setOpenEditModal(open);

  return (
    <>
      <Edit
        Content={EditDigitalContextBlock}
        open={openEditModal}
        setOpenModal={setOpenModal}
      />

      <div className="case-information__section-header">
        <div className="case-information__section-header__project-icon-name">
          <Image src={digitalContext} className="margin-right-5" />
          <div className="case-information__section-header__project-icon-name__project-name">
            Digital Context
          </div>
        </div>
        {isAdminUser() && (
          <div
            className="case-information__section-header__edit"
            onClick={onClickEdit}
          >
            <Image
              src={edit}
              className="case-information__section-header__edit__edit-icon"
            />
            <div className="case-information__section-header__edit__edit-text">
              Edit
            </div>
          </div>
        )}
      </div>
      <div className="case-information__project-name-container">
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Digital Teams
          </div>
          <div className="case-information__project-name-container__lower-text">
            <ShowMoreContent
              anchorClass="case-information__project-name-container__lower-text__more-less"
              showMoreText="Read More"
              showLessText="Read Less"
              lines={2}
            >
              {digitalTeamsInvolved}
            </ShowMoreContent>
          </div>
        </div>
        <div className="case-information__project-name-container__vertical-line"></div>
        <div className="case-information__project-name-container__block">
          <div className="case-information__project-name-container__upper-text">
            Digital Modules
          </div>
          <div className="case-information__project-name-container__lower-text">
            <ShowMoreContent
              anchorClass="case-information__project-name-container__lower-text__more-less"
              showMoreText="Read More"
              showLessText="Read Less"
              lines={2}
            >
              {digitalModulesUsed}
            </ShowMoreContent>
          </div>
        </div>
      </div>
    </>
  );
}

DigitalContextBlock.propTypes = {
  digitalTeamsInvolved: PropTypes.string,
  digitalModulesUsed: PropTypes.string,
};

DigitalContextBlock.defaultProps = {
  digitalTeamsInvolved: '',
  digitalModulesUsed: '',
};

export default DigitalContextBlock;
