import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const MultiValue = props => {
  const {
    selectProps: {
      allOption: { value: allOptionValue },
    },
    data: { label, value },
  } = props;
  let labelToBeDisplayed = `${label}`;

  if (value === allOptionValue) {
    labelToBeDisplayed = 'All is selected';
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export default MultiValue;

MultiValue.propTypes = {
  selectProps: PropTypes.shape({
    allOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

MultiValue.defaultProps = {
  selectProps: {
    allOption: {
      label: 'Select all',
      value: '*',
    },
  },
};
