import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { INVESTIBLE_THEMES } from 'constants/router';
import './InvestibleThemesDropdown.scss';

const InvestibleThemesDropdown = ({ open, closeDropdown }, ref) => {
  return (
    <div
      className="investible-dropdown__wrapper"
      ref={ref}
      style={{ display: open ? 'block' : 'none' }}
      onMouseLeave={closeDropdown}
    >
      <Dropdown
        trigger={<></>}
        pointing="left"
        floating
        compact
        className="investible-dropdown"
        icon={null}
        closeOnChange={false}
        open={open}
      >
        <Dropdown.Menu>
          {INVESTIBLE_THEMES.map(({ link, text, isComingSoon }, index) => {
            return (
              <Dropdown.Item
                as="a"
                href={link}
                target="_blank"
                key={`${text}-${index}`}
                disabled={isComingSoon}
              >
                {text}
                {isComingSoon && <span className="caption">(Coming Soon)</span>}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default React.forwardRef(InvestibleThemesDropdown);

InvestibleThemesDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};
