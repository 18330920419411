import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import downArrow from 'assets/images/icons/down-arrow.svg';

const DownloadButton = ({ content, className, disabled, onClick }) => {
  return (
    <Button
      className={`green button--download ${className}`}
      disabled={disabled}
      icon={
        <i className="icon">
          <img src={downArrow} alt={content} />
        </i>
      }
      content={content}
      onClick={onClick}
    />
  );
};

DownloadButton.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DownloadButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => null,
};

export { DownloadButton };
