import { createSlice } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';

import reducers from './reducers';
import { ASCENDING } from 'constants/DataTable';

export default createSlice({
  name: SLICES.DATA_TABLE,
  initialState: {
    pagination: {
      limit: 25,
      page: 1,
    },
    sortData: {
      sortBy: 'caseCode',
      sortDirection: ASCENDING.int,
    },
    prevPage: {
      from: null,
      query: null,
      currentIndex: null,
      totalRows: null,
    },
    selectedColumns: {},
  },
  reducers,
  extraReducers: {},
});
