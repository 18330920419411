import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getRecordLimitationNote,
  showMatchedRowStats,
  showTotalRecords,
} from 'utils/DataTable';

function HeaderRecords({
  advanceFilter,
  isAdmin,
  loading,
  matchedRows,
  searchQuery,
  totalRows,
}) {
  const showMatchedRows = showMatchedRowStats({
    loading,
    searchQuery,
    advanceFilter,
    matchedRows,
  });

  const showTotal = showTotalRecords({
    loading,
    searchQuery,
    advanceFilter,
    matchedRows,
    totalRows,
  });

  const recordsLimitNote = getRecordLimitationNote({
    isAdmin,
    loading,
    matchedRows,
  });

  return (
    <div className="search-page__header__records">
      <div>
        {showMatchedRows && (
          <span className="search-page__header__records__searched">
            {matchedRows} Matching {pluralize('Result', matchedRows)} Found
          </span>
        )}
        {showTotal && (
          <span className="search-page__header__records__total">
            {Boolean(searchQuery || advanceFilter) && ' - '}
            <span style={{ fontWeight: 700 }}>{totalRows}</span> Total&nbsp;
            {pluralize('Record', totalRows)}
          </span>
        )}
      </div>
      {recordsLimitNote}
    </div>
  );
}

HeaderRecords.propTypes = {
  advanceFilter: PropTypes.object,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  matchedRows: PropTypes.number,
  searchQuery: PropTypes.array,
  totalRows: PropTypes.number,
};

HeaderRecords.defaultProps = {
  advanceFilter: null,
  isAdmin: null,
  loading: null,
  matchedRows: null,
  searchQuery: null,
  totalRows: null,
};

export default HeaderRecords;
