import React from 'react';
import PropTypes from 'prop-types';

function EditPencil({ fill, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 12.2496H2.8105L9.796 5.26407L8.7355 4.20357L1.75 11.1891V12.2496ZM13.75 13.7496H0.25V10.5673L10.3263 0.491074C10.4669 0.350471 10.6576 0.271484 10.8565 0.271484C11.0554 0.271484 11.2461 0.350471 11.3868 0.491074L13.5085 2.61282C13.6491 2.75347 13.7281 2.9442 13.7281 3.14307C13.7281 3.34195 13.6491 3.53268 13.5085 3.67332L4.93225 12.2496H13.75V13.7496ZM9.796 3.14307L10.8565 4.20357L11.917 3.14307L10.8565 2.08257L9.796 3.14307Z"
        fill={fill}
      />
    </svg>
  );
}

EditPencil.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EditPencil.defaultProps = {
  fill: '#03053D',
  height: '14',
  width: '14',
  viewBox: '0 0 14 14',
};

export default EditPencil;
