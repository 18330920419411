import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import PlusCircle from 'components/Icons/PlusCircle';

const AddNewUser = ({ content, className, disabled, onClick }) => {
  return (
    <Button
      className={`green button--add-new-user ${className}`}
      disabled={disabled}
      icon={<PlusCircle />}
      content={content}
      onClick={onClick}
    />
  );
};

AddNewUser.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

AddNewUser.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => null,
};

export { AddNewUser };
