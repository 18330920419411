import React from 'react';
import { Message } from 'semantic-ui-react';

import queryString from 'utils/queryString';
import { stopPropagation } from 'utils/common';
import { getRelevantPlayers } from 'utils/case';
import { isAdminUser } from 'utils/user';
import {
  ASCENDING,
  DESCENDING,
  DUPLICATE_ROW_HIGHLIGHTER,
} from 'constants/DataTable';
import { NON_ADMIN_RECORDS_LIMIT } from 'constants/user';
import { generateCombinations } from './array';
import { escapeRegExp, isEqual } from './common';
import { PATHNAME } from 'constants/router';
import {
  OPERATOR_SUGGESTIONS,
  SEARCH_PAYLOAD_TYPES,
  DEFAULT_FILTER_OPTIONS,
  DEFAULT_SEARCH,
} from 'constants/search';
import data_table from 'store/data_table';
import HighlightWord from 'components/HighlightWord';
import ShowMoreContent from 'components/ShowMoreContent';
import ColumnActions from 'containers/UnderProcess/ColumnActions';
import Error from 'components/Icons/Error';

/**
 *
 * @param   {object} sortData
 * @param   {string} sortData.sortBy        - sorted column name
 * @param   {number} sortData.sortDirection - sort direction
 * @param   {string} column                 - sorting column
 * @returns {(1|-1)}                        - the sort direction in number form
 */
export const getSortDirection = ({ sortBy, sortDirection }, column) => {
  let direction = null;
  if (column === sortBy) {
    if (sortDirection === 1) {
      direction = -1;
    } else {
      direction = 1;
    }
  } else {
    direction = 1;
  }
  return direction;
};

/**
 *
 * @param   {(string|number)} dir    - Sort direction value
 * @param   {Boolean}      shortForm - indicatort to return a short form or long form string
 * @returns {string}                 - string representing the sort direction
 */
export const getSortData = (sortDirection, shortForm = false) => {
  if (sortDirection) {
    if (
      sortDirection === ASCENDING.int ||
      sortDirection.toString().toLowerCase() === ASCENDING.short ||
      sortDirection.toString().toLowerCase() === ASCENDING.long
    ) {
      return shortForm ? ASCENDING.short : ASCENDING.long;
    } else if (
      sortDirection === DESCENDING.int ||
      sortDirection.toString().toLowerCase() === DESCENDING.short ||
      sortDirection.toString().toLowerCase() === DESCENDING.long
    ) {
      return shortForm ? DESCENDING.short : DESCENDING.long;
    }
  }
  return null;
};

/**
 *
 * @param   {number[]} limitRows - array containg the page limit options
 * @returns {object[]}           - array of object with value and text as keys
 */
export const dropdownLimitOptions = limitRows => {
  return limitRows.map(elem => ({
    value: parseInt(elem.toString(), 10),
    text: elem.toString(),
  }));
};

/**
 *
 * @param   {Array}  duplicates   - array of objects representing the duplicate case details
 * @returns {Object}              - object containing the duplicatesCount and formattedData keys
 */
export const formatDataForUnderProcess = (duplicates = []) => {
  let duplicatesCount = 0;
  let formattedData = [];
  if (Array.isArray(duplicates)) {
    formattedData = duplicates.map(caseData => {
      if (caseData.duplicate) {
        duplicatesCount++;
        return { ...caseData, rowHighlighter: DUPLICATE_ROW_HIGHLIGHTER };
      }

      return caseData;
    });
  }
  return { duplicatesCount, formattedData };
};

/**
 *
 * @param   {object}  serahcQuery      - search quesry sent to API
 * @param   {Array}   inflectionalForm - caseDteails with keywords to heighlight
 */
const groupKeyWordsByOperator = (searchQuery, inflectionalForm) => {
  const keywordsByOperator = [];
  let counter = 0;

  //Loop through the search query and group the keywords by operator
  searchQuery.forEach(queryItem => {
    keywordsByOperator[counter] ||= { keywords: [] };
    if (queryItem.type === SEARCH_PAYLOAD_TYPES.TEXT) {
      keywordsByOperator[counter].keywords.push(queryItem.value);
    }
    if (queryItem.type === SEARCH_PAYLOAD_TYPES.OPERATOR) {
      keywordsByOperator[counter].operator ||= queryItem.value;

      if (keywordsByOperator[counter].operator !== queryItem.value) {
        counter++;
        // if current operator is AND , get the last keyword from previous group
        // and form the new group for AND operator
        if (queryItem.value.toUpperCase() === OPERATOR_SUGGESTIONS.AND) {
          const lastWord = keywordsByOperator[counter - 1].keywords.pop();
          keywordsByOperator[counter] = {
            keywords: [lastWord],
            operator: queryItem.value,
          };
        } else {
          keywordsByOperator[counter] = {
            operator: queryItem.value,
            keywords: [],
          };
        }
      }
    }
  });

  keywordsByOperator.forEach(item => {
    item.keywordCombinations = [];
    item.keywords.forEach(word => {
      // get keywords from inflectional forms returned from API
      const keywords = getInflectionalForm(inflectionalForm, word);
      // generate all combinations if there are multiple words in search word
      const keywordCombinations = generateCombinations(
        keywords,
        '[^\\s]*(?:\\s|\\W)+'
      );
      // adding the searched keyword
      item.keywordCombinations.push([
        ...keywordCombinations,
        escapeRegExp(word),
      ]);
    });
  });

  return keywordsByOperator;
};

/**
 *
 * @param   {string}    columnValue        - column value to match the keywords
 * @param   {object[]}  keywordsByOperator - Array of objects which contains the keywords grouped together by operator
 * @returns {Array}                        - Kewords in array
 */
const getKeywords = (columnValue, keywordsByOperator) => {
  let match = false;
  let keywords = [];

  // loop through all the operator and with their keyword combinations
  for (const keywordItem of keywordsByOperator) {
    const { keywordCombinations, operator } = keywordItem;
    let allCombinations = [];
    for (const keywordForms of keywordCombinations) {
      const regExp = new RegExp(
        '\\b(' + keywordForms.join('|') + ')[^\\s]*(\\b|$)',
        'i'
      );
      // check the any keyword exist in the column
      if (regExp.test(columnValue)) {
        match = true;
        allCombinations = [...allCombinations, ...keywordForms];
      } else if (
        operator &&
        operator.toUpperCase() === OPERATOR_SUGGESTIONS.AND
      ) {
        // in case of AND operator, if any keywords set not match, set the flag to false
        match = false;
        break;
      }
    }
    // if match found set the keywords
    if (match) {
      keywords = [...keywords, ...allCombinations];
    }
  }

  return keywords;
};

/**
 * Prepare updated records list
 *
 * @param {Array} recordsList
 * @param {Array} keywordsByOperator
 * @returns
 */
export const getRecordsListForPreparedKeywords = (
  recordsList,
  keywordsByOperator
) => {
  // loop through all the records and columns and look for the keywords exist
  // if exist set the keywords by column name
  recordsList.forEach(record => {
    const caseColumns = Object.keys(record);
    for (const field of caseColumns) {
      if (!record[field]) {
        continue;
      }
      const keywords = getKeywords(record[field], keywordsByOperator);

      if (keywords.length) {
        record.keywords ||= {};
        record.keywords[field] = keywords;
        // for non admins target name and relevant players are combined together
        if (!isAdminUser() && field === 'targetName') {
          record.keywords.relevantPlayers = record.keywords?.relevantPlayers
            ? [...record.keywords.relevantPlayers, ...keywords]
            : keywords;
        }
      }
    }
  });

  return recordsList;
};

/**
 *
 * @param   {Object}  caseDetails  - caseDetails object returned from API
 * @param   {Object}  searchQuery  - search query sent to API
 * @returns {Array}                - caseDteails with keywords to heighlight
 */
export const prepareKeywords = (caseDetails, searchQuery) => {
  const { inflectionalForm = [] } = caseDetails;
  const { recordsList = [] } = caseDetails;

  if (!(recordsList.length && inflectionalForm.length)) return recordsList;

  const keywordsByOperator = groupKeyWordsByOperator(
    searchQuery,
    inflectionalForm
  );

  return getRecordsListForPreparedKeywords(recordsList, keywordsByOperator);
};

/**
 *
 * @param   {Object}       inflectionalForm  - Object containg the inflectional Forms for the search words
 * @param   {string}       searchWord        - serach string
 * @returns {Array[Array]}                   - Array of Array containing the all keywords for each keyword in search string
 */
const getInflectionalForm = (inflectionalForm, searchWord) => {
  return inflectionalForm.reduce((allKeyWords, forms) => {
    const words = searchWord
      .split(' ')
      .filter(form => forms[form] && forms[form].length)
      .map(form => forms[form].map(word => escapeRegExp(word)));
    return [...allKeyWords, ...words];
  }, []);
};

/**
 * Returns cell render for cellRender case
 *
 * @param   {Object} param
 * @returns {any}
 */
const returnForCellRender = ({ col, data, hasKeywords }) => {
  const columnContent = col.cellRender(data);
  if (hasKeywords) {
    return col.hidden ? null : (
      <HighlightWord
        searchWords={data['keywords'][col.field]}
        content={columnContent}
        truncateText={col.truncateText}
        highlightClassName="highlight-text"
      />
    );
  }

  return col.truncateText
    ? returnForTruncateText({ content: columnContent })
    : columnContent;
};

/**
 * Returns cell render for truncateText case
 *
 * @param   {Object} param
 * @returns {any}
 */
const returnForTruncateText = ({ content }) => {
  return (
    <ShowMoreContent anchorClass="text-more-less">{content}</ShowMoreContent>
  );
};

/**
 * Returns cell render for wordHighlighter case
 *
 * @param   {Object} param
 * @returns {any}
 */
const returnForWordHighlighter = ({ col, content, data }) => {
  return col.hidden ? null : (
    <HighlightWord
      searchWords={data['keywords'][col.field]}
      content={content}
      truncateText={col.truncateText}
      highlightClassName="highlight-text"
    />
  );
};

/**
 * Returns table cell content
 *
 * @param   {Object} param
 * @returns {any}
 */
export const getTableCellContent = ({ data, col, content }) => {
  const hasKeywords =
    data['keywords'] &&
    data['keywords'][col.field] &&
    data['keywords'][col.field].length;

  if (col.cellRender) {
    return returnForCellRender({ col, data, hasKeywords });
  }

  if (!content) return;

  if (hasKeywords && col.wordHighlighter) {
    return returnForWordHighlighter({ col, content, data });
  }

  if (col.truncateText) {
    return returnForTruncateText({ content });
  }

  return content;
};

/**
 *
 * @param {object}  event                  - event object from the triggered event
 * @param {object}  data
 * @param {string}  data.caseId            - case Id to redirect to case details page
 * @param {object}  data.history           - history object from react-router-dom
 * @param {string}  data.showMoreClassName - showMoreContent option className
 * @param {Boolean} data.replace           - replace the location or add new entry
 */
export const redirectToCaseDetails = (
  event,
  { caseId, history, showMoreClassName = 'text-more-less', replace = false }
) => {
  // check if show more option is clicked
  if (event?.target?.className !== showMoreClassName) {
    let pathName = PATHNAME.CASE_DETAILS;
    pathName = pathName.replace(':caseId', caseId);
    replace ? history.replace(pathName) : history.push(pathName);
  }
};

/**
 *
 * @param   {Object} data
 * @param   {Array[Object]} data.dataSource - Array of objects
 * @param   {string}        data.field      - field name in the dataSource object
 * @param   {string|number} data.value      - value to match with the field to get the index
 * @param   {number}        data.page       - current page number for which dataSource is generated
 * @param   {number}        data.limit      - current limit set for the page
 * @returns {number}                        - returns current index of the value in dataSource given
 */
export const getCurrentItemIndex = ({
  dataSource,
  field,
  value,
  page,
  limit,
}) => {
  const currentIndex = dataSource.findIndex(row => row[field] === value);
  return currentIndex + 1 + (page - 1) * limit;
};

/**
 *
 * @param {Object}   queryParam
 * @param {number}   queryParam.page            - Page number
 * @param {number}   queryParam.limit           - Rows per page
 * @param {1|-1}     queryParam.sortDirection   - Sort direction 1=asc, -1=desc
 * @param {string}   queryParam.sortBy          - sort by column name
 * @param {object}   queryParam.searchQuery     - search keywords object
 * @param {object}   queryParam.advancedFilter  - advanced filter option object
 * @param {array}    queryParam.rowsToFetch     - Array of uid of rows to be fetched
 * @param {number}   queryParam.matchedRows     - matched row count
 * @param {number}   queryParam.totalRows       - total rows count
 * @returns {object}
 */
export const prepareSearchQuery = ({
  page,
  limit,
  sortDirection,
  sortBy,
  searchQuery,
  advanceFilter,
  rowsToFetch,
  matchedRows,
  totalRows,
}) => {
  const pageLimit = limit || matchedRows || totalRows;

  const query = {
    pageNumber: page,
    limit: pageLimit,
    sortDirection: getSortData(sortDirection, true),
    sortBy: sortBy,
    search: searchQuery || DEFAULT_SEARCH,
    advanceFilter: advanceFilter || DEFAULT_FILTER_OPTIONS,
    rowsToFetch: rowsToFetch?.length ? rowsToFetch.join(',') : null,
  };

  Object.keys(query).forEach(key => {
    if (query[key] === null || query[key] === undefined) {
      delete query[key];
    }
  });

  return query;
};

/**
 *
 * @param   {Object} data  - column data from search result
 * @returns                - JSX for the KnUrl column display
 */
const renderKnUrl = data => {
  return (
    <a
      href={data['knURL']}
      target="_blank"
      onClick={stopPropagation}
      rel="noreferrer"
    >
      {data['knURL']}
    </a>
  );
};

/**
 *
 * @param   {Object} data - Column data from search result
 * @returns               - JSX for the case code column
 */
const renderCaseCode = data => {
  return <span className="case-code">{data['caseCode']}</span>;
};

/**
 *
 * @param   {Object} data - Column data from search result
 * @returns               - JSX for the relevant players column
 */
const renderRelevantPlayers = data => {
  const { relevantPlayers, targetName } = data;
  return (
    <span>
      {getRelevantPlayers({
        relevantPlayers,
        targetName,
        isAdminUser: isAdminUser(),
      })}
    </span>
  );
};

/**
 * Return column configuration for 'My Lists' table
 *
 * @param   {Object}   columnsParam
 * @param   {Object[]} columnsParam.columnsConf        - array of objects with column config
 * @param   {Object}    columnsParam.selectedColumns   - object of selected column names
 * @returns {Array}
 */
export const prepareColumnsForSearchResult = ({
  columnConf,
  selectedColumns,
}) => {
  const columnRenderer = {
    caseCode: renderCaseCode,
    knURL: renderKnUrl,
    relevantPlayers: renderRelevantPlayers,
  };

  const isAdmin = isAdminUser();
  return columnConf
    .filter(col => (!isAdmin && col.field === 'targetName' ? false : true))
    .map(col => ({
      ...col,
      cellRender: columnRenderer[col.field] ? columnRenderer[col.field] : null,
      hidden: col.isFixed ? false : !selectedColumns[col.field],
    }));
};

/**
 *
 * @param   {Object}  paramsObject
 * @params  {Object}  paramsObject.caseDetails - response from search API
 * @params  {Object}  paramsObject.searchQuery - search keywords object or null
 * @returns {number}
 */
export const getTotalRows = ({ caseDetails, searchQuery }) => {
  return !searchQuery || caseDetails?.data?.matchedRow > 0
    ? caseDetails?.data?.totalElements
    : 0;
};

/**
 *
 * @param {Object}    queryParam
 * @param {Boolean}   queryParam.loading         - page loading status
 * @param {object}    queryParam.searchQuery     - search keywords object
 * @param {object}    queryParam.advancedFilter  - advanced filter option object
 * @param {number}    queryParam.matchedRows     - matched row count
 * @returns {Boolean}
 */
export const showMatchedRowStats = ({
  loading,
  searchQuery,
  advanceFilter,
  matchedRows,
}) => {
  return !loading && (searchQuery || advanceFilter) && Boolean(matchedRows);
};

/**
 *
 * @param {Object}    queryParam
 * @param {Boolean}   queryParam.loading         - page loading status
 * @param {object}    queryParam.searchQuery     - search keywords object
 * @param {object}    queryParam.advancedFilter  - advanced filter option object
 * @param {number}    queryParam.matchedRows     - matched row count
 * @param {number}    queryParam.totalRows       - total rows count
 * @returns {Boolean}
 */
export const showTotalRecords = ({
  loading,
  searchQuery,
  advanceFilter,
  matchedRows,
  totalRows,
}) => {
  return (
    !loading &&
    Boolean(totalRows) &&
    ((!searchQuery && !advanceFilter) || Boolean(matchedRows))
  );
};

/**
 *
 * @param {Object}    queryParam
 * @param {Boolean}   queryParam.loading         - page loading status
 * @param {string}    queryParam.page            - page name
 * @param {number}    queryParam.totalRows       - total rows count
 * @returns {Boolean}
 */
export const getNoRecordsClassName = ({ totalRows, loading, page }) => {
  return totalRows || loading ? '' : `${page}__main--no-records`;
};

/**
 *
 * @param {Object}    queryParam
 * @param {object}    queryParam.searchQuery     - search keywords object
 * @param {object}    queryParam.advancedFilter  - advanced filter option object
 * @param {number}    queryParam.matchedRows     - matched row count
 * @param {number}    queryParam.totalRows       - total rows count
 * @returns {Boolean}
 */
export const getTotalRowsInSearchResult = ({
  searchQuery,
  advanceFilter,
  matchedRows,
  totalRows,
}) => {
  return searchQuery || advanceFilter ? matchedRows : totalRows;
};

/**
 *
 * @param   {string} searchQueryString    - query string from url
 * @returns {object}                       - Object of parsed query string params
 */
export const parseSearchQueryString = searchQueryString => {
  const query = queryString.parse(searchQueryString);
  let searchQuery;
  let advanceFilter;
  let searchId;

  try {
    searchQuery = query?.search ? JSON.parse(query.search) : null;
    advanceFilter = query?.advanceFilter
      ? JSON.parse(query.advanceFilter)
      : null;
    searchId = query?.searchId;
  } catch (e) {
    return {};
  }

  return { searchQuery, advanceFilter, searchId };
};

/**
 *
 * @param {Object}    queryParam
 * @param {string}    queryParam.searchId          - searchId from query string
 * @param {object}    queryParam.searchRef         - Ref for the stored searchId
 * @param {number}    queryParam.onSearchChange    - callback function to execute on search changed
 */
export const handleSearchChange = ({ searchId, searchRef, onSearchChange }) => {
  if (searchId !== searchRef.current) {
    searchRef.current = searchId;
    onSearchChange();
  }
};

/**
 *
 * @param {Object[]} selectedColumns - Array of selected columns object
 * @param {String} column            - column name to check
 * @returns
 */
export const isColumnHidden = (selectedColumns, column) => {
  const show = selectedColumns.find(({ value }) => value === column);
  return show || column === 'actions' ? false : true;
};

/**
 *
 * @param   {Object} data  - Column data from the search result
 * @returns                - JSX object for under process screen case code
 */
const renderUnderProcessCaseCode = data => {
  return (
    <span className="case-code">
      {data['caseCode']}
      {data.rowHighlighter === 'negative' && (
        <span className="margin-left-5">
          <Error />
        </span>
      )}
    </span>
  );
};

/**
 * Return column configuration for 'Under Process' table
 *
 * @param   {Object} param
 * @returns {Array}
 */
export const prepareColumnsForUnderProcessTable = ({
  columnConf,
  handleRemove,
  handleUpload,
}) => {
  const customColumnConf = conf => ({
    caseCode: {
      ...conf,
      style: { width: '100px' },
      sortable: false,
      cellRender: renderUnderProcessCaseCode,
    },
    knUrl: {
      ...conf,
      sortable: false,
      cellRender: renderKnUrl,
    },
    relevantPlayers: {
      ...conf,
      sortable: false,
      cellRender: renderRelevantPlayers,
    },
  });

  const columnsList = columnConf
    .filter(col =>
      !isAdminUser() && col.field === 'targetName' ? false : true
    )
    .map(col => {
      const customConf = customColumnConf(col)[col.field];
      return customConf ? customConf : { ...col, sortable: false };
    });

  return [
    ...columnsList,
    {
      headerName: '',
      field: 'actions',
      style: {
        width: '80px',
      },
      cellRender: props => (
        <ColumnActions
          {...props}
          handleRemove={handleRemove}
          handleUpload={handleUpload}
        />
      ),
    },
  ];
};

/**
 *
 * @param {Object} param
 * @param {Object} param.dispatch - store action dispatch object
 * @param {Boolean|Object} param.searchQuery - searchQuery exist status or object
 */
export const setSortByRank = ({ dispatch, searchQuery }) => {
  if (!searchQuery) return null;

  dispatch(
    data_table.actions.SET_SORT_DATA({
      sortBy: 'rank',
      sortDirection: ASCENDING.int,
    })
  );
};

/**
 *
 * @param {Object} param
 * @returns
 */
export const getRecordLimitationNote = ({ isAdmin, loading, matchedRows }) => {
  if (isAdmin || loading || matchedRows <= NON_ADMIN_RECORDS_LIMIT) {
    return null;
  }

  return (
    <Message
      as="span"
      size="tiny"
      className="search-page__header__records__message"
      negative
    >
      You can see most recent {NON_ADMIN_RECORDS_LIMIT} records only
    </Message>
  );
};

/**
 *
 * @param   {Boolean} loading     - is Apllication loading
 * @param   {Boolean} isAdmin     - is admin user
 * @param   {Object}  searchQuery - search query object
 * @returns {Boolean}
 */
export const showSearchResult = (loading, isAdmin, searchQuery) => {
  return loading ||
    isAdmin ||
    (searchQuery && !isEqual(searchQuery, DEFAULT_SEARCH))
    ? true
    : false;
};
