// home for some common utility functions

import _cloneDeep from 'clone-deep';

/**
 * Deep clone the given object or array
 *
 * @param {Object|Array}  obj
 * @returns
 */
export const cloneDeep = obj => _cloneDeep(obj);

/**
 * Checks whether the given value is empty
 *
 * @param   {*}       value
 * @returns {Boolean}
 *
 */
export const isEmpty = value => {
  const has = Object.prototype.hasOwnProperty;
  const toString = Object.prototype.toString;

  // Null and Undefined...
  if (value == null) return true;

  const type = typeof value;

  // Booleans...
  if ('boolean' === type) return false;

  // Numbers...
  if ('number' === type) return value === 0;

  // Strings and arrays
  if ('string' === type || Array.isArray(value)) return value.length === 0;

  // Objects...
  if (value.toString === toString) {
    if (value.toString() === '[object Object]') {
      for (const key in value) {
        if (has.call(value, key)) return false;
      }

      return true;
    }
  }

  // Anything else...
  return false;
};

/**
 * Checks whether given two values are same or not
 *
 * @param   {*} value1
 * @param   {*} value2
 * @returns {Boolean}
 */
export const isEqual = (value1, value2) => {
  if (
    value1 === null ||
    value1 === undefined ||
    value2 === null ||
    value2 === undefined
  ) {
    return value1 === value2;
  }
  // after this just checking type of one would be enough
  if (value1.constructor !== value2.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (value1 instanceof Function) {
    return value1 === value2;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (value1 instanceof RegExp) {
    return value1 === value2;
  }
  if (value1 === value2 || value1.valueOf() === value2.valueOf()) {
    return true;
  }
  if (Array.isArray(value1) && value1.length !== value2.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (value1 instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(value1 instanceof Object)) {
    return false;
  }
  if (!(value2 instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(value1);
  return (
    Object.keys(value2).every(function (index) {
      return p.indexOf(index) !== -1;
    }) &&
    p.every(function (index) {
      return isEqual(value1[index], value2[index]);
    })
  );
};

/**
 * Removes duplicates from the given array.
 * Note: Works only with array of numbers or string
 *
 * @param   {Array} array
 * @returns {Array}
 */
export const removeDuplicates = array =>
  array.filter((item, index) => array.indexOf(item) === index);

/**
 * @function sortByKey
 * sort the array of objects by given key
 *
 * @param   {Object[]}    data          - Array of json objects with key value pair
 * @param   {string}      sortBy        - key on which array of objects should be sorted
 * @param   {1|-1}        sortDirection - sort direction 1 for ascending, -1 for descending
 * @returns {Object[]}
 */
export const sortByKey = (sortBy, sortDirection = 1, data = []) => {
  return data.sort((a, b) => {
    if (a[sortBy] > b[sortBy]) return sortDirection;
    if (a[sortBy] < b[sortBy]) return sortDirection * -1;
    return 0;
  });
};

/**
 * @function formatDate
 *
 * Method to format the date string to mm-dd-YYYY format.
 * @param   {string} dateTimeStr - datetime string which can be parsed with Date method
 * @returns {string}             - datetime string converted to mm-dd-YYYY format
 */
export const formatDate = dateTimeStr => {
  if (!dateTimeStr) return null;

  const format = [
    { month: '2-digit' },
    { day: '2-digit' },
    { year: 'numeric' },
  ];
  const date = new Date(dateTimeStr);

  return format
    .map(type => {
      const dateTime = new Intl.DateTimeFormat('en', type);
      return dateTime.format(date);
    })
    .join('-');
};
/**
 *  @function capitalizeFirstLetter
 *
 * @param   {string} content  - string to capitalize first letter
 * @returns {string}          - string with first letter capitalized
 */
export const capitalizeFirstLetter = content => {
  return content.charAt(0).toUpperCase() + content.slice(1);
};

/**
 *
 * @param   {string} content - string to excape the reg exp chars
 * @returns {string}
 */
export const escapeRegExp = content => {
  return content.replace(/[^A-Za-z0-9_]/g, '\\$&');
};

/**
 *
 * @param   {Object} e - Event object
 */
export const stopPropagation = e => e.stopPropagation();
