import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';

import DataTable from 'components/DataTable';
import { COLUMNS_CONF } from 'constants/DataTable';
import {
  getDuplicateCaseDetails,
  removeDuplicate,
  mergeDuplicate,
} from 'store/data_table/asyncActions';
import { showGlobalLoader } from 'store/ui/selector';
import './UnderProcess.scss';
import EditColumns from 'components/EditColumns';
import { toastError, toastSuccess } from 'utils/toast';
import {
  formatDataForUnderProcess,
  prepareColumnsForUnderProcessTable,
  isColumnHidden,
  getNoRecordsClassName,
} from 'utils/DataTable';
import { checkAccess } from 'utils/hoc';
import { Page_Views, trackPageView } from 'analytics';

function UnderProcess() {
  const dispatch = useDispatch();
  const globalLoaderIsActive = useSelector(showGlobalLoader);
  const [duplicateRecords, setDuplicateRecords] = useState({
    dataSource: [],
    totalRows: 0,
    firstPage: true,
    lastPage: true,
  });
  const { dataSource, totalRows, firstPage, lastPage } = duplicateRecords;
  const [totalDuplicates, setTotalDuplicates] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({ page: 1, limit: 25 });
  const { page, limit } = paginationData;

  useEffect(() => {
    trackPageView(Page_Views.UNDER_PROCESS);
  }, []);

  const getUnderProcessData = useCallback(async () => {
    setLoading(true);

    await new Promise(resolve =>
      dispatch(getDuplicateCaseDetails({ pageNumber: page, limit }))
        .unwrap()
        .then(caseDetails => {
          const { formattedData } = formatDataForUnderProcess(
            caseDetails?.data.recordsList
          );
          setTotalDuplicates(caseDetails?.data?.totalDuplicatesCount);
          const duplicates = {
            dataSource: formattedData,
            totalRows: caseDetails?.data?.totalElements || 0,
            firstPage: caseDetails?.data?.firstPage,
            lastPage: caseDetails?.data?.lastPage,
          };

          setDuplicateRecords(duplicates);
        })
        .catch(() => {
          toastError(
            'There was some error occured while fetching the under process records'
          );
        })
        .finally(() => {
          resolve();
          setLoading(false);
        })
    );
  }, [dispatch, page, limit]);

  const handleUpload = (uid, caseCode) => async () => {
    try {
      setLoading(true);
      await dispatch(mergeDuplicate({ uid, caseCode }));

      toastSuccess('Selected case details are updated successfully.');

      setLoading(false);
      getUnderProcessData();
    } catch (error) {
      setLoading(false);
      toastError(
        'There was some error occured while uploading the case details. Please try again.'
      );
    }
  };

  const handleRemove = (uid, caseCode) => async () => {
    try {
      setLoading(true);
      await dispatch(removeDuplicate(uid));

      toastSuccess(
        `Duplicate case details for Case ID ${caseCode} is successfully deleted.`
      );
      setLoading(false);

      // Updating the table
      getUnderProcessData();
    } catch (error) {
      setLoading(false);
      toastError(
        'There was some error occured while deleting the case details. Please try again.'
      );
    }
  };

  const [columns, setColumns] = useState(
    prepareColumnsForUnderProcessTable({
      columnConf: COLUMNS_CONF,
      handleRemove,
      handleUpload,
    })
  );

  const handleColumnChange = selected => {
    const selectedCols = columns.map(col => {
      return {
        ...col,
        hidden: isColumnHidden(selected, col.field),
      };
    });
    setColumns(selectedCols);
  };

  const handlePageChange = pagination => setPaginationData(pagination);

  if (globalLoaderIsActive) {
    return null;
  }

  const noRecordsClassName = getNoRecordsClassName({
    totalRows,
    loading,
    page: 'under-process',
  });

  return (
    <div className="under-process">
      {Boolean(totalRows) && (
        <div className="under-process__header">
          <div>
            <span className="under-process__header__duplicates">
              {totalDuplicates} Duplicate {pluralize('Record', totalDuplicates)}
              &nbsp;Found
            </span>
          </div>
          <div>
            {Boolean(totalRows) && (
              <EditColumns
                columns={columns}
                handleColumnChange={handleColumnChange}
              />
            )}
          </div>
        </div>
      )}
      <div className={`under-process__main ${noRecordsClassName}`}>
        <DataTable
          id="upload-data-table"
          onQueryChange={getUnderProcessData}
          columns={columns}
          totalRows={totalRows}
          dataSource={dataSource}
          showLoader={loading}
          paginationData={{ ...paginationData, firstPage, lastPage }}
          handlePageChange={handlePageChange}
          fixed
          paginated
        />
      </div>
    </div>
  );
}

export default checkAccess(UnderProcess);
