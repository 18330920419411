import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Image, Menu } from 'semantic-ui-react';

import { PATHNAME } from 'constants/router';
import './MenuItem.scss';

function MenuItem({
  href,
  imageSrc,
  onClick,
  title,
  to,
  className,
  trackAnalytics,
}) {
  const menuItemProps = {};

  if (typeof onClick === 'function') {
    menuItemProps.onClick = onClick;
  } else if (href) {
    menuItemProps.href = href;
  } else if (to) {
    menuItemProps.to = to;
    menuItemProps.as = NavLink;
  }

  if (typeof onClick !== 'function' && trackAnalytics) {
    menuItemProps.onClick = trackAnalytics;
  }

  return (
    <Menu.Item className={`menu-item ${className}`} {...menuItemProps}>
      <Image src={imageSrc} className="menu-item__img" />
      <span className="menu-item__text">{title}</span>
    </Menu.Item>
  );
}

MenuItem.propTypes = {
  href: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOf([...Object.values(PATHNAME)]),
  className: PropTypes.string,
  trackAnalytics: PropTypes.func,
};

MenuItem.defaultProps = {
  href: '',
  onClick: null,
  to: null,
  className: '',
  trackAnalytics: null,
};

export default MenuItem;
