import { CONTACT_EMAIL } from 'constants/Common';
import { USER_ROLES } from 'constants/user';
import store from 'store';
import { getRole } from 'store/user/selectors';
import { getCurrentTimeZone } from './browser';

/**
 * Returns the email address to contact the PIPE team
 * as per the current timzone
 *
 * @returns {String}
 */
export const getContactEmail = () => {
  try {
    const timeZone = getCurrentTimeZone();
    const continent = timeZone.split('/')[0];
    return CONTACT_EMAIL[continent.toUpperCase()] || CONTACT_EMAIL.DEFAULT;
  } catch (error) {
    return CONTACT_EMAIL.DEFAULT;
  }
};

/**
 * Returns the options for user role dropdown
 *
 * @returns {Array}
 */
export const getUserRoleOptions = () => {
  return Object.values(USER_ROLES).map(({ DISPLAY_NAME, NAME }) => ({
    text: DISPLAY_NAME,
    value: NAME,
  }));
};

/**
 * Checks whether the given email is valid
 *
 * @param    {String} email
 * @returns  {Boolean}
 */
export const isValidEmail = email => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

/**
 * Checks whether the logged-in user is of ADMIN role
 *
 * @returns {Boolean}
 */
export const isAdminUser = () => {
  const state = store.getState();
  const role = getRole(state);
  return role === USER_ROLES.ADMIN.NAME;
};
