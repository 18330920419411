import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid } from 'semantic-ui-react';
import pluralize from 'pluralize';

import { divideInColumns } from 'utils/cards';
import { PROPOSAL_UNIT, CASE_UNIT } from 'constants/statistics';
import './HomeCardsLayout.scss';
import ShowMoreContent from 'components/ShowMoreContent';
import { generateUniqueSearchId } from 'utils/search';
import { trackViewRecord } from 'analytics';

function HomeCardsLayout({ columns, cards, pluralizeTitle }) {
  const dividedCards = divideInColumns(cards, columns);

  const trackTabClick = title => {
    trackViewRecord(title);
  };

  return (
    <Grid className="home-cards-layout" columns={columns}>
      <Grid.Row>
        {dividedCards.map((columnCards, columnIndex) => (
          <Grid.Column key={`column_${columnIndex}`}>
            {columnCards.map((card, cardIndex) => {
              const unit =
                card.title?.toLowerCase() === PROPOSAL_UNIT
                  ? PROPOSAL_UNIT
                  : CASE_UNIT;
              return (
                <Card key={`card_${cardIndex}`}>
                  <Card.Description>
                    {pluralize(unit, card.total, true)}
                  </Card.Description>
                  <Card.Header title={card.title}>
                    <ShowMoreContent lines={2} showMoreText="">
                      {pluralizeTitle
                        ? pluralize.plural(card.title)
                        : card.title}
                    </ShowMoreContent>
                  </Card.Header>
                  <Card.Meta>
                    {card.addedInLastMonth} new&nbsp;
                    {pluralize(unit, card.addedInLastMonth)} added in last month
                  </Card.Meta>
                  <Link
                    className="view-records"
                    onClick={() => {
                      trackTabClick(card.title);
                    }}
                    to={`${
                      card.viewRecordsPath
                    }&searchId=${generateUniqueSearchId()}`}
                  >
                    View Records
                  </Link>
                </Card>
              );
            })}
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
}

HomeCardsLayout.propTypes = {
  columns: PropTypes.number,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      addedInLastMonth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      title: PropTypes.string.isRequired,
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
  pluralizeTitle: PropTypes.bool,
};

HomeCardsLayout.defaultProps = {
  columns: 5,
  cards: [],
  pluralizeTitle: false,
};

export default HomeCardsLayout;
