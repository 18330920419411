import { createSlice } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import { getMasterData } from './asyncActions';
import reducers from './reducers';

export default createSlice({
  name: SLICES.MASTER,
  initialState: {
    isLoading: false,
  },
  reducers,
  extraReducers: {
    [getMasterData.pending]: state => {
      state.isLoading = true;
    },
    [getMasterData.fulfilled]: (
      state,
      { payload: { data: masterDataObj } }
    ) => {
      Object.entries(masterDataObj).forEach(([key, value]) => {
        state[key] = value;
      });

      state.isLoading = false;
    },
  },
});
