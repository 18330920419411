export const USER_ROLES = {
  ADMIN: {
    NAME: 'ADMIN_USER',
    DISPLAY_NAME: 'Admin User',
  },
  PERMAMENT: {
    NAME: 'PERMANENT_USER',
    DISPLAY_NAME: 'Permanent User',
  },
  TEMPORARY: {
    NAME: 'TEMPORARY_USER',
    DISPLAY_NAME: 'Temporary User',
  },
  REMOVE_ACCESS: {
    NAME: 'REMOVE_ACCESS',
    DISPLAY_NAME: 'Remove Access',
  },
};

export const SUPER_ADMIN_ROLE = 'super_admin';
export const PIPEPACK_ADMIN_GROUP = 'pipepack admin';

export const NON_ADMIN_RECORDS_LIMIT = 500;
