import React from 'react';
import { Image } from 'semantic-ui-react';

import noResultsFoundImage from 'assets/images/no_results_found.png';
import './NoData.scss';

const NoData = () => {
  return (
    <div className="no-data">
      <Image src={noResultsFoundImage} />
    </div>
  );
};

export default NoData;
