import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CASE_TYPES } from 'constants/case';
import {
  getCaseDetails,
  getKnowledgeNavigatorCaseGUID,
} from 'store/case/asyncActions';
import { getMasterData } from 'store/master/asyncActions';
import ui from 'store/ui';
import { cloneDeep } from 'utils/common';
import { CaseDetailsContext } from 'utils/context';
import { extractErrorMessage } from 'utils/error';
import { toastError } from 'utils/toast';
import CaseContact from './CaseContact';
import CaseInformation from './CaseInformation';
import CaseTeam from './CaseTeam';
import './CaseDetailsContent.scss';

function CaseDetailsContent() {
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState({});
  const [knGUID, setKnGUID] = useState(null);

  const fetchCaseInformation = useCallback(() => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    let caseDetails = {};
    dispatch(getCaseDetails(params.caseId))
      .unwrap()
      .then(response => {
        caseDetails = cloneDeep(response.data);
        return dispatch(getKnowledgeNavigatorCaseGUID(caseDetails.caseCode));
      })
      .then(unwrapResult)
      .then(response => {
        setKnGUID(response.data);
      })
      .catch(error => {
        const message = extractErrorMessage(
          error,
          'Error occurred while fetching case details'
        );
        toastError(message);
      })
      .finally(() => {
        setData(caseDetails);
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  }, [dispatch, params.caseId]);

  const onUpdateSuccess = () => {
    fetchCaseInformation();
    dispatch(getMasterData());
  };

  useEffect(() => {
    fetchCaseInformation();
  }, [fetchCaseInformation]);

  return (
    <CaseDetailsContext.Provider
      value={{ caseId: params.caseId, data, onUpdateSuccess }}
    >
      <div className="case-details-content">
        <CaseInformation data={data} knGUID={knGUID} />
        <div className="case-details-content__right">
          <CaseContact
            caseCode={data?.caseCode}
            date={data?.startDate}
            isMaterial={data?.hasMaterials}
          />
          <CaseTeam
            caseCode={data?.caseCode}
            region={data?.region}
            office={data?.officeLocation}
            team={data?.caseTeam}
            hasMaterials={data?.hasMaterials?.toUpperCase() === 'YES'}
            isCaseOrProposal={data?.isCaseOrProposal || CASE_TYPES.PROPOSAL}
          />
        </div>
      </div>
    </CaseDetailsContext.Provider>
  );
}

CaseDetailsContent.propTypes = {};

CaseDetailsContent.defaultProps = {};

export default CaseDetailsContent;
