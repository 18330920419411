import SHA256 from 'crypto-js/sha256';

export const DEFAULT_ANALYTICS_DATA = {
  page: {
    pageInfo: {
      pageName: undefined,
      staffSystemID: undefined,
    },
    category: {
      primaryCategory: undefined,
    },
  },
  internal: {
    search: {
      term: undefined,
    },
  },
  filterCategory: undefined,
  CaseID: undefined,
  PageNumber: undefined,
  linkName: undefined,
  SubLinkName: undefined,
};

export const Page_Views = {
  HOME: 'Home',
  SEARCH: 'Search',
  List: 'List',
  CASE_DETAILS: 'Case Details',
  UNDER_PROCESS: 'Under Process',
  ABOUT: 'About',
  USER_MANAGEMENT: 'User Management',
};

export function setAnalyticsStaffEmailID(email) {
  const staffSystemID = SHA256(email && email.toLowerCase()).toString();
  const {
    digitalData: {
      page: { pageInfo, category },
    },
  } = window;
  pageInfo.staffSystemID = staffSystemID;
  category.primaryCategory = 'Pipepack';
}

function trackEvent(eventName) {
  if (window._satellite) {
    window._satellite.track(eventName);
  }
}

export function trackPageView(pageName) {
  const {
    digitalData: {
      page: { pageInfo },
    },
  } = window;

  pageInfo.pageName = pageName;
  trackEvent('Global_Page_Load');
}

export function trackGlobalSearchAndAdvanceFilter(
  globalSearch,
  searchText,
  filter
) {
  const { digitalData } = window;
  const {
    internal: { search },
  } = digitalData;

  search.term = searchText;
  digitalData.filterCategory = filter;
  if (globalSearch) {
    trackEvent('Internal_Search');
  } else {
    trackEvent('Filter_Selection');
  }
}

export function trackDownloadResults(caseIds) {
  const { digitalData } = window;
  if (
    digitalData?.internal?.search?.term !== undefined ||
    digitalData?.internal?.search?.term === ''
  ) {
    digitalData.linkName = undefined;
    digitalData.SubLinkName = undefined;
  }
  digitalData.CaseID = caseIds;
  digitalData.caseIDList = caseIds.join('|');
  trackEvent('Result_Click_Download');
}

export function trackPagination(pageNumber) {
  const { digitalData } = window;
  digitalData.PageNumber = pageNumber;
  trackEvent('Pagination_Click');
}

export function trackTabClicks(tabName) {
  const { digitalData } = window;
  if (digitalData?.internal?.search?.term !== undefined) {
    digitalData.internal.search.term = undefined;
  }
  digitalData.linkName = tabName;
  trackPageView(tabName);
  trackEvent('Tab_Clicks');
}

export function trackViewRecord(subLinkName) {
  const { digitalData } = window;
  digitalData.SubLinkName = subLinkName;
  trackEvent('View_Records_Click');
}

export function trackTileClick(linkName) {
  const { digitalData } = window;
  digitalData.linkName = linkName;
  trackEvent('Tile_Click');
}

export function trackCreateNewList(caseIds) {
  const { digitalData } = window;
  digitalData.CaseID = caseIds;
  digitalData.caseIDList = caseIds.join('|');
  trackEvent('Create_New_List_Clicks');
}
