import { createSlice } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import reducers from './reducers';

export default createSlice({
  name: SLICES.USER,
  initialState: {
    userInfo: {},
  },
  reducers,
});
