import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Popup, Icon, Button } from 'semantic-ui-react';
import pluralize from 'pluralize';

import Remove from 'components/Icons/Remove';
import deleteIcon from 'assets/images/icons/delete-back-line.svg';
import { DownloadButton } from 'components/Button';
import EditColumns from 'components/EditColumns';
import DataTable from 'components/DataTable';
import { COLUMNS_CONF } from 'constants/DataTable';
import { Alert, ConfirmAlert } from 'components/modal';

import { PATHNAME } from 'constants/router';
import {
  getSort,
  getPagination,
  getSelectedColumns,
} from 'store/data_table/selectors';
import data_table from 'store/data_table';
import {
  deleteBucketRecords,
  downloadBucketRecords,
  getBucketRecords,
} from 'store/bucket/asyncActions';
import bucket from 'store/bucket';
import {
  getBucketList,
  getSelectedBuckets,
  getShareModal,
  getCaseDeleteConfirmModal,
} from 'store/bucket/selectors';
import ui from 'store/ui';
import { showGlobalLoader } from 'store/ui/selector';
import { downloadExcelFile } from 'utils/browser';
import { toastError, toastSuccess } from 'utils/toast';
import {
  getSortData,
  redirectToCaseDetails,
  getCurrentItemIndex,
  prepareColumnsForSearchResult,
  getNoRecordsClassName,
} from 'utils/DataTable';
import { isAdminUser } from 'utils/user';
import './MyLists.scss';
import { Page_Views, trackPageView } from 'analytics';
import { ShareButtonWithToolTip } from 'components/Button/ShareButton';
import ShareListModal from './ShareListModal';

const MyLists = () => {
  const dispatch = useDispatch();
  const globalLoaderIsActive = useSelector(showGlobalLoader);
  const selectedColumns = useSelector(getSelectedColumns);
  const showShareModal = useSelector(getShareModal);
  const showCaseDeleteModal = useSelector(getCaseDeleteConfirmModal);

  const history = useHistory();
  const [loading, setLoading] = useState();
  const [bucketResults, setBucketResults] = useState({
    dataSource: [],
    totalRows: 0,
    firstPage: true,
    lastPage: true,
  });
  const sortData = useSelector(getSort);
  const paginationData = useSelector(getPagination);
  const { page, limit } = paginationData;
  const { sortDirection, sortBy } = sortData;

  const [selectedRows, setSelectedRows] = useState([]);
  const buckets = useSelector(getBucketList);
  const selectedBuckets = useSelector(getSelectedBuckets);
  const [downloadAlert, setDownloadAlert] = useState({
    open: false,
    message: '',
  });
  const selectedBucketData = buckets.filter(
    ({ bucketId }) => selectedBuckets[bucketId]
  );

  useEffect(() => {
    trackPageView(Page_Views.List);
  }, []);

  useEffect(() => {
    dispatch(data_table.actions.SET_PAGINATION({ page: 1, limit }));
  }, [dispatch, selectedBuckets, limit]);

  const { dataSource, totalRows, firstPage, lastPage } = bucketResults;

  const [columns, setColumns] = useState(
    prepareColumnsForSearchResult({
      columnConf: COLUMNS_CONF,
      selectedColumns,
    })
  );
  const isAdmin = isAdminUser();

  useEffect(() => {
    setColumns(
      prepareColumnsForSearchResult({
        columnConf: COLUMNS_CONF,
        selectedColumns,
      })
    );
  }, [isAdmin, selectedColumns]);

  const handleColumnChange = selected => {
    const selectedCols = selected.reduce(
      (acc, { value }) => ({ ...acc, [value]: true }),
      {}
    );
    dispatch(data_table.actions.SET_SELECTED_COLUMNS(selectedCols));
  };

  const getCaseDetails = useCallback(async () => {
    const selected = Object.keys(selectedBuckets).filter(
      bucketId => selectedBuckets[bucketId]
    );
    if (!selected || !selected.length) {
      setBucketResults({
        dataSource: [],
        totalRows: 0,
        firstPage: true,
        lastPage: true,
      });
      return;
    }

    setLoading(true);
    const query = {
      pageNumber: page,
      limit,
      sortDirection: getSortData(sortDirection, true),
      sortBy: sortBy,
      bucketIds: selected.join(','),
    };
    await new Promise(resolve =>
      dispatch(getBucketRecords(query))
        .unwrap()
        .then(caseDetails => {
          setBucketResults({
            dataSource: caseDetails?.data?.recordsList || [],
            totalRows: caseDetails?.data?.totalElements,
            firstPage: page === 1,
            lastPage:
              page === Math.ceil(caseDetails?.data?.totalElements / limit),
          });
        })
        .catch(() => {
          toastError(
            'There was some error occured while fetching the case records'
          );
        })
        .finally(() => {
          resolve();
          setLoading(false);
        })
    );
  }, [dispatch, selectedBuckets, sortBy, sortDirection, limit, page]);

  const handlePageChange = pagination =>
    dispatch(data_table.actions.SET_PAGINATION(pagination));

  const handleSortChange = sortInfo =>
    dispatch(data_table.actions.SET_SORT_DATA(sortInfo));

  const onClickDownload = () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(
      downloadBucketRecords({
        bucketIds: Object.keys(selectedBuckets).join(','),
        rowsToFetch: selectedRows,
      })
    )
      .unwrap()
      .then(response => {
        downloadExcelFile(response, {
          contentTypeHeader: 'application/vnd.ms-excel',
          fileName: 'my_cases.xlsx',
        });
        toastSuccess('File downloaded successfully');
        setSelectedRows([]);
      })
      .catch(() => {
        toastError('Failed to download');
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const onClickDeleteRecords = () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(
      deleteBucketRecords({
        bucketIds: Object.keys(selectedBuckets).join(','),
        caseIds: selectedRows,
      })
    )
      .unwrap()
      .then(response => {
        getCaseDetails();
        toastSuccess('Cases deleted successfully');
        setSelectedRows([]);
      })
      .catch(() => {
        toastError('Failed To delete cases');
      })
      .finally(() => {
        toggleDeleteCaseModal();
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const onRowSelectionChange = selectedRecords => {
    setSelectedRows(
      Object.entries(selectedRecords)
        .filter(([_key, value]) => value)
        .map(([key]) => key)
    );
  };

  const onRowClick = useCallback(
    (e, rowData) => {
      const selected = Object.keys(selectedBuckets).filter(
        bucketId => selectedBuckets[bucketId]
      );
      const currentIndex = getCurrentItemIndex({
        dataSource,
        field: 'id',
        value: rowData.id,
        page,
        limit,
      });
      dispatch(
        data_table.actions.SET_PREV_PAGE_INFO({
          from: PATHNAME.MY_LISTS,
          query: { bucketIds: selected.join(',') },
          totalRows,
          currentIndex,
        })
      );
      redirectToCaseDetails(e, { caseId: rowData.id, history });
    },
    [dispatch, selectedBuckets, dataSource, totalRows, page, limit, history]
  );

  const isIDSame = (bucketId, acc, id) => {
    if (bucketId.toString() === id.toString()) return acc;
    return { ...acc, [id]: selectedBuckets[id] };
  };
  const unselectBucket = bucketId => () => {
    const filteredList = Object.keys(selectedBuckets).reduce((acc, id) => {
      return isIDSame(bucketId, acc, id);
    }, {});
    dispatch(bucket.actions.SET_SELECTED_BUCKET(filteredList));
  };
  const handleNonAdminDownload = () => {
    if (!selectedRows.length) {
      setDownloadAlert({
        open: true,
        message: 'Please select records from the list',
      });
    } else if (selectedRows.length > 100) {
      setDownloadAlert({
        open: true,
        message: `You have selected ${selectedRows.length} records, To download more than 100 records please contact <a href="mailto:webmaster@example.com">NAMR PIPE KT</a> team`,
      });
    } else {
      onClickDownload();
    }
  };
  const checkDownloadValidation = () => {
    !isAdmin ? handleNonAdminDownload() : onClickDownload();
  };

  const toggleShareModal = () => {
    dispatch(bucket.actions.TOGGLE_SHARE_MODAL());
  };

  const toggleDeleteCaseModal = () => {
    dispatch(bucket.actions.TOGGLE_CONFIRM_MODAL());
  };

  const onCloseDownloadAlert = isCloseButton => {
    !isCloseButton && selectedRows.length && onClickDownload();
    setDownloadAlert({
      open: false,
      message: '',
    });
  };

  if (globalLoaderIsActive) {
    return null;
  }

  const noRecordsClassName = getNoRecordsClassName({
    totalRows,
    loading,
    page: 'mylists-page',
  });

  return (
    <div className="mylists-page">
      <Alert
        open={downloadAlert.open}
        onClose={onCloseDownloadAlert}
        message={downloadAlert.message}
      />
      <ConfirmAlert
        open={showCaseDeleteModal}
        onCancel={toggleDeleteCaseModal}
        onConfirm={onClickDeleteRecords}
        message="Are you sure you want to delete the record(s)?"
        confirmText="Yes"
        cancelText="No"
        title="Delete Records"
        confirmIcon={<Remove fill="#fff" height="18px" width="18px" />}
      />
      {showShareModal ? (
        <ShareListModal
          bucketId={selectedBucketData[0].bucketId}
          onClose={toggleShareModal}
          open={showShareModal}
        />
      ) : null}
      <div className="mylists-page__header">
        <div className="mylists-page__header__buckets">
          {Boolean(selectedBucketData.length) && (
            <span className="mylists-page__header__buckets__names">
              {selectedBucketData.map(({ name, bucketId }) => {
                return (
                  <Label
                    key={bucketId}
                    className="mylists-page__header__buckets__names__label"
                  >
                    {name}{' '}
                    <Popup
                      content="Unselect List"
                      trigger={
                        <Icon onClick={unselectBucket(bucketId)}>
                          <img src={deleteIcon} alt="Unselect" />
                        </Icon>
                      }
                    />
                  </Label>
                );
              })}
            </span>
          )}
          {Boolean(selectedBucketData.length) && Boolean(totalRows) && (
            <span className="mylists-page__header__buckets__total">
              - <span style={{ fontWeight: 700 }}>{totalRows}</span>&nbsp;
              {pluralize('Record', totalRows)} in&nbsp;
              {pluralize('list', selectedBucketData.length)}
            </span>
          )}
        </div>
        <div className="mylists-page__header__actions">
          <Button
            icon={<Remove fill="#fff" height="16px" width="16px" />}
            className={`green button--only-icon`}
            disabled={
              selectedRows.length === 0 ||
              Object.keys(selectedBuckets).length >= 2
            }
            onClick={toggleDeleteCaseModal}
          />
          <ShareButtonWithToolTip
            disabled={selectedBucketData.length !== 1}
            className="mylists-page__header__actions__action download-result"
            label="Share List"
            content="Please note that using share list feature you can only share the complete list and not specific records within a given list"
            onClick={toggleShareModal}
          />
          <DownloadButton
            className="mylists-page__header__actions__action download-result"
            content="Download Results"
            disabled={!totalRows}
            onClick={checkDownloadValidation}
          />
          <EditColumns
            className="mylists-page__header__actions__action"
            columns={columns}
            handleColumnChange={handleColumnChange}
            disabled={!totalRows}
          />
        </div>
      </div>
      <div className={`mylists-page__main ${noRecordsClassName}`}>
        <DataTable
          key={selectedBucketData.map(({ bucketId }) => bucketId).join('-')}
          onRowSelectionChange={onRowSelectionChange}
          onQueryChange={getCaseDetails}
          id="my-lists-table"
          columns={columns}
          dataSource={dataSource}
          totalRows={totalRows}
          paginated
          sortable
          fixed
          showCheckbox
          sortData={sortData}
          paginationData={{ ...paginationData, firstPage, lastPage }}
          onRowClick={onRowClick}
          selectedRows={selectedRows.reduce(
            (acc, record) => ({ ...acc, [record]: true }),
            {}
          )}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
        />
      </div>
    </div>
  );
};

export default MyLists;
