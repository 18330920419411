import { matchPath } from 'react-router-dom';

import { PATHNAME } from 'constants/router';

/**
 *
 * @param   {String|number} bucketId        - id to remove from the list
 * @param   {Object}        selectedBuckets - selected bucket object with bucketId as key
 * @returns {object}                        - filtered list after removing the given bucket Id
 */
export const deleteBucketFromSelectedList = (bucketId, selectedBuckets) => {
  return Object.keys(selectedBuckets).reduce((allSelected, id) => {
    const selectedItem =
      id.toString() !== bucketId.toString() && selectedBuckets[id];
    if (selectedItem) {
      return { ...allSelected, [id]: selectedBuckets[id] };
    }
    return allSelected;
  }, {});
};

/**
 *
 * @param   {String|number} bucketId   - Id to replace the bucket name
 * @param   {String}        bucketName - bucket name to replace for the given id
 * @param   {Object[]}      buckets    - Array of objects containing the bucket details
 * @returns {Object[]}                 - updated bucket list
 */
export const updateBucketName = (bucketId, bucketName, buckets) => {
  return buckets.map(bucketItem => {
    if (bucketItem.bucketId.toString() === bucketId.toString()) {
      return { ...bucketItem, name: bucketName };
    }
    return { ...bucketItem };
  });
};

/**
 * Callback on validation error
 * @callback BucketList-validationErrorCb
 * @param {String} message - The error message
 *
 * @function validateBucketName
 * @param {object}                       inputParam
 * @param {string}                       inputParam.bucketName - bucket name to validate
 * @param {Object[]}                     inputParam.buckets    - bucket list array
 * @param {BucketList-validationErrorCb} inputParam.onError    - on error callback
 * @returns
 */
export const validateBucketName = ({ bucketName, buckets, onError }) => {
  if (!bucketName) {
    onError('Please enter list name.');
    return false;
  }
  if (buckets.find(({ name }) => name === bucketName)) {
    onError('Entered list name already exist.');
    return false;
  }

  return true;
};

/**
 *
 * @param {string} pathname        - current pathname
 * @param {object} history         - react-router-dom history object
 * @param {object} selectedBuckets - selected bucket object with bucketId as key
 */
export const loadMyListRoute = (pathname, history, selectedBuckets) => {
  const isMyListsRoute = matchPath(pathname, {
    path: PATHNAME.MY_LISTS,
    exact: false,
  });

  const hasSelected = Object.keys(selectedBuckets).some(
    bucketId => selectedBuckets[bucketId]
  );
  const showMyList = !isMyListsRoute && hasSelected;
  if (showMyList) {
    history.push(PATHNAME.MY_LISTS);
  }
};
