export const STATISTICS_CATEGORY = {
  REGION: 'REGION',
  PRACTICE_AREA: 'INDUSTRY PRACTICE AREA',
  TOPIC_AREA: 'TOPIC AREA',
  PROPOSAL_VS_CASE: 'PROPOSAL VS CASE',
};

export const CASE_UNIT = 'case';
export const PROPOSAL_UNIT = 'proposal';

export const CATEGORY_TO_FILTER_MAP = {
  [STATISTICS_CATEGORY.REGION]: 'region',
  [STATISTICS_CATEGORY.PRACTICE_AREA]: 'industryPracticeArea',
  [STATISTICS_CATEGORY.TOPIC_AREA]: 'topic',
  [STATISTICS_CATEGORY.PROPOSAL_VS_CASE]: 'selectedRadio',
};
