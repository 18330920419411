export const SLICES = {
  CASE: 'case',
  MASTER: 'master',
  UI: 'ui',
  OKTA_USER: 'okta_user',
  DATA_TABLE: 'data_table',
  STATISTICS: 'statistics',
  USER: 'user',
  BUCKET: 'bucket',
};
