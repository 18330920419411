import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Input,
  Button,
  Message,
  Popup,
  Ref,
} from 'semantic-ui-react';
import pluralize from 'pluralize';

import FolderUpArrow from 'components/Icons/FolderUpArrow';
import cancel from 'assets/images/icons/cancel.svg';
import save from 'assets/images/icons/save.svg';
import { getUserEmail } from 'store/okta_user/selectors';
import { getBucketList } from 'store/bucket/selectors';
import { saveToBucket, createBucket } from 'store/bucket/asyncActions';
import { toastSuccess, toastError } from 'utils/toast';
import { DEFAULT_ERROR } from 'constants/errors';
import { extractErrorMessage } from 'utils/error';
import { trackCreateNewList } from 'analytics';

const SaveToBucketButton = ({
  content,
  className,
  disabled,
  selectedRows,
  onSaveToBucket,
}) => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const buckets = useSelector(getBucketList);
  const dropdownRef = useRef();
  const [showEditor, setShowEditor] = useState(false);
  const [newBucket, setNewBucket] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);

  //stopPropagation is required to stop the dropdown menu closing
  const stopPropagation = e => e.stopPropagation();

  const onCancel = () => {
    setShowEditor(false);
    setErrorMsg('');
    setNewBucket('');
  };

  const hideDropDown = useCallback(e => {
    const { target } = e;
    if (dropdownRef.current.contains(target)) {
      //inside click
      return;
    }
    // outside click
    onCancel();
    setOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', hideDropDown);

    return () => {
      document.removeEventListener('mousedown', hideDropDown);
    };
  }, [hideDropDown]);

  const onCreateBucket = async e => {
    if (!newBucket) {
      setErrorMsg('Please enter list name.');
      return;
    }

    if (buckets.find(({ name }) => name === newBucket)) {
      setErrorMsg('Entered list name already exist.');
      return;
    }

    setloading(true);
    trackCreateNewList(selectedRows);

    await dispatch(
      createBucket({
        casesList: selectedRows,
        bucketName: newBucket,
        userEmail,
      })
    )
      .unwrap()
      .then(() => {
        toastSuccess(
          `Successfully saved ${pluralize(
            'record',
            selectedRows.length,
            true
          )} in the list "${newBucket}".`
        );
        setOpen(false);
        onCancel();
        onSaveToBucket(e);
      })
      .catch(error => {
        const message = extractErrorMessage(error, DEFAULT_ERROR.general);
        toastError(message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const handleBucketSelect = (bucketId, bucketName) => e => {
    dispatch(saveToBucket({ casesList: selectedRows, bucketId }))
      .unwrap()
      .then(() => {
        toastSuccess(
          `Successfully saved ${pluralize(
            'record',
            selectedRows.length,
            true
          )} in the list "${bucketName}".`
        );
        setOpen(false);
        onCancel();
        onSaveToBucket(e);
      })
      .catch(error => {
        const message = extractErrorMessage(error, DEFAULT_ERROR.general);
        toastError(message);
      });
  };

  const onCreateNewClick = () => {
    setShowEditor(true);
  };

  const onBucketNameChange = e => {
    stopPropagation(e);
    const {
      target: { value },
    } = e;
    setNewBucket(value);
    setErrorMsg('');
  };

  //Allow space in input field inside Dropdown
  const onKeyDown = e => {
    if (e.keyCode === 32) {
      stopPropagation(e);
    }
  };

  const openDropdown = () => setOpen(!open);

  return (
    <Ref innerRef={dropdownRef}>
      <Dropdown
        className={`button--save-to-bucket ${className}`}
        disabled={disabled}
        floating
        button
        closeOnChange={false}
        open={open}
        icon={null}
        trigger={
          <Button className="bucket-dropdown-trigger" onClick={openDropdown}>
            {buckets.length ? (
              <>
                {' '}
                <i className="icon">
                  <FolderUpArrow />
                </i>
                {content}
              </>
            ) : (
              <span onClick={onCreateNewClick}>+ Create New List</span>
            )}
          </Button>
        }
      >
        <Dropdown.Menu
          onClick={stopPropagation}
          className="save-to-bucket-outer-menu"
        >
          <Dropdown.Menu scrolling className="save-to-bucket-outer-menu__item">
            {buckets.map(({ name, size, bucketId }) => {
              return (
                <Dropdown.Item
                  key={bucketId}
                  onClick={handleBucketSelect(bucketId, name)}
                >
                  {name}
                  <span className="records-count">
                    (<span>{size}</span> records)
                  </span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
          {Boolean(buckets.length) && <Dropdown.Divider />}
          {showEditor ? (
            <Fragment>
              <Input
                name="new-bucket"
                placeholder="List Name"
                size="mini"
                action
                onKeyDown={onKeyDown}
                focus
              >
                <input
                  maxLength="20"
                  value={newBucket}
                  onChange={onBucketNameChange}
                  autoComplete="off"
                />
                <Popup
                  content="Cancel"
                  trigger={
                    <Button
                      onClick={onCancel}
                      className="btn-cancel"
                      disabled={loading}
                    >
                      <img src={cancel} alt="cancel" />
                    </Button>
                  }
                />
                <Popup
                  content="Save"
                  trigger={
                    <Button
                      onClick={onCreateBucket}
                      className="btn-save"
                      loading={loading}
                    >
                      {!loading && <img src={save} alt="Save" />}
                    </Button>
                  }
                />
              </Input>
              {errorMsg && (
                <Message size="mini" negative>
                  <p>{errorMsg}</p>
                </Message>
              )}
            </Fragment>
          ) : (
            <Dropdown.Header
              content="+ Create New List"
              onClick={onCreateNewClick}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Ref>
  );
};

SaveToBucketButton.propTypes = {
  content: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSaveToBucket: PropTypes.func,
};

SaveToBucketButton.defaultProps = {
  className: '',
  disabled: false,
  onSaveToBucket: () => null,
};

export { SaveToBucketButton };
