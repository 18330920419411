import React from 'react';
import PropTypes from 'prop-types';

function Upload({ fill, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM11 6V13H9V6H4L10 0L16 6H11Z"
        fill={fill}
      />
    </svg>
  );
}

Upload.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Upload.defaultProps = {
  fill: '#FFF',
  height: '18',
  width: '20',
  viewBox: '0 0 20 18',
};

export default Upload;
