import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import './ComboBox.scss';

function getBGColorProperty(isDisabled, isFocused, isSelected, color) {
  if (isDisabled) {
    return undefined;
  } else {
    if (isSelected) return color;
    else {
      if (isFocused) return 'rgba(91, 182, 131, 0.1)';
      else return undefined;
    }
  }
}
function getColorProperty(isDisabled, isSelected, color) {
  if (isDisabled) {
    return '#ccc';
  } else {
    if (isSelected) return 'white';
    else return 'black';
  }
}
function getActiveBGColorProperty(isDisabled, isSelected, color) {
  if (!isDisabled) {
    if (isSelected) return color;
    else return 'rgba(91,182,131,0.3)';
  } else {
    return undefined;
  }
}
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = '#5BB683';
    const bgColor = getBGColorProperty(
      isDisabled,
      isFocused,
      isSelected,
      color
    );
    const ColorProperty = getColorProperty(isDisabled, isSelected, color);
    const activeBGColor = getActiveBGColorProperty(
      isDisabled,
      isSelected,
      color
    );
    return {
      ...styles,
      backgroundColor: bgColor,
      color: ColorProperty,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: activeBGColor,
      },
    };
  },
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'rgba(91,182,131,0.1)',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#5BB683',
    fontStyle: 'italic',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#5BB683',
    ':hover': {
      backgroundColor: '#5BB683',
      color: 'white',
    },
  }),
};
function ComboBoxWithSelect({
  header,
  options,
  selectedValue,
  name,
  onChange,
  placeholder,
}) {
  const comboBoxRef = useRef();
  const menuRef = useRef();
  const [selected, setSelected] = useState([]);

  const handleChange = selectedOptions => {
    setSelected(selectedOptions);
    const selectedVal = selectedOptions.map(option => option.value);
    onChange({ masterDataUpdateValue: null }, selectedVal);
  };
  const handleMenuOpen = () => {
    menuRef.current.style.left = comboBoxRef.current.inputRef.offsetLeft + 'px';
  };

  useEffect(() => {
    const incomingValueArray =
      (selectedValue &&
        (Array.isArray(selectedValue) ? selectedValue : [selectedValue])) ||
      [];

    const availableSelectedValue = options.filter(
      option => incomingValueArray.indexOf(option.value) > -1
    );

    setSelected(availableSelectedValue);
  }, [selectedValue, options]);

  useEffect(() => {
    handleMenuOpen();
  }, [selected]);

  return (
    <>
      <div className="combo-box-container">
        <div className="combo-box-container__title">{header}</div>
        <Select
          cacheOptions
          isMulti
          placeholder={placeholder}
          menuPortalTarget={menuRef.current}
          onChange={handleChange}
          onMenuOpen={handleMenuOpen}
          closeMenuOnSelect={false}
          value={selected}
          styles={colourStyles}
          classNamePrefix="combo-box"
          className="combo-box-container__setwidth"
          ref={comboBoxRef}
          name={name}
          options={options}
        />
        <div ref={menuRef} className="combo-box-menu-container"></div>
      </div>
    </>
  );
}

ComboBoxWithSelect.propTypes = {
  header: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

ComboBoxWithSelect.defaultProps = {
  selectedValue: null,
  onChange: () => null,
  placeholder: 'Select options from the menu',
};

export default ComboBoxWithSelect;
