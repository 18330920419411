export function getSearchString(search) {
  if (!search || search.length === 0) {
    return '';
  }
  const searchStrings = [];
  search.forEach(({ value }) => {
    searchStrings.push(value);
  });
  return searchStrings.join(' ');
}

export function getSearchAdvanceFilterString(filter) {
  let filterString = '';
  Object.keys(filter).forEach(key => {
    const value = filter[key];
    if (value && value.length) {
      let keyStringValues = '';
      if (key === 'industryPracticeArea') {
        const ipaList = [];
        value.forEach(ipa => {
          Object.keys(ipa).forEach(ipaKey => {
            ipaList.push(...ipa[ipaKey]);
          });
        });
        keyStringValues = ipaList.join(',');
      } else {
        keyStringValues = value.join(',');
      }
      filterString += `${key}:${keyStringValues}|`;
    }
  });

  if (filterString && filterString.length) {
    filterString = filterString.slice(0, filterString.length - 1);
  }
  return filterString;
}
