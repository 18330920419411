import { createSelector } from '@reduxjs/toolkit';

const uiState = state => state.ui;

export const getOpen = createSelector(uiState, state => {
  return state.modal.open;
});

export const getFilter = createSelector(uiState, state => {
  return state.filter.open;
});

export const showGlobalLoader = createSelector(uiState, state => {
  return state.globalLoader.active;
});

export const getAlertModal = createSelector(uiState, state => {
  return state.alertModal;
});
