export const TYPE_ALERT = 'alert';
export const TYPE_INFO = 'info';
export const TYPE_ERROR = 'error';
export const TYPE_SUCCESS = 'success';

export const TOAST_TYPE_COLOR = {
  [TYPE_ALERT]: '#065161',
  [TYPE_INFO]: '#065161',
  [TYPE_ERROR]: '#842029',
  [TYPE_SUCCESS]: '#0f5132',
};

export const TOAST_TYPE = {
  [TYPE_ALERT]: 'warning',
  [TYPE_INFO]: TYPE_INFO,
  [TYPE_ERROR]: TYPE_ERROR,
  [TYPE_SUCCESS]: TYPE_SUCCESS,
};
