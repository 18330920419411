/**
 * Utility to download file from blob API response
 *
 * @param {Blob}    data        blob data from API response
 * @param {Object}  config      configuration for the file
 */
export const downloadExcelFile = (data, config = {}) => {
  const { contentTypeHeader, fileName = 'cases.xlsx' } = config;
  const downloadLink = window.document.createElement('a');
  downloadLink.href = URL.createObjectURL(
    new Blob([data], { type: contentTypeHeader })
  );
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

/**
 * Get user's current time-zone details
 *
 * @returns {String|null}
 */
export const getCurrentTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    return Error('Unable to get timezone details');
  }
};
