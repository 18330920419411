import { createSelector } from '@reduxjs/toolkit';

import { MASTER_DATA_TYPE } from 'constants/master';

const masterState = state => state.master;

/**
 * To return the loading state of the master data
 */
export const isMasterDataLoading = createSelector(
  masterState,
  /**
   * @param     {Object}    state
   * @returns   {Boolean}
   */
  state => state.isLoading
);

/**
 * To return master data for CASE_OR_PROPOSAL
 */
export const getCaseOrProposals = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.CASE_OR_PROPOSAL]
);

/**
 * To return master data for CASE_TYPE
 */
export const getCaseTypes = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.CASE_TYPE] || []
);

/**
 * To return master data for DIGITAL_MODULES_USED
 */
export const getDigitalModulesUsed = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.DIGITAL_MODULES_USED] || []
);

/**
 * To return master data for DIGITAL_TEAMS_INVOLVED
 */
export const getDigitalTeamsInvolved = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.DIGITAL_TEAMS_INVOLVED] || []
);

/**
 * To return master data for FIRM_NAME
 */
export const getFirmNames = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.FIRM_NAME] || []
);

/**
 * To return master data for FIRM_TYPE
 */
export const getFirmTypes = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.FIRM_TYPE] || []
);

/**
 * To return master data for INFORMATION_SOURCES
 */
export const getInformationSources = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.INFORMATION_SOURCES] || []
);

/**
 * To return master data for KEY_TERMS
 */
export const getKeyTerms = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.KEY_TERMS] || []
);

/**
 * To return master data for MAJOR_INDUSTRY
 */
export const getMajorIndutries = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.MAJOR_INDUSTRY] || []
);

/**
 * To return master data for OFFICE_LOCATION
 */
export const getOfficeLocations = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.OFFICE_LOCATION] || []
);

/**
 * To return master data for PIPE_TOPICS
 */
export const getPipeTopics = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.PIPE_TOPICS] || []
);

/**
 * To return master data for REGION
 */
export const getRegions = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.REGION] || []
);

/**
 * To return master data for RELEVANT_PLAYERS
 */
export const getRelevantPlayers = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.RELEVANT_PLAYERS] || []
);

/**
 * To return master data for TARGET_INDUSTRY
 */
export const getTargetIndustries = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.TARGET_INDUSTRY] || []
);

/**
 * To return master data for TARGET_NAME
 */
export const getTargetNames = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.TARGET_NAME] || []
);

/**
 * To return master data for VALUE_CREATION_LEVERS
 */
export const getValueCreationLevers = createSelector(
  masterState,
  /**
   * @param     {Object} state
   * @returns   {Array}
   */
  state => state[MASTER_DATA_TYPE.VALUE_CREATION_LEVERS] || []
);
