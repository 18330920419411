import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Pagination, Dropdown, Icon } from 'semantic-ui-react';

import Tooltip from 'components/DataTable/Tooltip';
import { dropdownLimitOptions } from 'utils/DataTable';
import SettingsIcon from 'components/Icons/Settings';
import './PaginationPanel.scss';

const PaginationPanel = ({
  id,
  pagination,
  handlePaginationChange,
  totalRows,
  limitRows,
  handleLimitChange,
}) => {
  const paginationState = {
    boundaryRange: 2,
    siblingRange: 1,
    showEllipsis: true,
  };
  const { boundaryRange, siblingRange, showEllipsis } = paginationState;
  const { page, firstPage, lastPage, align, limit } = pagination;

  const paginationItem = (label, iconName, disabled) => {
    return {
      content: (
        <Tooltip content={label}>
          <Icon name={iconName} />
        </Tooltip>
      ),
      icon: true,
      disabled,
    };
  };

  return (
    <Grid.Row id={`${id}-pagination-row`}>
      <Grid.Column>
        <div
          id="pagination-bar"
          style={{
            width: '100%',
            textAlign: pagination && align ? align : 'center',
          }}
        >
          <Pagination
            id="grid-pagination"
            activePage={page}
            boundaryRange={boundaryRange}
            onPageChange={handlePaginationChange}
            siblingRange={siblingRange}
            totalPages={Math.ceil(totalRows / limit)}
            ellipsisItem={showEllipsis ? '...' : null}
            firstItem={null}
            lastItem={null}
            prevItem={paginationItem('Prev page', 'angle left', firstPage)}
            nextItem={paginationItem('Next page', 'angle right', lastPage)}
          />
          <Dropdown
            id="page-limit"
            upward
            icon={
              <i className="icon">
                <SettingsIcon />
              </i>
            }
            options={dropdownLimitOptions(limitRows)}
            onChange={handleLimitChange}
            trigger={<></>}
            value={limit}
          />
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

PaginationPanel.propTypes = {
  id: PropTypes.string,
  pagination: PropTypes.shape({
    align: PropTypes.oneOf(['left', 'center', 'right']),
    page: PropTypes.number.isRequired,
    firstPage: PropTypes.bool,
    lastPage: PropTypes.bool,
    limit: PropTypes.number,
  }).isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  limitRows: PropTypes.array.isRequired,
  handleLimitChange: PropTypes.func.isRequired,
};

PaginationPanel.defaultProps = {
  id: '',
};

export default PaginationPanel;
