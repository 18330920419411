import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Checkbox } from 'semantic-ui-react';

const Option = props => {
  const { isSelected, label, data } = props;
  return (
    <div>
      <components.Option {...props}>
        <Checkbox checked={isSelected} label={label} disabled={data.isFixed} />
      </components.Option>
    </div>
  );
};

export default Option;

Option.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    isFixed: PropTypes.bool,
  }),
};

Option.defaultProps = {
  isSelected: false,
  data: PropTypes.shape({
    isFixed: false,
  }),
};
