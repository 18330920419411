import { createSlice } from '@reduxjs/toolkit';

import { SLICES } from 'constants/store';
import reducers from './reducers';

const INITIAL_STATE = {
  modal: {
    open: false,
    type: null,
  },
  loading: {
    isLoading: false,
    message: null,
  },
  filter: {
    open: false,
  },
  globalLoader: {
    active: false,
  },
  alertModal: {
    open: false,
    data: {},
  },
};
export default createSlice({
  name: SLICES.UI,
  initialState: INITIAL_STATE,
  reducers,
  extraReducers: {},
});
