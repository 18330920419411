import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import ComboBox from 'components/ComboBox/ComboBox';
import {
  getDigitalModulesUsed,
  getDigitalTeamsInvolved,
} from 'store/master/selectors';
import { CaseDetailsContext } from 'utils/context';
import { cloneDeep, isEqual } from 'utils/common';

function EditDigitalContextBlock({ setFormData }) {
  const {
    data: { digitalModulesUsed, digitalTeamsInvolved },
  } = useContext(CaseDetailsContext);
  const savedFormFields = { digitalModulesUsed, digitalTeamsInvolved };

  const digitalModules = useSelector(getDigitalModulesUsed)
    .filter(({ id, digitalModulesUsedName: label }) => id && label)
    .map(({ id, digitalModulesUsedName: label }) => ({ label, value: id }));

  const digitalTeams = useSelector(getDigitalTeamsInvolved)
    .filter(({ id, digitalTeamsInvolvedName: label }) => id && label)
    .map(({ id, digitalTeamsInvolvedName: label }) => ({
      label,
      value: id,
    }));

  const [formFields, setFormFields] = useState({
    digitalTeamsInvolved: savedFormFields.digitalTeamsInvolved || '',
    digitalModulesUsed: savedFormFields.digitalModulesUsed || '',
  });

  const handleChange = name => data => {
    const { caseUpdateValue, masterDataUpdateValue } = data;
    let isFormUpdated = false;

    const formFieldObject = cloneDeep(formFields);
    formFieldObject[name] = caseUpdateValue;

    setFormFields(formFieldObject);

    if (!isEqual(formFields, savedFormFields)) {
      isFormUpdated = true;
    }

    const updatedFormDataInformation = {
      formData: formFieldObject,
      isFormUpdated,
      masterDataUpdateValue: {},
    };

    updatedFormDataInformation.masterDataUpdateValue[name] =
      masterDataUpdateValue;

    setFormData(updatedFormDataInformation);
  };

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <ComboBox
            header="Digital Teams"
            options={digitalTeams}
            selectedValue={formFields.digitalTeamsInvolved}
            onChange={handleChange('digitalTeamsInvolved')}
            name="digitalTeamsInvolved"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ComboBox
            header="Digital Modules"
            options={digitalModules}
            selectedValue={formFields.digitalModulesUsed}
            onChange={handleChange('digitalModulesUsed')}
            name="digitalModulesUsed"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

EditDigitalContextBlock.propTypes = {
  setFormData: PropTypes.func.isRequired,
};

export default EditDigitalContextBlock;
