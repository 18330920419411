const reducers = {
  OPEN_MODAL: (state, action) => {
    state.modal.open = true;
    state.modal.type = action.payload.type;
  },
  CLOSE_MODAL: state => {
    state.modal.open = false;
    state.modal.type = null;
    state.uploadStatus = { uploading: false };
  },
  SHOW_LOADING: (state, { payload }) => {
    state.loading.isLoading = true;
    state.loading.message = payload.message || null;
  },
  HIDE_LOADING: state => {
    state.loading.isLoading = false;
    state.loading.message = null;
  },
  TOGGLE_FILTER: state => {
    state.filter.open = !state.filter.open;
  },
  SHOW_GLOBAL_LOADER: (state, { payload }) => {
    state.globalLoader.active = payload;
  },
  SHOW_ALERT_MODAL: (state, { payload }) => {
    state.alertModal = payload;
  },
};

export default reducers;
