import PropTypes from 'prop-types';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import Upload from 'components/Icons/Upload';
import Remove from 'components/Icons/Remove';
import './ColumnActions.scss';

function ColumnActions({
  id,
  caseCode,
  handleRemove,
  handleUpload,
  rowHighlighter,
}) {
  if (!rowHighlighter) {
    return null;
  }

  return (
    <div className="column-actions">
      <Popup
        content="Update"
        trigger={
          <span className="upload-icon" onClick={handleUpload(id, caseCode)}>
            <Upload fill="#333" height="15px" width="15px" />
          </span>
        }
      />
      <Popup
        content="Delete"
        trigger={
          <span className="delete-icon" onClick={handleRemove(id, caseCode)}>
            <Remove fill="#333" height="15px" width="15px" />
          </span>
        }
      />
    </div>
  );
}

ColumnActions.propTypes = {
  id: PropTypes.string.isRequired,
  caseCode: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  rowHighlighter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ColumnActions.defaultProps = {
  rowHighlighter: false,
};

export default ColumnActions;
