/**
 *
 * @param   {HTMLElement|string} selector - HTML element object or possible element selector string
 * @returns {object}                      - object with markRegExp and unmark function
 */
export const textHighlight = selector => {
  let highlightClassName = '';
  const markerContext = [];

  /**
   *
   * @returns  - all possible elements for the highligting text
   */
  const getContexts = () => {
    if (markerContext.length) return markerContext;

    let context;
    if (typeof selector === 'undefined' || !selector) {
      context = [];
    } else if (typeof selector === 'string') {
      context = Array.prototype.slice.call(document.querySelectorAll(selector));
    } else {
      // HTMLElement
      context = [selector];
    }

    // filter duplicate text nodes
    context.forEach(ctx => {
      const isDescendant =
        markerContext.filter(nodes => {
          return nodes.contains(ctx);
        }).length > 0;
      if (markerContext.indexOf(ctx) === -1 && !isDescendant) {
        markerContext.push(ctx);
      }
    });
    return markerContext;
  };

  /**
   * Callback to filter nodes. Can return e.g. NodeFilter.FILTER_ACCEPT or
   * NodeFilter.FILTER_REJECT
   * @callback NodeIterator-filterCb
   * @see {@link https://developer.mozilla.org/en/docs/Web/API/NodeIterator}
   * @param {HTMLElement} node - The node to filter
   */

  /**
   * @typedef NodeIterator-whatToShow
   * @see {@link https://developer.mozilla.org/en/docs/Web/API/NodeIterator}
   * @type {number}
   */

  /**
   * Creates a NodeIterator on the specified context
   * @see {@link https://developer.mozilla.org/en/docs/Web/API/NodeIterator}
   * @param {HTMLElement}             ctx        - The context DOM element
   * @param {NodeIterator-whatToShow} whatToShow
   * @param {NodeIterator-filterCb}   filter
   * @return {NodeIterator}
   */
  const createIterator = (ctx, whatToShow, filter) => {
    return document.createNodeIterator(ctx, whatToShow, filter);
  };

  /**
   * Callback for each node
   * @callback NodeIterator-forEachNodeCallback
   * @param {HTMLElement} node - The DOM text node element
   */

  /**
   * Callback if all contexts were handled
   * @callback NodeIterator-forEachNodeEndCallback
   */

  /**
   * Iterates through all nodes in the specified context
   * @param {NodeIterator-whatToShow}             whatToShow
   * @param {HTMLElement}                         ctx        - The context
   * @param  {NodeIterator~forEachNodeCallback}   eachCb     - Each callback
   * @param {NodeIterator-filterCb}               filterCb   - Filter callback
   * @param {NodeIterator-forEachNodeEndCallback} doneCb     - End callback
   */
  const iterateThroughNodes = (whatToShow, ctx, eachCb, filterCb, doneCb) => {
    const iterator = createIterator(ctx, whatToShow, filterCb);
    const elements = [];
    const retrieveNodes = () => iterator.nextNode();

    let currentNode;
    while ((currentNode = retrieveNodes())) {
      elements.push(currentNode);
    }
    elements.forEach(node => {
      eachCb(node);
    });

    doneCb();
  };

  /**
   * Iterates over all contexts and initializes
   * @param {NodeIterator-whatToShow}             whatToShow
   * @param  {NodeIterator-forEachNodeCallback}   each        - Each callback
   * @param {NodeIterator-filterCb}               filter      - Filter callback
   * @param {NodeIterator-forEachNodeEndCallback} done        - End callback
   */
  const forEachNode = (whatToShow, each, filter, doneCb = () => null) => {
    const contexts = getContexts();
    let open = contexts.length;
    const doneCallback = () => {
      if (--open <= 0) {
        // call end all contexts were handled
        doneCb();
      }
    };

    contexts.forEach(ctx => {
      iterateThroughNodes(whatToShow, ctx, each, filter, doneCallback);
    });
  };

  /**
   * @typedef TextHighlight-TextNodes
   * @type {object.<string>}
   * @property {string}      value       - The composite value of all text nodes
   * @property {object[]}    nodes       - An array of objects
   * @property {number}      nodes.start - The start position within the composite
   * value
   * @property {number}      nodes.end   - The end position within the composite
   * value
   * @property {HTMLElement} nodes.node  - The DOM text node element
   */

  /**
   * Callback
   * @callback TextHighlight-getTextNodesCallback
   * @param {TextHighlight-TextNodes}
   */

  /**
   * Calls the callback with an object containing all text nodes
   * with start and end positions and the composite value
   * of them (string)
   * @param {TextHighlight-getTextNodesCallback} doneCb - Callback
   * @access protected
   */
  const getTextNodes = doneCb => {
    let val = '';
    const nodes = [];
    const eachcallback = node => {
      if (val.length && !(/\s$/.test(val) || /^\s/.test(node.textContent))) {
        node.textContent = ' ' + node.textContent;
      }
      nodes.push({
        start: val.length,
        end: (val + node.textContent).length,
        node,
      });
      val += node.textContent;
    };
    const nodeFilter = () => NodeFilter.FILTER_ACCEPT;
    const doneCallback = () =>
      doneCb({
        value: val,
        nodes: nodes,
      });

    forEachNode(NodeFilter.SHOW_TEXT, eachcallback, nodeFilter, doneCallback);
  };

  /**
   * Wraps the instance element and class around matches that fit the start and
   * end positions within the node
   * @param  {HTMLElement} node  - The DOM text node
   * @param  {number}      start - The position where to start wrapping
   * @param  {number}      end   - The position where to end wrapping
   * @return {HTMLElement}       - Returns the splitted text node that will appear
   */
  const wrapRangeInTextNode = (node, start, end) => {
    const highlightElement = 'mark';
    const startNode = node.splitText(start);
    const returnNode = startNode.splitText(end - start);
    const highlightNode = document.createElement(highlightElement);
    highlightNode.setAttribute('data-marker', 'true');

    if (highlightClassName) {
      highlightNode.setAttribute('class', highlightClassName);
    } else {
      highlightNode.setAttribute('style', 'background-color:#ff0');
    }

    highlightNode.textContent = startNode.textContent;
    startNode.parentNode.replaceChild(highlightNode, startNode);
    return returnNode;
  };

  /**
   * recalcuates start and end positions from the given node index using end index of current match
   * and updates the text nodes with calcuted positions
   * @param  {TextHighlight-TextNodes} textNode  - The text nodes in a context
   * @param  {number}                  endIndex  - The end position of the match in a text node
   * @param  {number}                  nodeIndex - node index from which the positions has to be calculated
   */
  const recalculatePosition = (nodes, endIndex, nodeIndex) => {
    nodes.forEach((_, j) => {
      if (j >= nodeIndex) {
        if (nodes[j].start > 0 && j !== nodeIndex) {
          nodes[j].start -= endIndex;
        }
        nodes[j].end -= endIndex;
      }
    });

    return nodes;
  };

  /**
   * Determines matches by start and end positions using the text node
   * even across text nodes and calls
   * @param  {TextHighlight-TextNodes} textNode - The text nodes in a context
   * @param  {number}                  start    - The start position of the match
   * @param  {number}                  end      - The end position of the match
   */
  const wrapRangeInMappedTextNode = (textNode, start, end) => {
    // iterate over all text nodes to find the one matching the positions
    textNode.nodes.every((node, i) => {
      const sibling = textNode.nodes[i + 1];
      if (typeof sibling === 'undefined' || sibling.start > start) {
        // map range from textNode.value to text node
        // calculate the match string position in the current node
        // when there are multiple nodes
        const startIndex = start - node.start;
        const endIndex = (end > node.end ? node.end : end) - node.start;

        //get strings before and after the match string
        const startStr = textNode.value.substr(0, node.start);
        const endStr = textNode.value.substr(endIndex + node.start);
        node.node = wrapRangeInTextNode(node.node, startIndex, endIndex);

        // recalculate positions to also find subsequent matches in the
        // same text node. Necessary as the text node in textNode now only
        // contains the splitted part after the wrapped one
        textNode.value = startStr + endStr;
        textNode.nodes = recalculatePosition(textNode.nodes, endIndex, i);

        end -= endIndex;
        if (end > node.end) {
          start = node.end;
        } else {
          return false;
        }
      }
      return true;
    });
  };

  /**
   * Wraps the instance element and class around matches across all HTML
   * elements in all contexts
   * @param {RegExp} regex - The regular expression to be searched for
   */
  const wrapMatchesAcrossElements = regExp => {
    const matchIdx = 0;
    const findAndHighlightInNodes = textNode => {
      let match;
      while (
        (match = regExp.exec(textNode.value)) !== null &&
        match[matchIdx] !== ''
      ) {
        // calculate range inside textNode.value
        const start = match.index;
        const end = start + match[matchIdx].length;
        // note that textNode will be updated automatically, as it'll change
        // in the wrapping process, due to the fact that text
        // nodes will be splitted
        wrapRangeInMappedTextNode(textNode, start, end);
      }
    };
    getTextNodes(findAndHighlightInNodes);
  };

  /**
   * Marks a custom regular expression
   * @param  {RegExp} regexp    - The regular expression
   * @param  {string} className - class for the highliting element
   * @access public
   */
  const markRegExp = (regExp, className) => {
    highlightClassName = className;
    return wrapMatchesAcrossElements(regExp);
  };

  /**
   * Checks if the specified DOM element matches the selector
   * @param  {HTMLElement}     element  - The DOM element
   * @param  {string}          selector - The selector
   * @return {boolean}
   */
  const selectorMatches = (element, selectorString) => {
    const elementProto = Element.prototype;
    const matchfunction =
      elementProto.matches ||
      elementProto.webkitMatchesSelector ||
      elementProto.mozMatchesSelector ||
      elementProto.msMatchesSelector ||
      function (s) {
        return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
      };
    return matchfunction.call(element, selectorString);
  };

  /**
   * Unwraps the specified DOM node with its content (text nodes or HTML)
   * and normalizes the parent at the end (merge splitted text nodes)
   * @param  {HTMLElement} node - The DOM node to unwrap
   */
  const unwrapMatches = node => {
    const parent = node.parentNode;
    const docFrag = document.createDocumentFragment();
    while (node.firstChild) {
      docFrag.appendChild(node.removeChild(node.firstChild));
    }
    parent.replaceChild(docFrag, node);
    parent.normalize();
  };

  /**
   * Removes all marked elements inside the context with their HTML and
   * normalizes the parent at the end
   */
  const unmark = () => {
    let selectorString = 'mark[data-marker]';
    if (highlightClassName) {
      selectorString += `.${highlightClassName}`;
    }

    const nodefilter = node => {
      const matchesSel = selectorMatches(node, selectorString);
      if (!matchesSel) {
        return NodeFilter.FILTER_REJECT;
      } else {
        return NodeFilter.FILTER_ACCEPT;
      }
    };

    const eachCallback = node => unwrapMatches(node);

    forEachNode(NodeFilter.SHOW_ELEMENT, eachCallback, nodefilter);
  };

  return {
    markRegExp,
    unmark,
  };
};
