import React from 'react';

import {
  MAJOR_INDUSTRY_MAPPING,
  MAJOR_INDUSTRY_REVERSE_MAPPING,
  MAJOR_INDUSTRY_SUBSECTORS_MAPPING,
} from 'constants/case';
import { PATHNAME } from 'constants/router';
import { removeDuplicates } from './common';
import { prepareSearchQuery, getSortData } from 'utils/DataTable';
import { addAfter } from 'utils/array';
import {
  loadCaseDetails,
  downloadSearchResult,
} from 'store/data_table/asyncActions';
import {
  getBucketRecords,
  downloadBucketRecords,
} from 'store/bucket/asyncActions';
import { Message } from 'semantic-ui-react';

/**
 * Prapares and return the payload for major industries and sub-sectors
 *
 * @param   {Object}  majorIndustries
 * @returns {Array}
 */
export const preparePayloadForMajorIndustries = majorIndustries => {
  const payload = [];

  // Preparing payload for 'majorIndustries' node
  payload.push({
    majorIndustries: Object.keys(majorIndustries)
      .map(majorIndustry => MAJOR_INDUSTRY_MAPPING[majorIndustry])
      .toString(),
  });

  // Preparing payload for 'subSectors' node
  payload.push({
    subSectors: Object.values(majorIndustries).reduce(
      (prevValue, currentValue) => {
        let finalString = prevValue;

        if (Array.isArray(currentValue)) {
          currentValue.forEach(subSector => {
            if (subSector) {
              if (finalString) {
                finalString = `${finalString}, ${subSector}`;
              } else {
                finalString = subSector;
              }
            }
          });
        }

        return finalString;
      },
      ''
    ),
  });

  // Preparing payload for each major industry and its sub-sectors separately
  Object.keys(MAJOR_INDUSTRY_MAPPING).forEach(majorIndustry => {
    if (majorIndustries[majorIndustry]) {
      payload.push({
        [majorIndustry]: 'Yes',
      });
      if (MAJOR_INDUSTRY_SUBSECTORS_MAPPING[majorIndustry]) {
        payload.push({
          [MAJOR_INDUSTRY_SUBSECTORS_MAPPING[majorIndustry]]:
            majorIndustries[majorIndustry].toString(),
        });
      }
    } else {
      payload.push({
        [majorIndustry]: null,
      });

      if (MAJOR_INDUSTRY_SUBSECTORS_MAPPING[majorIndustry]) {
        payload.push({
          [MAJOR_INDUSTRY_SUBSECTORS_MAPPING[majorIndustry]]: null,
        });
      }
    }
  });

  return payload;
};

/**
 * Prepare value for major industries from selected options
 *
 * @param   {Array} selectedOptions
 * @returns {Object}
 */
export const preparePayloadValueForMajorIndustries = selectedOptions => {
  const selectedMajorIndustries = {};
  const getSubSectorFromValue = value => value.split(':')[0].trim();
  const getMajorIndustryFromValue = value => {
    if (value.split(':')[1]) {
      return value.split(':')[1].trim();
    }
    return value.split(':')[0];
  };

  selectedOptions.forEach(({ value }) => {
    const trimmedLabel =
      MAJOR_INDUSTRY_REVERSE_MAPPING[getMajorIndustryFromValue(value)];
    const trimmedValue = getSubSectorFromValue(value);

    if (
      Object.keys(MAJOR_INDUSTRY_REVERSE_MAPPING).includes(trimmedValue) &&
      !selectedMajorIndustries[trimmedLabel]
    ) {
      selectedMajorIndustries[trimmedLabel] = [];
    } else {
      if (selectedMajorIndustries[trimmedLabel]) {
        selectedMajorIndustries[trimmedLabel].push(trimmedValue);
      } else {
        selectedMajorIndustries[trimmedLabel] = [trimmedValue];
      }
    }
  });

  return selectedMajorIndustries;
};

/**
 * Formats payload for the update case details API
 *
 * @param   {Object} formData
 * @returns {Array}
 */
export const prepareUpdateCaseDetailsPayload = (formData, majorIndustries) => {
  let payload = [];

  Object.entries(formData).forEach(([key, value]) => {
    if (key === 'majorIndustrySubSectors') {
      payload = [...payload, ...preparePayloadForMajorIndustries(value)];
    } else if (key === 'majorIndustries') {
      payload = [...payload, ...preparePayloadForIPA(value)];
    } else if (key === 'subSectors') {
      payload = [
        ...payload,
        ...preparePayloadForSubSectors(value, majorIndustries),
      ];
    } else {
      payload.push({ [key]: value });
    }
  });

  return payload;
};

/**
 * Returns list of team members for proposals
 *
 * @param   {String} team
 * @returns {Array}
 */
export const getCaseTeamForProposal = team => {
  const teamArray = team ? team.split(',') : [];
  return teamArray.map(item => {
    const itemArray = item.split('-');
    return {
      name: itemArray[0].trim(),
      title: itemArray[1].trim(),
    };
  });
};

/**
 * Returns dropdown options for Major Industries and Sub-sectors dropdown
 *
 * @param   {Array} majorIndustries
 * @returns {Array}
 */
export const getMajorIndustriesDropdownOptions = majorIndustries => {
  const majorIndustryNames = removeDuplicates(
    majorIndustries.map(({ industryPracticeArea }) => industryPracticeArea)
  );

  return majorIndustryNames.map(majorIndustryName => ({
    label: majorIndustryName,
    options: [
      { label: majorIndustryName, value: majorIndustryName },
      ...majorIndustries
        .filter(
          ({ industryPracticeArea, subSector }) =>
            industryPracticeArea === majorIndustryName && subSector
        )
        .map(({ subSector }) => ({
          label: subSector,
          value: subSector + ': ' + majorIndustryName,
        })),
    ],
  }));
};

/**
 * Returns sub-sector and major industry values
 *
 * @param   {Object} data
 * @returns {Object}
 */
export const getMajorIndustrySubSectors = data => {
  let majorIndustryvalue = '';
  let subSectorValue = '';
  for (const item in MAJOR_INDUSTRY_MAPPING) {
    if (data[item]?.toUpperCase() === 'Yes'.toUpperCase()) {
      majorIndustryvalue += ', ' + MAJOR_INDUSTRY_MAPPING[item];
      if (data[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]]) {
        subSectorValue += ', ' + data[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]];
      }
    }
  }
  subSectorValue = subSectorValue.substring(2);
  majorIndustryvalue = majorIndustryvalue.substring(2);
  return { subSectorValue, majorIndustryvalue };
};

export const getChipLabelForMajorIndustriesFormData = ({
  majorIndustryFormData,
  majorIndustry,
  subSector,
}) => {
  return majorIndustryFormData[
    MAJOR_INDUSTRY_SUBSECTORS_MAPPING[majorIndustry]
  ] === MAJOR_INDUSTRY_MAPPING[majorIndustry]
    ? MAJOR_INDUSTRY_MAPPING[majorIndustry]
    : subSector + ': ' + MAJOR_INDUSTRY_MAPPING[majorIndustry];
};
/**
 * Prepares and returns the form data for Major Industry
 * and Sub-sectors form input control
 *
 * @param   {Object} majorIndustryFormData
 * @returns {Array}
 */
export const getMajorIndustriesSelectedFormData = majorIndustryFormData => {
  let formData = [];

  for (const item in MAJOR_INDUSTRY_MAPPING) {
    if (majorIndustryFormData[item]?.toUpperCase() === 'Yes'.toUpperCase()) {
      if (majorIndustryFormData[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]]) {
        const subSectors =
          majorIndustryFormData[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]].split(
            ','
          );

        formData = [
          ...formData,
          ...subSectors.map(subSector => ({
            chipLabel: getChipLabelForMajorIndustriesFormData({
              majorIndustryFormData,
              majorIndustry: item,
              subSector,
            }),
            label:
              majorIndustryFormData[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]],
            value:
              majorIndustryFormData[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]] ===
              MAJOR_INDUSTRY_MAPPING[item]
                ? MAJOR_INDUSTRY_MAPPING[item]
                : subSector + ': ' + MAJOR_INDUSTRY_MAPPING[item],
          })),
        ];
      } else {
        formData.push({
          chipLabel: MAJOR_INDUSTRY_MAPPING[item],
          label: MAJOR_INDUSTRY_MAPPING[item],
          value: MAJOR_INDUSTRY_MAPPING[item],
        });
      }
    }
  }

  return formData;
};

/**
 * Format the success message for the upload cases workflow
 *
 * @param   {Object} param
 * @returns {String}
 */
export const getUploadSuccessMessage = ({
  totalNumberOfCasesUploaded,
  totalNumberOfRowsSkipped,
  totalDuplicateCases,
  indexOfRowsSkipped,
}) => {
  const messageType = totalNumberOfRowsSkipped
    ? { info: true }
    : { positive: true };
  return (
    <Message {...messageType}>
      <Message.Header style={{ textAlign: 'left' }}>
        Sucessfully uploaded the file.
      </Message.Header>
      <Message.List>
        {Boolean(totalNumberOfCasesUploaded) && (
          <Message.Item>
            Total records uploaded: {totalNumberOfCasesUploaded}
          </Message.Item>
        )}
        {Boolean(totalDuplicateCases) && (
          <Message.Item>
            Total conflicts found: {totalDuplicateCases}
          </Message.Item>
        )}
        {Boolean(totalNumberOfRowsSkipped) && (
          <Message.Item>
            Total records skipped: {totalNumberOfRowsSkipped}
          </Message.Item>
        )}
        {Boolean(indexOfRowsSkipped?.length) && (
          <Message.Item>
            Following rows are skipped: {indexOfRowsSkipped.join(', ')}
          </Message.Item>
        )}
      </Message.List>
    </Message>
  );
};

/**
 * Prepares the Relevant player based on the user role
 *
 * @param   {Object} param
 * @returns {String}
 */
export const getRelevantPlayers = ({
  targetName,
  relevantPlayers,
  isAdminUser,
}) => {
  let relevantPlayersArray = relevantPlayers
    ?.split(',')
    .map(name => name.trim())
    .filter(name => name);

  if (!isAdminUser && targetName && !targetName?.match(/^(unknown|none)$/i)) {
    // In case of non-admin users, placing 'Target Name' randomly
    // between list of 'Relevant Players'
    if (Array.isArray(relevantPlayersArray) && relevantPlayersArray.length) {
      relevantPlayersArray = addAfter(
        relevantPlayersArray,
        relevantPlayersArray[
          Math.floor(Math.random() * relevantPlayersArray.length)
        ],
        targetName
      );
    }
  }

  return relevantPlayersArray?.sort(Intl.Collator().compare).join(', ');
};

/**
 *
 * @param    {object} param
 * @returns  {function}      - dispatch action for get case details
 */
export const getAllCaseDetailsAction = ({
  currentIndex,
  sortDirection,
  sortBy,
  query,
  from,
}) => {
  const searchQuery = {
    pageNumber: 1,
    limit: currentIndex < 400 ? 500 : (currentIndex || 1) + 500,
    sortDirection: getSortData(sortDirection, true),
    sortBy: sortBy,
    ...(query ? query : null),
  };
  let getResultAction;
  switch (from) {
    case PATHNAME.MY_LISTS: {
      getResultAction = getBucketRecords(searchQuery);
      break;
    }
    case PATHNAME.search:
    default:
      getResultAction = loadCaseDetails(searchQuery);
      break;
  }

  return getResultAction;
};

/**
 * Prepares the Relevant player based on the user role
 *
 * @param   {Object}   param
 * @returns {function}       - dispatch action for download case details
 */
export const getDownloadAction = ({
  page,
  limit,
  sortDirection,
  sortBy,
  query,
  from,
}) => {
  let downloadResult = downloadSearchResult;
  let downloadQuery = prepareSearchQuery({
    page,
    limit,
    sortDirection,
    sortBy,
    searchQuery: query?.search,
    advanceFilter: query?.advanceFilter,
  });
  if (from === PATHNAME.MY_LISTS) {
    downloadResult = downloadBucketRecords;
    downloadQuery = {
      bucketIds: query?.bucketIds,
    };
  }

  return downloadResult(downloadQuery);
};

const MAJORINDUSTRIES_ID_MAP = majorIndustries => {
  return majorIndustries.reduce(function (result, majorIndutry) {
    result[majorIndutry.id] = majorIndutry;
    return result;
  }, {});
};

export const preparePayloadForIPA = majorIndustriesFormData => {
  const payload = [];
  const majorIndustries = [];
  Object.keys(MAJOR_INDUSTRY_MAPPING).forEach(majorIndustry => {
    if (majorIndustriesFormData.indexOf(majorIndustry) !== -1) {
      majorIndustries.push(MAJOR_INDUSTRY_MAPPING[majorIndustry]);
      payload.push({
        [majorIndustry]: 'Yes',
      });
    } else {
      payload.push({
        [majorIndustry]: null,
      });
    }
  });
  payload.push({ majorIndustries: majorIndustries.toString() });
  return payload;
};

export const preparePayloadForSubSectors = (
  subSectorsFormData,
  majorIndustries
) => {
  const payload = [];
  const subSectors = [];

  const industriesMap = MAJORINDUSTRIES_ID_MAP(majorIndustries);
  const tempPayload = {};

  subSectorsFormData.forEach(subSectorId => {
    const subSectorKey =
      MAJOR_INDUSTRY_SUBSECTORS_MAPPING[
        MAJOR_INDUSTRY_REVERSE_MAPPING[
          industriesMap[subSectorId].industryPracticeArea
        ]
      ];
    subSectors.push(industriesMap[subSectorId].subSector);
    if (tempPayload[subSectorKey]) {
      tempPayload[subSectorKey] =
        tempPayload[subSectorKey] + ',' + industriesMap[subSectorId].subSector;
    } else {
      tempPayload[subSectorKey] = industriesMap[subSectorId].subSector;
    }
  });

  Object.keys(MAJOR_INDUSTRY_SUBSECTORS_MAPPING).forEach(subSector => {
    const subSectorKey = MAJOR_INDUSTRY_SUBSECTORS_MAPPING[subSector];

    if (tempPayload[subSectorKey]) {
      payload.push({ [subSectorKey]: tempPayload[subSectorKey] });
    } else {
      payload.push({ [subSectorKey]: null });
    }
  });
  payload.push({ subSectors: subSectors.toString() });
  return payload;
};

/**
 * Returns dropdown options for Major Industries dropdown
 *
 * @returns {Array}
 */
export const getIndustriesPracticeAreaDropdownOptions = () => {
  return Object.keys(MAJOR_INDUSTRY_MAPPING).map(key => ({
    value: key,
    label: MAJOR_INDUSTRY_MAPPING[key],
  }));
};

export const getSelectedIPAValueFromData = majorIndustryFormData => {
  const industryPracticeArea = [];
  for (const item in MAJOR_INDUSTRY_MAPPING) {
    if (majorIndustryFormData[item]?.toUpperCase() === 'Yes'.toUpperCase()) {
      industryPracticeArea.push(item);
    }
  }
  return industryPracticeArea;
};

/**
 * Returns dropdown options for Sub-sectors dropdown
 *
 * @param   {Array} majorIndustries
 * @returns {Array}
 */
export const getSubSectorDropdownOptions = (
  majorIndustries,
  selectedIPAs = []
) => {
  const selectedIPAVal = selectedIPAs.map(ipa => MAJOR_INDUSTRY_MAPPING[ipa]);
  return majorIndustries
    .filter(
      ({ industryPracticeArea, subSector }) =>
        selectedIPAVal.indexOf(industryPracticeArea) !== -1 && subSector
    )
    .map(({ id, subSector }) => ({
      value: id,
      label: subSector,
    }));
};

/**
 * Prepares and returns the form data for Sub Sectors form input control
 *
 * @param   {Object} majorIndustryFormData
 * @returns {Array}
 */

export const getSubSectorSelectedFormData = (
  majorIndustries,
  majorIndustryFormData
) => {
  const subSectorsData = [];

  for (const item in MAJOR_INDUSTRY_SUBSECTORS_MAPPING) {
    if (majorIndustryFormData[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]]) {
      const subSectors =
        majorIndustryFormData[MAJOR_INDUSTRY_SUBSECTORS_MAPPING[item]].split(
          ','
        );

      const matched = majorIndustries.filter(
        industry =>
          subSectors.indexOf(industry.subSector) > -1 &&
          MAJOR_INDUSTRY_MAPPING[item] === industry.industryPracticeArea
      );

      subSectorsData.push(...matched);
    }
  }

  return subSectorsData.map(subSector => subSector.id);
};
