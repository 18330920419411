import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { getContactEmail } from 'utils/user';
import './CaseContact.scss';

function CaseContact({ caseCode, date, isMaterial }) {
  return (
    <div className="case-contact">
      <div className="case-contact__start-date">Start Date</div>
      <div className="case-contact__date">{date}</div>
      {isMaterial?.toUpperCase() === 'Yes'.toUpperCase() ? (
        <Button
          className="case-contact__button"
          href={`mailto:${getContactEmail()}?subject=${caseCode}`}
        >
          <span className="case-contact__button__text">
            Contact PIPE KT for material
          </span>
        </Button>
      ) : null}
    </div>
  );
}

CaseContact.propTypes = {
  caseCode: PropTypes.string,
  date: PropTypes.string,
  isMaterial: PropTypes.oneOf(['Yes', 'No']),
};

CaseContact.defaultProps = {
  caseCode: '',
  date: '-',
  isMaterial: 'No',
};

export default CaseContact;
