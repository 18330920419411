import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Grid, Icon, Image, Message, Modal } from 'semantic-ui-react';

import cancel from 'assets/images/icons/cancel.svg';
import save from 'assets/images/icons/save.svg';
import FormTextArea from 'components/FormTextArea';
import { useDispatch } from 'react-redux';
import { shareBucket } from 'store/bucket/asyncActions';
import { toastError, toastSuccess } from 'utils/toast';
import { extractErrorMessage } from 'utils/error';
import ui from 'store/ui';

function ShareListModal({ bucketId, onClose, open }) {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  const handleUsersListUpdate = (_e, value) => {
    setUsersList(value.split(','));
  };

  const shareList = () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(shareBucket({ bucketId, usersList }))
      .unwrap()
      .then(() => {
        toastSuccess('List shared successfully');
        onClose();
      })
      .catch(error => {
        toastError(extractErrorMessage(error, 'Failed to share list'));
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  const SpecialNote = () => (
    <Message
      style={{ textAlign: 'left', boxShadow: 'none' }}
      header="Note: "
      content="Please note that using share list feature you can only share the complete list and not specific records within a given list."
    />
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <h1>Share List</h1>
      </Modal.Header>
      <Modal.Content>
        <FormTextArea
          header={`Enter Email(s) separated by comma "," `}
          onChange={handleUsersListUpdate}
          name="caseDescription"
        />
      </Modal.Content>
      <Modal.Actions className="footer">
        <Grid>
          <Grid.Row>
            <Grid.Column width={11}>
              <SpecialNote />
            </Grid.Column>
            <Grid.Column width={5} verticalAlign={'bottom'}>
              <Button
                onClick={onClose}
                className="add-new-user__footer__cancel-btn"
              >
                <Icon>
                  <Image src={cancel} />
                </Icon>
                <span className="margin-left-10">Cancel</span>
              </Button>
              <Button className="green" onClick={shareList}>
                <Icon>
                  <Image src={save} />
                </Icon>
                <span className="margin-left-15">Share</span>
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
}

ShareListModal.propTypes = {
  bucketId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ShareListModal.defaultProps = {
  open: false,
};

export default ShareListModal;
