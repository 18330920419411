import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import alertIcon from 'assets/images/icons/alert.svg';
import Close from 'components/Icons/Close';

import './Alert.scss';

export function Alert({ open, message, onClose }) {
  const [showAlert, setShowAlert] = useState(open);

  useEffect(() => {
    setShowAlert(open);
  }, [open]);

  const closeModal = isClose => {
    setShowAlert(false);
    if (typeof onClose === 'function') {
      onClose(isClose);
    }
  };

  return (
    <Modal className="modal-alert" open={showAlert} size="tiny">
      <Modal.Header className="modal-alert__header">
        <span className="modal-alert__header__icon">
          <img src={alertIcon} alt="alert icon" />
        </span>
        <span className="modal-alert__header__text">Alert!</span>
        <span
          className="modal-alert__header__close"
          onClick={() => {
            closeModal(true);
          }}
        >
          <Close fill="#D7D7D7" height="13" width="13" />
        </span>
      </Modal.Header>
      <Modal.Content className="modal-alert__content">
        <p className="modal-alert__content__text">{message}</p>
      </Modal.Content>
      <Modal.Actions className="footer actions modal-alert__footer">
        <Button
          onClick={() => {
            closeModal(false);
          }}
          className="green modal-alert__footer__submit"
          content="OK"
        />
      </Modal.Actions>
    </Modal>
  );
}

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  onClose: () => null,
};
