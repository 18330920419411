import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Modal,
} from 'semantic-ui-react';

import cancel from 'assets/images/icons/cancel.svg';
import save from 'assets/images/icons/save.svg';
import FormDropdown from 'components/FormDropdown';
import FormTextInput from 'components/FormTextInput';
import { USER_ROLES } from 'constants/user';
import { getUserName } from 'store/okta_user/selectors';
import ui from 'store/ui';
import { createUser, getUserByEmailId } from 'store/user/asyncActions';
import { extractErrorMessage } from 'utils/error';
import { toastSuccess, toastError } from 'utils/toast';
import { getUserRoleOptions, isValidEmail } from 'utils/user';
import './AddNewUserModal.scss';

function AddNewUserModal({ onAddNewUser, onClose, open }) {
  const dispatch = useDispatch();
  const loggedInUserFullName = useSelector(getUserName);
  const [formData, setFormData] = useState({
    email: '',
    role: '',
    createdBy: loggedInUserFullName,
    firstName: '',
    lastName: '',
  });
  const [isEmailValid, setEmailValid] = React.useState(true);
  const [hasValidFormData, setHasValidFormData] = React.useState(false);
  const [isAutoPopulateLoading, setAutoPopulateLoading] = React.useState(false);

  const isFormDataValid = updatedFormData => {
    let isValid = true;

    for (const formDataNode in updatedFormData) {
      const value = updatedFormData[formDataNode];
      if (formDataNode === 'email') {
        isValid = isValidEmail(value);
      } else if (!value) {
        isValid = false;
      }
    }

    return isValid;
  };

  const getUsernameByEmail = async () => {
    return new Promise((resolve, reject) => {
      dispatch(getUserByEmailId(formData.email))
        .unwrap()
        .then(response => {
          const { firstName = '', lastName = '' } =
            response?.data?.searchFilter?.employees[0] || {};
          setFormData({
            ...formData,
            firstName,
            lastName,
          });

          if (!firstName || !lastName) {
            reject(Error('Entered email id does not exist in the system'));
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const onBlurEmail = () => {
    if (isValidEmail(formData.email)) {
      setAutoPopulateLoading(true);
      getUsernameByEmail()
        .catch(error => {
          toastError(
            extractErrorMessage(
              error,
              'Entered email id does not exist in the system'
            )
          );
        })
        .finally(() => {
          setAutoPopulateLoading(false);
        });
    } else {
      setFormData({
        ...formData,
        firstName: '',
        lastName: '',
      });
    }
  };

  const onChange = field => (_e, value) => {
    if (field) {
      const updatedFormData = {
        ...formData,
        [field]: value,
      };

      setFormData(updatedFormData);
    }
  };

  const onFormSubmit = () => {
    getUsernameByEmail().then(addUser);
  };

  const addUser = () => {
    dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));
    dispatch(createUser(formData))
      .unwrap()
      .then(() => {
        toastSuccess('User created successfully');
        onAddNewUser();
      })
      .catch(error => {
        toastError(extractErrorMessage(error, 'Failed to create new user'));
      })
      .finally(() => {
        dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
      });
  };

  useEffect(() => {
    setEmailValid(isValidEmail(formData.email));
    setHasValidFormData(isFormDataValid(formData));
  }, [formData]);

  return (
    <Modal className="add-new-user" open={open} onClose={onClose}>
      <Modal.Header className="add-new-user__header">
        <h1>Add New User</h1>
      </Modal.Header>
      <Modal.Content className="add-new-user__content">
        <Form
          id="add_new_user_form"
          className="add-new-user__content__form"
          onSubmit={onFormSubmit}
          autoComplete="off"
        >
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <FormTextInput
                  required
                  className="add-new-user__content__form__email-input"
                  header="User Email Id"
                  name="email"
                  onBlur={onBlurEmail}
                  onChange={onChange('email')}
                  type="email"
                />
                {!isEmailValid && (
                  <p className="add-new-user__content__form__input-error">
                    Please enter a valid email address
                  </p>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div className="form-text-input-username">
                  <div className="form-text-input-username__label">
                    First Name
                  </div>
                  <Input
                    disabled
                    required
                    loading={isAutoPopulateLoading}
                    className="transparent"
                    value={formData.firstName}
                    name="firstName"
                    placeholder="Please enter a valid email address to auto-populate"
                    type="text"
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="form-text-input-username">
                  <div className="form-text-input-username__label">
                    Last Name
                  </div>
                  <Input
                    disabled
                    required
                    loading={isAutoPopulateLoading}
                    className="transparent"
                    value={formData.lastName}
                    name="lastName"
                    placeholder="Please enter a valid email address to auto-populate"
                    type="text"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FormDropdown
                  required
                  className="add-new-user__content__form__role-input"
                  header="User Role"
                  name="role"
                  options={getUserRoleOptions().filter(
                    ({ value }) => value !== USER_ROLES.REMOVE_ACCESS.NAME
                  )}
                  onChange={onChange('role')}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FormTextInput
                  disabled
                  required
                  className="add-new-user__content__form__added-by-input"
                  header="Added By"
                  name="createdBy"
                  value={formData.createdBy}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions className="footer add-new-user__footer">
        <Button onClick={onClose} className="add-new-user__footer__cancel-btn ">
          <Icon>
            <Image src={cancel} />
          </Icon>
          <span className="margin-left-10">Cancel</span>
        </Button>
        <Button
          className="green add-new-user__footer__submit"
          form="add_new_user_form"
          type="submit"
          disabled={!hasValidFormData}
        >
          <Icon>
            <Image src={save} />
          </Icon>
          <span className="margin-left-15">Add User</span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AddNewUserModal.propTypes = {
  onAddNewUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

AddNewUserModal.defaultProps = {
  open: false,
};

export default AddNewUserModal;
