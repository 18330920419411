import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'semantic-ui-react';

import { OPERATOR_SUGGESTIONS } from 'constants/search';
import './OperatorSuggestions.scss';

function OperatorSuggestions({
  leftOffset,
  onSelectSuggestion,
  selectedOperator,
}) {
  const onMouseDown = e => e.preventDefault();

  return (
    <div className="operator-suggestions" style={{ left: `${leftOffset}px` }}>
      <List>
        {selectedOperator === OPERATOR_SUGGESTIONS.AND && (
          <List.Item
            key={OPERATOR_SUGGESTIONS.AND}
            // Triggering preventDefault to prevent registered
            // onBlur event on the search input
            onMouseDown={onMouseDown}
            onClick={() => {
              onSelectSuggestion(OPERATOR_SUGGESTIONS.AND);
            }}
          >
            {OPERATOR_SUGGESTIONS.AND}
          </List.Item>
        )}

        {selectedOperator === OPERATOR_SUGGESTIONS.OR && (
          <List.Item
            key={OPERATOR_SUGGESTIONS.OR}
            // Triggering preventDefault to prevent registered
            // onBlur event on the search input
            onMouseDown={onMouseDown}
            onClick={() => {
              onSelectSuggestion(OPERATOR_SUGGESTIONS.OR);
            }}
          >
            {OPERATOR_SUGGESTIONS.OR}
          </List.Item>
        )}

        <List.Item
          key={OPERATOR_SUGGESTIONS.PLAIN_TEXT}
          // Triggering preventDefault to prevent registered
          // onBlur event on the search input
          onMouseDown={onMouseDown}
          onClick={() => {
            onSelectSuggestion(OPERATOR_SUGGESTIONS.PLAIN_TEXT);
          }}
        >
          {OPERATOR_SUGGESTIONS.PLAIN_TEXT}
        </List.Item>
      </List>
    </div>
  );
}

OperatorSuggestions.propTypes = {
  leftOffset: PropTypes.number,
  onSelectSuggestion: PropTypes.func.isRequired,
  selectedOperator: PropTypes.oneOf([
    OPERATOR_SUGGESTIONS.AND,
    OPERATOR_SUGGESTIONS.OR,
  ]).isRequired,
};

OperatorSuggestions.defaultProps = {
  leftOffset: 0,
};

export default OperatorSuggestions;
