import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Message } from 'semantic-ui-react';

import Remove from 'components/Icons/Remove';
import { ConfirmAlert } from 'components/modal';
import { DEFAULT_ERROR } from 'constants/errors';
import { getBucketList, getSelectedBuckets } from 'store/bucket/selectors';
import bucket from 'store/bucket';
import { deleteBucket, updateBucket } from 'store/bucket/asyncActions';
import {
  deleteBucketFromSelectedList,
  updateBucketName,
  validateBucketName,
  loadMyListRoute,
} from 'utils/bucketList';
import { toastSuccess, toastError } from 'utils/toast';
import { extractErrorMessage } from 'utils/error';
import DropdownItem from './DropdownItem';
import './BucketsDropdown.scss';

const BucketsDropdown = ({ open, closeDropdown }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const buckets = useSelector(getBucketList);
  const selectedBuckets = useSelector(getSelectedBuckets);
  const [showEditor, setShowEditor] = useState({});
  const [newBucket, setNewBucket] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setloading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeBucket, setActiveBucket] = useState({});

  useEffect(() => {
    setErrorMsg(
      !buckets.length
        ? 'List is not created. Go to search results and create new list.'
        : ''
    );
  }, [buckets]);

  useEffect(() => {
    if (open) {
      loadMyListRoute(pathname, history, selectedBuckets);
    }
  }, [history, pathname, selectedBuckets, open]);

  const selectBucket = (_e, data) => {
    const { value, checked } = data;
    setErrorMsg('');

    dispatch(
      bucket.actions.SET_SELECTED_BUCKET({
        ...selectedBuckets,
        [value]: checked,
      })
    );
  };

  const showAlertModal = bucketId => () => {
    setShowAlert(true);
    const bucketInfo = buckets.find(list => list.bucketId === bucketId);
    setActiveBucket(bucketInfo);
  };

  const hideAlertModal = () => {
    setShowAlert(false);
    setActiveBucket({});
  };

  const deleteItem = async () => {
    const bucketId = activeBucket.bucketId;
    setErrorMsg('');
    const bucketInfo = buckets.find(list => list.bucketId === bucketId);

    setloading(true);
    dispatch(deleteBucket(bucketId))
      .unwrap()
      .then(() => {
        toastSuccess(`List "${bucketInfo.name}" deleted successfully.`);
        const filteredList = deleteBucketFromSelectedList(
          bucketId,
          selectedBuckets
        );
        dispatch(bucket.actions.SET_SELECTED_BUCKET(filteredList));
      })
      .catch(error => {
        toastError(extractErrorMessage(error, DEFAULT_ERROR.general));
      })
      .finally(() => {
        setloading(false);
        hideAlertModal();
      });
  };

  const editItem = (bucketId, bucketName) => () => {
    setShowEditor({ [bucketId]: true });
    setErrorMsg('');
    setNewBucket({ [bucketId]: bucketName });
  };

  const onCancel = bucketId => () => {
    setShowEditor({ [bucketId]: false });
    setErrorMsg('');
    setNewBucket({ [bucketId]: '' });
  };

  const onSave = bucketId => () => {
    const bucketName = newBucket[bucketId];
    const status = validateBucketName({
      bucketName,
      buckets,
      onError: setErrorMsg,
    });

    if (!status) return false;

    saveBucket(bucketId, bucketName);
  };

  const saveBucket = async (bucketId, bucketName) => {
    setloading(true);
    dispatch(updateBucket({ bucketId, bucketName }))
      .unwrap()
      .then(() => {
        toastSuccess(`list name updated to ${bucketName} successfully.`);
        const updatedBuckets = updateBucketName(bucketId, bucketName, buckets);
        dispatch(bucket.actions.SET_BUCKET_LIST(updatedBuckets));
        setShowEditor({});
        setErrorMsg('');
      })
      .catch(error => {
        toastError(extractErrorMessage(error, DEFAULT_ERROR.general));
      })
      .finally(() => {
        setloading(false);
      });
  };

  //Allow space in input field inside Dropdown
  const onKeyDown = e => {
    setErrorMsg('');
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  };

  const onBucketNameChange = bucketId => e => {
    setNewBucket({ [bucketId]: e.target.value });
  };

  const AlertMessage = () => {
    return (
      <>
        Are you sure you want to delete the list &lsquo;
        <b>{activeBucket.name}</b>&rsquo;?
      </>
    );
  };

  return (
    <div
      className="buckets-dropdown__wrapper"
      ref={ref}
      style={{ display: open ? 'block' : 'none' }}
      onMouseLeave={closeDropdown}
    >
      <Dropdown
        trigger={<></>}
        pointing="left"
        floating
        compact
        className="buckets-dropdown"
        icon={null}
        closeOnChange={false}
        open={open}
      >
        <Dropdown.Menu>
          {buckets.map(({ name, bucketId }) => {
            const itemProps = {
              bucketId,
              editItem,
              loading,
              name,
              newBucket,
              onBucketNameChange,
              onCancel,
              onKeyDown,
              onSave,
              selectBucket,
              selectedBuckets,
              showAlertModal,
              showEditor,
            };
            return <DropdownItem key={bucketId} {...itemProps} />;
          })}
          {errorMsg && <Message error content={errorMsg} />}
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmAlert
        open={showAlert}
        confirmText="Delete"
        message={<AlertMessage />}
        onConfirm={deleteItem}
        onCancel={hideAlertModal}
        confirmIcon={<Remove fill="#fff" height="18px" width="18px" />}
        confirmButtonColor="red"
      />
    </div>
  );
};

export default React.forwardRef(BucketsDropdown);

BucketsDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};
