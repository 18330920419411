import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ui from 'store/ui';
import HomeCardsLayout from 'components/HomeCardsLayout';
import { STATISTICS_CATEGORY } from 'constants/statistics';
import { getStatistics } from 'store/statistics/asyncActions';
import { formatStatisticsCardsData } from 'utils/cards';
import { getMajorIndutries } from 'store/master/selectors';
import { cloneDeep } from 'utils/common';

function CasesByPracticeArea() {
  const dispatch = useDispatch();
  const majorIndustries = useSelector(getMajorIndutries);
  const [cards, setCards] = useState([]);

  const addFilters = React.useCallback(
    items => {
      const groupedData = majorIndustries.reduce((list, eachIndustry) => {
        list[eachIndustry.industryPracticeArea] = ['all'];
        return list;
      }, {});

      return items.map(record => ({
        ...record,
        filterValue: [
          {
            [record.value]: groupedData[record.value],
          },
        ],
      }));
    },
    [majorIndustries]
  );

  const getCards = React.useCallback(async () => {
    try {
      dispatch(ui.actions.SHOW_GLOBAL_LOADER(true));

      // Fetching statistics by PRACTICE AREA to show the details in cards
      const response = await dispatch(
        getStatistics(STATISTICS_CATEGORY.PRACTICE_AREA)
      );
      const { listOfGetStatisticsValues } = unwrapResult(response);
      const formattedCards = formatStatisticsCardsData(
        STATISTICS_CATEGORY.PRACTICE_AREA,
        addFilters(listOfGetStatisticsValues)
      );

      const sortedCards = [];
      let otherCard;
      formattedCards.forEach(card => {
        if (card.title === 'Other') {
          otherCard = cloneDeep(card);
        } else {
          sortedCards.push(card);
        }
      });
      sortedCards.push(otherCard);

      setCards(sortedCards);
    } finally {
      dispatch(ui.actions.SHOW_GLOBAL_LOADER(false));
    }
  }, [dispatch, addFilters]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  return <HomeCardsLayout columns={4} cards={cards} />;
}

export default CasesByPracticeArea;
