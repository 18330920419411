/**
 * Mapping of all master data types
 * @type  {Object}
 */
export const MASTER_DATA_TYPE = {
  CASE_OR_PROPOSAL: 'caseOrProposal',
  CASE_TYPE: 'caseType',
  DIGITAL_MODULES_USED: 'digitalModulesUsed',
  DIGITAL_TEAMS_INVOLVED: 'digitalTeamsInvolved',
  FIRM_NAME: 'firmName',
  FIRM_TYPE: 'firmType',
  INFORMATION_SOURCES: 'informationSources',
  KEY_TERMS: 'keyTerms',
  MAJOR_INDUSTRY: 'majorIndustry',
  OFFICE_LOCATION: 'officeLocation',
  PIPE_TOPICS: 'pipeTopics',
  REGION: 'region',
  RELEVANT_PLAYERS: 'relevantPlayers',
  TARGET_INDUSTRY: 'targetIndustry',
  TARGET_NAME: 'targetName',
  VALUE_CREATION_LEVERS: 'valueCreationLevers',
};
