import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes, { bool } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dimmer, Image, Loader } from 'semantic-ui-react';

import caseTeamLogo from 'assets/images/case-team-logo.svg';
import { CASE_TYPES } from 'constants/case';
import { getCaseTeamHRIDs } from 'store/case/asyncActions';
import { getUserByHRID, getUserPicture } from 'store/user/asyncActions';
import { isEmpty } from 'utils/common';
import { extractErrorMessage } from 'utils/error';
import { toastError } from 'utils/toast';
import TeamMember from './TeamMember';
import './CaseTeam.scss';
import { getCaseTeamForProposal } from 'utils/case';

function CaseTeam({
  caseCode,
  region,
  office,
  team,
  hasMaterials,
  isCaseOrProposal,
}) {
  const dispatch = useDispatch();
  const [caseTeam, setCaseTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [caseTeamPictures, setCaseTeamPictures] = useState({});

  const getOnClickTeamMemberHandler = hrid => () => {
    if (hrid) {
      window.open(
        `${process.env.REACT_APP_PROFILE_NAVIGATOR_CASE_URL}?hrid=${hrid}`
      );
    }
  };

  useEffect(() => {
    if (isCaseOrProposal === CASE_TYPES.PROPOSAL) {
      setCaseTeam(getCaseTeamForProposal(team));
    }
  }, [team, isCaseOrProposal]);

  useEffect(() => {
    if (caseCode && isCaseOrProposal === CASE_TYPES.CASE) {
      setLoading(true);
      dispatch(getCaseTeamHRIDs(caseCode))
        .then(unwrapResult)
        .then(response => {
          const hrids = response?.data;
          return Promise.all(hrids.map(hrid => dispatch(getUserByHRID(hrid))));
        })
        .then(employees => {
          const caseTeamResponse = employees.map(employee => {
            const unwrapEmployee = unwrapResult(employee)?.data?.employees[0];
            return {
              ...unwrapEmployee,
              name: `${unwrapEmployee.firstName} ${unwrapEmployee.lastName}`,
            };
          });
          setCaseTeam(caseTeamResponse);
        })
        .catch(error => {
          toastError(
            extractErrorMessage(error, 'Unable to fetch case team details')
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [caseCode, dispatch, isCaseOrProposal]);

  useEffect(() => {
    if (!isEmpty(caseTeam) && isCaseOrProposal === CASE_TYPES.CASE) {
      setLoading(true);
      Promise.all(
        caseTeam.map(({ profilePicture }) =>
          dispatch(getUserPicture(profilePicture))
        )
      )
        .then(pictures => {
          const updatedCaseTeamPictures = {};
          caseTeam.forEach(({ id }, index) => {
            updatedCaseTeamPictures[id] = URL.createObjectURL(
              unwrapResult(pictures[index])
            );
          });
          setCaseTeamPictures(updatedCaseTeamPictures);
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [caseTeam, dispatch, isCaseOrProposal]);

  return (
    <div className="case-team">
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <div className="case-team__logo">
        <Image src={caseTeamLogo} />
        <div className="case-team__text">Case Team</div>
      </div>
      <hr />
      <div className="case-team__second-block">
        <div className="case-team__second-block__segment">
          <div className="upper-text">Region</div>
          <div className="lower-text">{region}</div>
        </div>
        <div className="case-team__second-block__segment">
          <div className="upper-text">Office</div>
          <div className="lower-text">{office}</div>
        </div>
      </div>
      <hr />
      <div
        className="teams"
        style={{ maxHeight: hasMaterials ? '178px' : '240px' }}
      >
        {caseTeam.map(({ alumni, id, name, title }) => (
          <TeamMember
            key={id || name}
            alumni={alumni}
            name={name}
            designation={title}
            photo={caseTeamPictures[id]}
            onClick={getOnClickTeamMemberHandler(id)}
          />
        ))}
      </div>
    </div>
  );
}
CaseTeam.propTypes = {
  caseCode: PropTypes.string,
  region: PropTypes.string,
  office: PropTypes.string,
  team: PropTypes.string,
  hasMaterials: bool,
  isCaseOrProposal: PropTypes.oneOf([CASE_TYPES.CASE, CASE_TYPES.PROPOSAL])
    .isRequired,
};

CaseTeam.defaultProps = {
  caseCode: null,
  hasMaterials: false,
  region: '',
  office: '',
  team: '',
};
export default CaseTeam;
