import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Icon } from 'semantic-ui-react';

import { ComboBoxWithCheckbox } from 'components/ComboBox';
import threeDots from 'assets/images/icons/three-dots.svg';

const ColumnToggleButton = ({
  columns,
  onColumnsChange,
  selectedColumns,
  id,
  disabled,
}) => {
  const [selectedItems, setSelectedItems] = useState(selectedColumns);
  const [showColumnsMenu, setShowColumnsMenu] = useState(false);
  const columnsEditor = useRef();

  const hideDropDown = e => {
    if (columnsEditor.current.contains(e.target)) {
      //inside click
      return;
    }
    // outside click
    setShowColumnsMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', hideDropDown);

    return () => {
      document.removeEventListener('mousedown', hideDropDown);
    };
  }, []);

  const handleOnChange = selected => {
    setSelectedItems(selected);

    if (typeof onColumnsChange === 'function') {
      onColumnsChange(selected);
    }
  };

  const toggleColumnsMenu = () => {
    setShowColumnsMenu(!showColumnsMenu);
  };

  return (
    <div className="edit-columns" ref={columnsEditor}>
      <Button
        className="edit-columns__toggle-btn"
        icon={
          <Icon className="icon">
            <img src={threeDots} alt="show columns" />
          </Icon>
        }
        labelPosition="right"
        content="Edit Columns"
        onClick={toggleColumnsMenu}
        disabled={disabled}
      />
      <Dropdown
        multiple
        open={showColumnsMenu}
        className="edit-columns__menu"
        direction="left"
        disabled={disabled}
      >
        <Dropdown.Menu>
          <Dropdown.Item>
            <ComboBoxWithCheckbox
              id={id}
              className="edit-columns__combo"
              classNamePrefix="edit-columns__combo"
              onChange={handleOnChange}
              options={columns}
              value={selectedItems}
              placeholder={'Edit Columns'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isClearable={selectedItems.some(v => !v.isFixed)}
              menuIsOpen
              isMulti
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

ColumnToggleButton.propTypes = {
  icon: PropTypes.node,
  columns: PropTypes.array.isRequired,
  onColumnsChange: PropTypes.func,
  selectedColumns: PropTypes.array,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

ColumnToggleButton.defaultProps = {
  icon: null,
  onColumnsChange: () => null,
  selectedColumns: null,
  id: '',
  disabled: false,
};

export { ColumnToggleButton };
