import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import TableRow from './TableRow';

const TableBody = ({
  dataSource,
  selectedRows,
  collapsing,
  selectRow,
  columns,
  showCheckbox,
  onRowClick,
}) => {
  let rowHighlighter = null;
  return (
    <Table.Body>
      {dataSource &&
        dataSource.length > 0 &&
        dataSource.map((data, index) => {
          let addSpacer = false;
          if (rowHighlighter) {
            addSpacer = true;
          }
          rowHighlighter = data.rowHighlighter
            ? { [data.rowHighlighter]: true }
            : null;
          return (
            <TableRow
              key={`${data.id}-${index}`}
              data={data}
              selectedRows={selectedRows}
              selectRow={selectRow}
              columns={columns}
              showCheckbox={showCheckbox}
              collapsing={collapsing}
              addSpacer={addSpacer}
              rowHighlighter={rowHighlighter}
              onRowClick={onRowClick}
            />
          );
        })}
    </Table.Body>
  );
};

TableBody.propTypes = {
  dataSource: PropTypes.array,
  selectedRows: PropTypes.objectOf(PropTypes.bool),
  collapsing: PropTypes.bool,
  selectRow: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      style: PropTypes.object,
      hidden: PropTypes.bool,
      cellRender: PropTypes.func,
      field: PropTypes.string,
      type: PropTypes.oneOf(['date']),
      wordHighlighter: PropTypes.bool,
      truncateText: PropTypes.bool,
    })
  ),
  showCheckbox: PropTypes.bool,
  onRowClick: PropTypes.func,
};

TableBody.defaultProps = {
  dataSource: [],
  selectedRows: {},
  selectRow: () => null,
  columns: [],
  collapsing: false,
  showCheckbox: false,
  onRowClick: () => null,
};

export default TableBody;
