import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './FormTextInput.scss';
function FormTextInput({
  disabled,
  header,
  value,
  onBlur,
  onChange,
  name,
  placeholder,
  required,
  type,
}) {
  const [input, setInput] = useState(value || '');
  const handleInput = e => {
    const updatedValue = e.target.value;
    setInput(updatedValue);
    onChange(e, updatedValue);
  };

  return (
    <div className="form-text-input">
      <div className="form-text-input__label">{header}</div>
      <Input
        disabled={disabled}
        className="transparent"
        value={input}
        onBlur={onBlur}
        onChange={handleInput}
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
      />
    </div>
  );
}
FormTextInput.propTypes = {
  disabled: PropTypes.bool,
  header: PropTypes.string.isRequired,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'email',
    'file',
    'hidden',
    'image',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
  ]),
};

FormTextInput.defaultProps = {
  disabled: false,
  value: '',
  onBlur: () => null,
  onChange: () => null,
  placeholder: '',
  required: false,
  type: 'text',
};

export default FormTextInput;
