import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, Radio, Input, Icon, Image } from 'semantic-ui-react';
import cancel from 'assets/images/icons/cancel.svg';

import { PATHNAME } from 'constants/router';
import {
  getCaseTypes,
  getRegions,
  getMajorIndutries,
  getPipeTopics,
} from 'store/master/selectors';
import {
  getMajorIndustriesDropdownOptionsForAdvanceFilters,
  getTopicsListOptionsForAdvanceFilters,
} from 'utils/search';
import ValueContainer from './ValueContainer';
import './HeaderFilter.scss';
import { DEFAULT_FILTER_OPTIONS } from 'constants/search';

export default function HeaderFilter({
  handleOnClick,
  isSubmitDisabled,
  advanceFilter,
  setAdvanceFilter,
}) {
  const history = useHistory();
  const { pathname } = history.location;
  const [filterState, setFilterState] = useState('all');
  const caseType = useSelector(getCaseTypes);
  const [caseTypeOptions, setCaseTypeOptions] = useState([]);
  const regions = useSelector(getRegions);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const majorIndustries = useSelector(getMajorIndutries);
  const [majorIndustriesOptions, setMajorIndustriesOptions] = useState([]);
  const topics = useSelector(getPipeTopics);
  const [topicsOptions, setTopicsOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);

  const inputRef = {
    industryPracticeArea: useRef(),
    caseType: useRef(),
    topic: useRef(),
    region: useRef(),
    selectedYear: useRef(),
  };

  // for Case type
  useEffect(() => {
    const caseTypeList = caseType.map(({ caseTypeName, id }) => ({
      value: id,
      label: caseTypeName,
    }));
    setCaseTypeOptions(caseTypeList);
  }, [caseType]);

  // for Year
  useEffect(() => {
    const dateList = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i >= 1900; i--) {
      const date = {
        value: i,
        label: i,
      };
      dateList.push(date);
    }
    setYearOptions(dateList);
  }, []);

  // for region
  useEffect(() => {
    const regionsList = regions.map(({ id, regionName }) => ({
      value: id,
      label: regionName,
    }));
    setRegionsOptions(regionsList);
  }, [regions]);

  // for Topics
  useEffect(() => {
    setTopicsOptions(getTopicsListOptionsForAdvanceFilters(topics));
  }, [topics]);

  // Industry practice area
  useEffect(() => {
    setMajorIndustriesOptions(
      getMajorIndustriesDropdownOptionsForAdvanceFilters(majorIndustries)
    );
  }, [majorIndustries]);

  const handleRadio = (e, { value }) => {
    setFilterState(value);
    setAdvanceFilter({ ...advanceFilter, selectedRadio: [value] });
  };

  const handleComboBoxChange = filter => selected => {
    if (filter === 'industryPracticeArea') {
      selected = getSelectedIndustryPracticeArea(selected);
    } else {
      selected = selected.map(({ label }) => label);
    }
    const filterSelected = {
      ...advanceFilter,
      [filter]: selected,
    };
    setAdvanceFilter(filterSelected);
  };

  const handleInputChange =
    filter =>
    (_e, { value }) => {
      const selectedValues = value
        ? [...value.split(',').map(item => item.trim())]
        : [];
      setAdvanceFilter({
        ...advanceFilter,
        [filter]: selectedValues,
      });
    };

  const getSelectedIndustryPracticeArea = selectedOptions => {
    const selectedIndustries = selectedOptions.reduce(
      (acc, { label, majorIndustry }) => {
        const subSector = majorIndustry === label ? 'Yes' : label;
        acc[majorIndustry] = [...(acc[majorIndustry] || []), subSector];
        return acc;
      },
      {}
    );

    return Object.entries(selectedIndustries).map(
      ([majorIndustry, subSectors]) => ({
        [majorIndustry]: subSectors,
      })
    );
  };

  if (pathname === PATHNAME.LANDING) {
    return null;
  }

  const commonPropsForFilterDropdown = {
    className: 'header-filter__filter-dropdown',
    classNamePrefix: 'header-filter__filter-dropdown',
    isMulti: true,
    isSearchable: true,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    components: {
      ValueContainer,
    },
  };

  return (
    <Form id="header_filter" className="header-filter" autoComplete="off">
      <div className="header-filter__radio-group">
        <div>
          <Radio
            className="header-filter__radio-group__radio"
            label="All"
            name="radioGroup"
            value="all"
            checked={filterState === 'all'}
            onChange={handleRadio}
          />
        </div>
        <div>
          <Radio
            className="header-filter__radio-group__radio"
            label="Case Only"
            name="radioGroup"
            value="case"
            checked={filterState === 'case'}
            onChange={handleRadio}
          />
        </div>
        <div>
          <Radio
            className="header-filter__radio-group__radio"
            label="Proposal Only"
            name="radioGroup"
            value="proposal"
            checked={filterState === 'proposal'}
            onChange={handleRadio}
          />
        </div>
      </div>
      <Select
        ref={inputRef['industryPracticeArea']}
        {...commonPropsForFilterDropdown}
        className={`${commonPropsForFilterDropdown.className} ${commonPropsForFilterDropdown.className}--industry`}
        placeholder="Industry Practice Area"
        options={majorIndustriesOptions}
        onChange={handleComboBoxChange('industryPracticeArea')}
      />
      <Select
        ref={inputRef['caseType']}
        {...commonPropsForFilterDropdown}
        className={`${commonPropsForFilterDropdown.className} ${commonPropsForFilterDropdown.className}--case-type`}
        placeholder="Case Type"
        options={caseTypeOptions}
        onChange={handleComboBoxChange('caseType')}
      />
      <Select
        ref={inputRef['topic']}
        {...commonPropsForFilterDropdown}
        className={`${commonPropsForFilterDropdown.className} ${commonPropsForFilterDropdown.className}--topic`}
        placeholder="Topic"
        options={topicsOptions}
        onChange={handleComboBoxChange('topic')}
      />
      <Select
        ref={inputRef['region']}
        {...commonPropsForFilterDropdown}
        className={`${commonPropsForFilterDropdown.className} ${commonPropsForFilterDropdown.className}--region`}
        placeholder="Region"
        options={regionsOptions}
        onChange={handleComboBoxChange('region')}
      />
      <Select
        ref={inputRef['selectedYear']}
        {...commonPropsForFilterDropdown}
        className={`${commonPropsForFilterDropdown.className} ${commonPropsForFilterDropdown.className}--year`}
        name="year"
        options={yearOptions}
        onChange={handleComboBoxChange('selectedYear')}
        placeholder="Year"
      />
      <Input
        placeholder="Client Firm"
        className="header-filter__input"
        onChange={handleInputChange('clientFirm')}
        value={advanceFilter['clientFirm']}
      />
      <Input
        placeholder="MDP"
        className="header-filter__input"
        onChange={handleInputChange('mdp')}
        value={advanceFilter['mdp']}
      />
      <div>
        <Icon
          onClick={() => {
            Object.keys(inputRef).forEach(ref =>
              inputRef[ref].current.setValue([])
            );
            setAdvanceFilter(DEFAULT_FILTER_OPTIONS);
          }}
        >
          <Image src={cancel} />
        </Icon>
      </div>
      <div>
        <Button
          className="go-btn"
          disabled={isSubmitDisabled}
          onClick={handleOnClick}
        >
          <span className="go-text">GO</span>
        </Button>
      </div>
    </Form>
  );
}

HeaderFilter.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  advanceFilter: PropTypes.shape({
    selectedRadio: PropTypes.arrayOf(PropTypes.string),
    caseType: PropTypes.arrayOf(PropTypes.string),
    region: PropTypes.arrayOf(PropTypes.string),
    industryPracticeArea: PropTypes.arrayOf(PropTypes.object),
    topic: PropTypes.arrayOf(PropTypes.string),
    selectedYear: PropTypes.arrayOf(PropTypes.number),
    clientFirm: PropTypes.arrayOf(PropTypes.string),
    mdp: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setAdvanceFilter: PropTypes.func.isRequired,
};

HeaderFilter.defaultProps = {
  isSubmitDisabled: false,
};
