import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import ComboBox from 'components/ComboBox/ComboBox';
import { getKeyTerms } from 'store/master/selectors';
import { CaseDetailsContext } from 'utils/context';

function EditKeyTermBlock({ setFormData }) {
  const { data: keyTermBlockField } = useContext(CaseDetailsContext);
  const keyTerms = useSelector(getKeyTerms);
  const [keyTermsOptions, setKeyTermsOptions] = useState([]);
  const [formFields, setFormFields] = useState({
    keyTerms: keyTermBlockField.keyTerms,
  });

  let isFormUpdated = false;

  const handleChange = e => {
    formFields.keyTerms = e.caseUpdateValue;
    setFormFields(formFields);

    if (formFields.keyTerms !== keyTermBlockField.keyTerms) {
      isFormUpdated = true;
    }

    const data = {
      formData: formFields,
      masterDataUpdateValue: e.masterDataUpdateValue,
      masterDataName: e.masterDataName,
    };
    setFormData(data, isFormUpdated);
  };

  useEffect(() => {
    const keyTermList = keyTerms
      .filter(({ keyTermsName: label, id }) => label && id)
      .map(({ keyTermsName: label, id }) => ({
        label,
        value: id,
      }));
    setKeyTermsOptions(keyTermList);
  }, [keyTerms]);

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <ComboBox
            header="Keyword Tags"
            options={keyTermsOptions}
            selectedValue={keyTermBlockField.keyTerms}
            onChange={handleChange}
            name="keyTerm"
            placeholder="Input or select Keyword Tags"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

EditKeyTermBlock.propTypes = {
  setFormData: PropTypes.func,
};

EditKeyTermBlock.defaultProps = {
  setFormData: () => null,
};

export default EditKeyTermBlock;
