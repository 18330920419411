import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import './FormDropdown.scss';

function FormDropdown({
  allowAdditions,
  header,
  options,
  selectedOption,
  onChange,
  name,
}) {
  const handleInput = (e, { value }) => {
    onChange(e, value);
  };

  return (
    <div className="form-dropdown">
      {Boolean(header) && <div className="form-dropdown__label">{header}</div>}
      <Dropdown
        search
        selection
        allowAdditions={allowAdditions}
        options={options}
        onChange={handleInput}
        defaultValue={selectedOption}
        className="form-dropdown__items"
        name={name}
      />
    </div>
  );
}
FormDropdown.propTypes = {
  allowAdditions: PropTypes.bool,
  header: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
};

FormDropdown.defaultProps = {
  allowAdditions: false,
  header: '',
  onChange: () => null,
  selectedOption: null,
};

export default FormDropdown;
