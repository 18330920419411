export const addAfter = (array, index, newItem) => {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
};

/**
 *  @function generateAllCombinations
 *
 * @param   {Array[]}   stringsArrays         - Array of arrays containg the strings
 * @param   {string}    [wordSeparator= ' ']  - string to include in between words
 * @example
 * // returns ["data securities", 'data securities', "data security", 'data security']
 * generateCombinations([ ["data's", "data"], ["securities", "security"] ]);
 * @returns {Array[]}                         - Array of all possible strings
 */
export const generateCombinations = (stringsArrays, wordSeparator = ' ') => {
  if (stringsArrays.length === 1) {
    return stringsArrays[0];
  } else {
    const result = [];
    const allCasesOfRest = generateCombinations(
      stringsArrays.slice(1),
      wordSeparator
    ); // recur with the rest of array

    if (!allCasesOfRest.length) {
      return stringsArrays[0];
    }

    if (!stringsArrays[0].length) {
      return allCasesOfRest;
    }

    for (const secondPart of allCasesOfRest) {
      for (const firstPart of stringsArrays[0]) {
        result.push(firstPart + wordSeparator + secondPart);
      }
    }

    return result;
  }
};
