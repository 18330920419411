import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import ComboBox from 'components/ComboBox/ComboBox';
import FormTextInput from 'components/FormTextInput';
import { getPipeTopics } from 'store/master/selectors';
import { CaseDetailsContext } from 'utils/context';

function EditTopicBlock({ setFormData }) {
  const { data: topicBlockField } = useContext(CaseDetailsContext);

  const topics = useSelector(getPipeTopics);
  const [topicsOptions, setTopicsOptions] = useState([]);
  const [formFields, setFormFields] = useState({
    pipeTopics: topicBlockField.pipeTopics,
    valueCreationLevels: topicBlockField.valueCreationLevels,
  });

  let isFormUpdated = false;

  const handleChange = name => (_e, value) => {
    const formFieldObject = formFields;
    formFieldObject[name] = value;

    if (_e.caseUpdateValue !== undefined) {
      formFieldObject.pipeTopics = _e.caseUpdateValue;
    }
    setFormFields(formFieldObject);

    if (
      formFieldObject.pipeTopics !== topicBlockField.pipeTopics ||
      formFieldObject.valueCreationLevels !==
        topicBlockField.valueCreationLevels
    ) {
      isFormUpdated = true;
    }

    if (_e.masterDataUpdateValue !== undefined) {
      const data = {
        formData: formFields,
        masterDataUpdateValue: _e.masterDataUpdateValue,
        masterDataName: _e.masterDataName,
      };
      setFormData(data, isFormUpdated);
    } else {
      setFormData(formFields, isFormUpdated);
    }
  };

  useEffect(() => {
    const topicList = topics
      .filter(({ topics: pipeTopics, id }) => pipeTopics && id)
      .map(({ topics: pipeTopics, id }) => ({
        value: id,
        label: pipeTopics,
      }));
    setTopicsOptions(topicList);
  }, [topics]);

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <ComboBox
            header="Topic Area"
            options={topicsOptions}
            selectedValue={topicBlockField.pipeTopics}
            onChange={handleChange('pipeTopics')}
            name="pipeTopics"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FormTextInput
            header="Value Creation"
            value={formFields.valueCreationLevels}
            onChange={handleChange('valueCreationLevels')}
            name="valueCreationLevels"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

EditTopicBlock.propTypes = {
  setFormData: PropTypes.func,
};

EditTopicBlock.defaultProps = {
  setFormData: () => null,
};

export default EditTopicBlock;
