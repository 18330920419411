import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, Segment, Ref } from 'semantic-ui-react';

import TableHeader from './TableHeader';
import TableBody from './TableBody';
import PaginationPanel from './PaginationPanel';
import NoData from './NoData';
import Loader from 'components/Loader';

const DataTable = ({
  className,
  id,
  centered,
  selectable,
  striped,
  collapsing,
  showCheckbox,
  columns,
  dataSource,
  totalRows,
  fixed,
  paginated,
  limitRows,
  sortable,
  onQueryChange,
  onRowSelectionChange,
  showLoader,
  sortData,
  paginationData,
  onRowClick,
  selectedRows,
  handleSortChange,
  handlePageChange,
}) => {
  const [loading, setLoading] = useState(false);
  const { limit, page } = paginationData;
  const { sortBy, sortDirection } = sortData;
  const tableRef = useRef();

  const getData = useCallback(async () => {
    setLoading(true);
    await onQueryChange();
    setLoading(false);
  }, [onQueryChange]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    scrollTableToTop();
  }, [page, limit, sortBy, sortDirection]);

  const onPageChnage = pageNumber => {
    handlePageChange({
      ...paginationData,
      page: pageNumber,
    });
  };

  const onSortChanage = (column, direction) => {
    handleSortChange({
      sortBy: column,
      sortDirection: direction,
    });
  };

  const onLimitChange = value => {
    handlePageChange({
      ...paginationData,
      limit: value,
      page: 1,
    });
  };

  const scrollTableToTop = () => {
    if (tableRef?.current) {
      tableRef?.current?.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handlePaginationChange = (_e, { activePage }) => {
    onPageChnage(activePage);
  };

  const handleLimitChange = (_e, { value }) => {
    onLimitChange(value);
  };

  const selectRow = (e, { checked, value }) => {
    e.stopPropagation();
    const updatedSelectedRows = { ...selectedRows, [value]: checked };
    onRowSelectionChange(updatedSelectedRows);
  };

  if (!loading && !dataSource.length) {
    return <NoData />;
  }

  return (
    <Segment className="semantic-table-section">
      <Loader active={loading || showLoader} />
      <Grid>
        <Grid.Row id={`${id}-row`}>
          <Ref innerRef={tableRef}>
            <Grid.Column>
              <Table
                striped={striped}
                sortable={sortable}
                selectable={selectable}
                celled
                collapsing={collapsing}
                unstackable
                style={{
                  borderRadius: 0,
                  textAlign: centered ? 'center' : '',
                }}
                className={`semantic-data-table ${className}`}
                id={id}
                fixed={fixed}
              >
                <TableHeader
                  columns={columns}
                  showCheckbox={showCheckbox}
                  onSortChanage={onSortChanage}
                  collapsing={collapsing}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  sortable={sortable}
                />
                <TableBody
                  dataSource={dataSource}
                  selectedRows={selectedRows}
                  collapsing={collapsing}
                  selectRow={selectRow}
                  columns={columns}
                  showCheckbox={showCheckbox}
                  loading={loading}
                  onRowClick={onRowClick}
                />
              </Table>
            </Grid.Column>
          </Ref>
        </Grid.Row>
        {paginated && paginationData && (
          <PaginationPanel
            id={id}
            pagination={{ ...paginationData }}
            handlePaginationChange={handlePaginationChange}
            totalRows={totalRows}
            limit={limit}
            limitRows={limitRows}
            handleLimitChange={handleLimitChange}
          />
        )}
      </Grid>
    </Segment>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  centered: PropTypes.bool,
  selectable: PropTypes.bool,
  striped: PropTypes.bool,
  collapsing: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      style: PropTypes.object,
      hidden: PropTypes.bool,
      cellRender: PropTypes.func,
      field: PropTypes.string,
      type: PropTypes.oneOf(['date']),
      wordHighlighter: PropTypes.bool,
      truncateText: PropTypes.bool,
    }).isRequired
  ).isRequired,
  fixed: PropTypes.bool,
  dataSource: PropTypes.array.isRequired,
  paginated: PropTypes.bool,
  limitRows: PropTypes.array,
  totalRows: PropTypes.number.isRequired,
  sortable: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  showLoader: PropTypes.bool,
  sortData: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.number,
  }),
  paginationData: PropTypes.shape({
    firstPage: PropTypes.bool,
    lastPage: PropTypes.bool,
    pageNumber: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
  }),
  onRowClick: PropTypes.func,
  selectedRows: PropTypes.object,
  handleSortChange: PropTypes.func,
  handlePageChange: PropTypes.func,
};

DataTable.defaultProps = {
  className: '',
  id: '',
  centered: false,
  selectable: true,
  striped: false,
  collapsing: false,
  showCheckbox: false,
  fixed: false,
  paginated: false,
  limitRows: ['25', '50', '100', '200'],
  sortable: false,
  onQueryChange: () => null,
  onRowSelectionChange: () => null,
  showLoader: false,
  sortData: { sortBy: null, sortDirection: null },
  paginationData: { firstPage: true, lastPage: true },
  onRowClick: () => null,
  selectedRows: {},
  handleSortChange: () => null,
  handlePageChange: () => null,
};

export default DataTable;
