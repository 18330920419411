import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Image } from 'semantic-ui-react';

import loadingAnimation from 'assets/images/loading-animation.gif';
import './Loader.scss';

const Loader = ({ active }) => (
  <Dimmer className="application-loader" active={active} inverted>
    <Image src={loadingAnimation} />
  </Dimmer>
);

export default Loader;

Loader.propTypes = {
  active: PropTypes.bool.isRequired,
};
